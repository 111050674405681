import React, { createRef } from "react";
import { getDevice } from "../../../../lib/responsive-lib";
import "./SearchAddress.css";

export default class SearchAddress extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      geolocationDone: false,
      address: "",
    };

    // Method binding
    this.nextStep = this.nextStep.bind(this);
    this.emptyAddress = this.emptyAddress.bind(this);
  }

  // Class variables
  searchBoxListener: any = undefined;
  private addressInput = createRef<HTMLInputElement>();

  // React componentDidMount
  componentDidMount() {
    this.setState({ device: getDevice() });
    this.initSearchBox();
  }

  // React componentWillUnmount
  componentWillUnmount() {
    google.maps.event.removeListener(this.searchBoxListener);
  }

  // React render
  public render() {
    return (
      <>
        <div className="searchaddress__container">
          <div className="searchaddress__search-text">
            Cerca l'indirizzo dell'edificio/stabilimento di cui vuoi fare
            l'autovalutazione e selezionalo.
          </div>
          <div
            className={
              "searchaddress__search-container searchaddress__search-container-" +
              this.state.device
            }
          >
            <div
              className={
                "searchaddress__search-box searchaddress__search-box-" +
                this.state.device
              }
            >
              <input
                id="search_input"
                type="text"
                placeholder="Cerca un indirizzo"
                className="searchaddress__search-input"
                onClick={this.emptyAddress}
                ref={this.addressInput}
              />
              <img
                src={
                  require("./../../../../assets/system/icon-system-search.svg")
                    .default
                }
                alt="Cerca luogo"
                className="searchaddress__search-logo"
              />
            </div>
            <div
              className={
                "searchaddress__button-row searchaddress__button-row-" +
                this.state.device
              }
            >
              <div
                className={
                  "searchaddress__left-button searchaddress__button-element-" +
                  this.state.device
                }
              >
                <div
                  className="searchaddress__button"
                  onClick={this.props.action}
                >
                  Indietro
                </div>
              </div>
              <div
                className={
                  "searchaddress__right-button searchaddress__right-button-" +
                  (this.state.geolocationDone ? "on" : "off")
                }
              >
                <div className="searchaddress__button" onClick={this.nextStep}>
                  Avanti
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // Initialize Google Search Box
  initSearchBox(): void {
    /*global google*/
    const input = document.getElementById("search_input") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.searchBoxListener = searchBox.addListener("places_changed", () => {
      this.setPosition(searchBox.getPlaces()[0]);
    });
  }

  // Set the position found on the background map
  setPosition(places: any): void {
    this.props.setCookiesAddressInfo(places.address_components);
    this.props.setPosition(
      {
        coords: {
          latitude: places.geometry.location.lat(),
          longitude: places.geometry.location.lng(),
        },
      },
      false
    );
    this.setState({ geolocationDone: true });
  }

  // Empty address var on state
  emptyAddress(e: React.MouseEvent): void {
    this.setState({
      geolocationDone: false,
    });
    if (
      this.addressInput.current !== undefined &&
      this.addressInput.current !== null
    ) {
      this.addressInput.current.value = "";
    }
  }

  // Go to the next step
  nextStep(): void {
    if (this.state.geolocationDone === true) {
      this.props.nextStep();
    }
  }
}
