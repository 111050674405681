import React from "react";
import MainBox from "./../../mainbox/MainBox";
import ProgressBar from "./../../progressbar/ProgressBar";
import ReCAPTCHA from "react-google-recaptcha";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import { getDevice } from "./../../../lib/responsive-lib";
import { Redirect } from "react-router-dom";
import { getResponse } from "./../../../api/proxy/api";
import { checkPrivacy } from "./../../../lib/general-lib";
import {
  getLocalAccessToken,
  getAvailablesLogins,
} from "./../../../api/auth-api/api";
import {
  getExternalRegistration,
  getStandardRegistration,
} from "./../../../api/registration-api/api";
import {
  setUtagView,
  setUtagLink,
  capitalizeString,
} from "./../../../lib/general-lib";
import Loader from "./../../loader/Loader";
import config from "./../../../config/config";
import "./Registration.css";
import { HomeBanner } from "../../home-banner";

interface RegistrationProps {
  cookies?: any;
  disableMarker: any;
  setMapsCoordinates: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  location: any;
  setMapStatus: any;
}

interface RegistrationState {
  device: String;
  redirect: Boolean;
  recaptchaStatus: Boolean;
  gotologin: Boolean;
  firstName: String;
  lastName: String;
  email: String;
  password: String;
  rePassword: String;
  errorTitle: String;
  errorText: String;
  error: Boolean;
  loader: Boolean;
  redirectNext: Boolean;
  redirectToPrivacy: Boolean;
  availablesLogins: Array<any>;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class Registration extends React.Component<
  RegistrationProps,
  RegistrationState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      redirect: false,
      recaptchaStatus: !config.recaptcha.enabled,
      gotologin: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      errorTitle: "",
      errorText: "",
      error: false,
      loader: false,
      redirectNext: false,
      redirectToPrivacy: false,
      availablesLogins: [],
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.hanldeBack = this.hanldeBack.bind(this);
    this.handleReCAPTCHAChanges = this.handleReCAPTCHAChanges.bind(this);
    this.hanldeRegistration = this.hanldeRegistration.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRePasswordChange = this.onRePasswordChange.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.getexternalLoginUrl = this.getexternalLoginUrl.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    if (!checkPrivacy()) {
      this.setState({ redirectToPrivacy: true });
    } else if (
      window.location.hash !== undefined &&
      window.location.hash !== ""
    ) {
      this.handleUrlParams(window.location.hash.substring(1).split("&"));
    } else {
      this.mountComponentOp();
    }
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      window.location.hash !== undefined &&
      window.location.hash !== ""
    ) {
      this.handleUrlParams(window.location.hash.substring(1).split("&"));
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectBack()}
        {this.renderRedirectToLogin()}
        {this.renderRedirecToNextStep()}
        {this.renderRedirectToPrivecyCheck()}
        <div className="registration__container">
          <MainBox>
            <div className="registration__text-row-header">
              <MBHeader
                device={this.state.device}
                loginEnabled={false}
                closeEnabled={false}
                cookies={this.props.cookies}
                parent={config.steps[2]}
              />
            </div>
            <div className="registration__text-row-progressbar">
              <ProgressBar step={"02"} />
            </div>
            <div
              className={
                "registration__registration-items registration__registration-items-" +
                this.state.device
              }>
              <div
                className={
                  "registration__registration-items-title registration__registration-items-title-" +
                  this.state.device
                }>
                Per continuare è necessario effettuare la{" "}
                <span className="bold">Registrazione</span>. Oppure se possiedi
                già un Username puoi effettuare direttamente il{" "}
                <span
                  onClick={this.goToLogin}
                  className="registration__gotologin-button">
                  Login
                </span>
              </div>
              {this.getItems()}
            </div>
            {this.getButtonsBar()}
          </MainBox>
          <HomeBanner />
        </div>
        {this.renderError()}
        {this.renderLoader()}
      </>
    );
  }

  // Set data about login for analytics system
  setLoginForAnalytics(action: string, result: boolean): void {
    setUtagLink(
      {
        page_url: config.analytics.baseurl + "/registration",
        first_level: "registration",
        event_name: action,
        ga_eventCategory: "riconoscimento-utente",
        ga_eventaction: action,
        ga_eventLabel: result ? "success" : "fail",
      },
      false
    );
  }

  // Get single param in params array
  getParam(parmas: String, paramsArray: Array<String>): String | undefined {
    return paramsArray.find((param) => param.split("=")[0] === parmas);
  }

  // Get value from string key-value
  getValueByKey(keyValue: String | undefined): String {
    return keyValue !== undefined ? keyValue.split("=")[1] : "";
  }

  // Perform login by external account
  getexternalLoginUrl(e: React.MouseEvent): void {
    if (this.state.availablesLogins.length > 0) {
      window.location.replace(
        config.main.baseurl +
          this.state.availablesLogins.find((item) =>
            item.Name.match(new RegExp(e.currentTarget.id, "i"))
          ).Url
      );
    } else {
      this.setState({
        msgTitle: "Errore",
        msgText:
          "Si è verificato un problema nell'effettuare il login con " +
          capitalizeString(e.currentTarget.id),
        msg: true,
      });
    }
  }

  // Perform all operation to do after component starts
  mountComponentOp(): void {
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    this.getLoginMethods();
    this.props.disableMarker();
    this.props.setInfoWindowVisibility(false);
    this.props.setMapZoom(config.maps.default.zoom);
    const { cookies } = this.props;
    if (
      cookies.get("derris_current_coordinates") !== undefined &&
      cookies.get("derris_current_coordinates") !== ""
    ) {
      this.props.setMapsCoordinates(cookies.get("derris_current_coordinates"));
    }
    // Analytics
    setUtagView(
      {
        page_url: config.analytics.baseurl + "/registration",
        first_level: "registration",
      },
      false
    );
    this.setState({ device: getDevice() });
  }

  // Get the login methods availables
  getLoginMethods(): void {
    this.setState({ loader: true }, () => {
      getAvailablesLogins(config.steps[2] + "/")
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res: any) => {
              this.setState({
                availablesLogins: JSON.parse(res),
                loader: false,
              });
            });
          } else {
            console.error(
              "Error while loading login method - Status: " + result.status
            );
          }
        })
        .catch((error) => {
          console.error("Error while loading login method - " + error);
        });
    });
  }

  // Manage the url params
  handleUrlParams(paramsArray: Array<String>): void {
    switch (true) {
      // New user: must be registered
      case this.getParam("haslocalaccount", paramsArray) ===
        "haslocalaccount=False":
        this.setState({ loader: true }, () => {
          getExternalRegistration(
            this.getValueByKey(
              this.getParam("external_user_firstname", paramsArray)
            ),
            this.getValueByKey(
              this.getParam("external_user_lastname", paramsArray)
            ),
            this.getValueByKey(
              this.getParam("external_user_name", paramsArray)
            ),
            this.getValueByKey(this.getParam("provider", paramsArray)),
            this.getValueByKey(
              this.getParam("external_access_token", paramsArray)
            )
          )
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res: any) => {
                  const { cookies } = this.props;
                  this.setLoginForAnalytics("registration", true);
                  cookies.set("derris_user", JSON.parse(res).userName, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  cookies.set("derris_token", JSON.parse(res).access_token, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  this.setState({
                    loader: false,
                    redirectNext: true,
                  });
                });
              } else {
                this.setLoginForAnalytics("registrazione", false);
                this.setState({ loader: false });
                console.error(
                  "Error new external account - Status: " + result.status
                );
              }
            })
            .catch((error) => {
              this.setLoginForAnalytics("registration", false);
              this.setState({ loader: false });
              console.error("Error new external account - " + error);
            });
        });
        break;
      // User already registered: getting local account
      case this.getParam("haslocalaccount", paramsArray) ===
        "haslocalaccount=True":
        this.setState({ loader: true }, () => {
          getLocalAccessToken(
            this.getValueByKey(this.getParam("provider", paramsArray)),
            this.getValueByKey(
              this.getParam("external_access_token", paramsArray)
            )
          )
            .then((atResult) => {
              if (atResult.status === 200) {
                atResult.json().then((res: any) => {
                  const { cookies } = this.props;
                  this.setLoginForAnalytics("login", true);
                  cookies.set("derris_user", JSON.parse(res).userName, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  cookies.set("derris_token", JSON.parse(res).access_token, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  this.setState({
                    loader: false,
                    redirectNext: true,
                  });
                });
              } else {
                this.setLoginForAnalytics("login", false);
                this.setState({ loader: false });
                console.error("Local token error - Status: " + atResult.status);
              }
            })
            .catch((error) => {
              this.setLoginForAnalytics("login", false);
              this.setState({ loader: false });
              console.error("Local token error - " + error);
            });
        });
        break;
      // Invalid url params: initializing component
      default:
        this.mountComponentOp();
        break;
    }
  }

  // Rendere redirect component
  renderRedirectToPrivecyCheck(): Object {
    return this.state.redirectToPrivacy === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[0] }}
      />
    ) : (
      ""
    );
  }

  // Handle go back action
  hanldeBack(): void {
    this.setState({ redirect: true });
  }

  handleReCAPTCHAChanges(recaptchaValue: any): void {
    if (recaptchaValue !== null) {
      getResponse(
        "GET",
        "https://www.google.com/recaptcha/api/siteverify?secret=" +
          config.recaptcha.secret +
          "&response=" +
          recaptchaValue,
        null
      )
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res: any) => {
              if (JSON.parse(res).success === true) {
                this.setState({ recaptchaStatus: true });
              }
            });
          } else {
            console.error("reCAPTCHA error - Status: " + result.status);
          }
        })
        .catch((error) => {
          console.error("reCAPTCHA error - " + error);
        });
    }
  }

  hanldeRegistration(e: React.MouseEvent): void {
    switch (true) {
      case this.state.recaptchaStatus === false:
        console.error("ReCAPTCHA not valid");
        break;
      case this.state.recaptchaStatus &&
        (this.state.email === "" ||
          this.state.password === "" ||
          this.state.rePassword === ""):
        this.setState({
          msgTitle: "Errore",
          msgText:
            "I campi Email, Password e Conferma password sono obbligatori",
          msg: true,
        });
        break;
      case this.state.recaptchaStatus &&
        this.state.password !== this.state.rePassword:
        this.setState({
          msgTitle: "Errore",
          msgText: "Le due password inserite non coincidono",
          msg: true,
        });
        break;
      case this.state.recaptchaStatus &&
        this.state.password.length < config.password.minLength:
        this.setState({
          msgTitle: "Errore",
          msgText:
            "La password deve avere una lunghezza minima di " +
            config.password.minLength +
            " caratteri",
          msg: true,
        });
        break;
      default:
        this.setState({ loader: true }, () => {
          getStandardRegistration(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.password,
            this.state.rePassword
          )
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res: any) => {
                  const { cookies } = this.props;
                  this.setLoginForAnalytics("registration", true);
                  cookies.set("derris_user", JSON.parse(res).userName, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  cookies.set("derris_token", JSON.parse(res).access_token, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  this.setState({
                    loader: false,
                    redirectNext: true,
                  });
                });
              } else if (result.status === 400) {
                result.json().then((res: any) => {
                  this.setLoginForAnalytics("registration", false);
                  if (/registered/i.test(res.Description)) {
                    console.error("New account error - " + res.Description);
                    this.setState({
                      msg: true,
                      msgTitle: "Errore",
                      msgText:
                        'ERRORE - Se ti sei già registrato in precedenza e possiedi già un nome utente e una password, clicca su "Login" per effetturare l\'accesso.',
                      loader: false,
                    });
                  } else {
                    this.setLoginForAnalytics("registration", false);
                    console.error(
                      "New account error - Status: " + result.status
                    );
                    this.setState({
                      msg: true,
                      msgTitle: "Errore",
                      msgText:
                        "Impossibile effettuare la registrazione - Status: " +
                        result.status,
                      loader: false,
                    });
                  }
                });
              } else {
                this.setLoginForAnalytics("registration", false);
                console.error("New account error - Status: " + result.status);
                this.setState({
                  msg: true,
                  msgTitle: "Errore",
                  msgText:
                    "Impossibile effettuare la registrazione - Status: " +
                    result.status,
                  loader: false,
                });
              }
            })
            .catch((error) => {
              this.setLoginForAnalytics("registration", false);
              console.error("New account error - " + error);
              this.setState({
                msg: true,
                msgTitle: "Errore",
                msgText: "New account error - " + error,
                loader: false,
              });
            });
        });
        break;
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Redirect to login
  goToLogin(e: React.MouseEvent): void {
    const { cookies } = this.props;
    cookies.set("derris_origin_page", config.steps[2], { path: "/" });
    this.setState({ gotologin: true });
  }

  // Rendere redirect component
  renderRedirectToLogin(): Object {
    return this.state.gotologin === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[5] }}
      />
    ) : (
      ""
    );
  }

  // Rendere redirect component
  renderRedirectBack(): Object {
    return this.state.redirect === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[1] }}
      />
    ) : (
      ""
    );
  }

  // Rendere redirect component
  renderRedirecToNextStep(): Object {
    return this.state.redirectNext === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[3] }}
      />
    ) : (
      ""
    );
  }

  // Handle first name input changes
  onFirstNameChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ firstName: e.currentTarget.value });
  }

  // Handle last name input changes
  onLastNameChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ lastName: e.currentTarget.value });
  }

  // Handle email input changes
  onEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ email: e.currentTarget.value });
  }

  // Handle password input changes
  onPasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ password: e.currentTarget.value });
  }

  // Handle repeat password input changes
  onRePasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ rePassword: e.currentTarget.value });
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Render form items
  getItems(): Object {
    switch (this.state.device) {
      case "mobile":
        return (
          <>
            <div className="registration__registration-alternative">
              Oppure usa
            </div>
            {this.getExternalRegistration(true, false)}
            <div className="registration__registration-field">
              <div className="registration__label-box">Nome</div>
              <div className="registration__input-box">
                <input
                  id="registration__input-firstname"
                  type="text"
                  className="registration__input"
                  onChange={this.onFirstNameChange}
                />
              </div>
            </div>
            <div className="registration__registration-field">
              <div className="registration__label-box">Cognome</div>
              <div className="registration__input-box">
                <input
                  id="registration__input-lastname"
                  type="text"
                  className="registration__input"
                  onChange={this.onLastNameChange}
                />
              </div>
            </div>
            <div className="registration__registration-field">
              <div className="registration__label-box">Email*</div>
              <div className="registration__input-box">
                <input
                  id="registration__input-email"
                  type="email"
                  className="registration__input"
                  onChange={this.onEmailChange}
                />
              </div>
            </div>
            <div className="registration__registration-field">
              <div className="registration__label-box">Password*</div>
              <div className="registration__input-box">
                <input
                  id="registration__input-password"
                  type="password"
                  className="registration__input"
                  onChange={this.onPasswordChange}
                />
              </div>
            </div>
            <div className="registration__registration-field">
              <div className="registration__label-box">Conferma password*</div>
              <div className="registration__input-box">
                <input
                  id="registration__input-repassword"
                  type="password"
                  className="registration__input"
                  onChange={this.onRePasswordChange}
                />
              </div>
            </div>
            <div
              className={
                "registration__registration-recaptcha registration__registration-recaptcha-" +
                this.state.device
              }>
              <div className="registration__registration-recaptcha-container">
                {config.recaptcha.enabled ? this.getRecaptcha() : ""}
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="registration__registration-items-left">
              <div className="registration__registration-items-left-row">
                <div className="registration__label-box">Nome</div>
                <div className="registration__input-box">
                  <input
                    id="registration__input-firstname"
                    type="text"
                    className="registration__input"
                    onChange={this.onFirstNameChange}
                  />
                </div>
              </div>
              <div className="registration__registration-items-left-row">
                <div className="registration__label-box">Cognome</div>
                <div className="registration__input-box">
                  <input
                    id="registration__input-lastname"
                    type="text"
                    className="registration__input"
                    onChange={this.onLastNameChange}
                  />
                </div>
              </div>
              <div className="registration__registration-items-left-row">
                <div className="registration__label-box">Email*</div>
                <div className="registration__input-box">
                  <input
                    id="registration__input-email"
                    type="email"
                    className="registration__input"
                    onChange={this.onEmailChange}
                  />
                </div>
              </div>
              <div className="registration__registration-items-left-row">
                <div className="registration__label-box">Password*</div>
                <div className="registration__input-box">
                  <input
                    id="registration__input-password"
                    type="password"
                    className="registration__input"
                    onChange={this.onPasswordChange}
                  />
                </div>
              </div>
              <div className="registration__registration-items-left-row">
                <div className="registration__label-box">
                  Conferma password*
                </div>
                <div className="registration__input-box">
                  <input
                    id="registration__input-repassword"
                    type="password"
                    className="registration__input"
                    onChange={this.onRePasswordChange}
                  />
                </div>
              </div>
            </div>
            <div className="registration__registration-items-right">
              <div className={"registration__external-title"}>Oppure</div>
              {this.getExternalRegistration(true, false)}
              <div className="registration__registration-items-recaptcha-container">
                {config.recaptcha.enabled ? this.getRecaptcha() : ""}
              </div>
            </div>
          </>
        );
    }
  }

  // Render the button bar
  getButtonsBar(): Object {
    return (
      <>
        <div
          className={
            "registration__button-bar registration__button-bar-" +
            this.state.device
          }>
          <div
            className={
              "registration__button-bar-left registration__button-bar-left-" +
              this.state.device
            }>
            <div
              className={
                "registration__button-back registration__button-back-" +
                this.state.device
              }
              onClick={this.hanldeBack}>
              Indietro
            </div>
          </div>
          <div
            className={
              "registration__button-bar-right registration__button-bar-right-" +
              this.state.device
            }>
            <div
              className={
                "registration__button-registration registration__button-registration-" +
                this.state.device +
                " " +
                (this.state.recaptchaStatus ? "enabled" : "disabled")
              }
              onClick={this.hanldeRegistration}>
              Registrati
            </div>
          </div>
        </div>
      </>
    );
  }

  // Render the external registration button
  getExternalRegistration(google: boolean, facebook: boolean): Object {
    return (
      <>
        <div
          className={
            "registration__external registration__external-" + this.state.device
          }>
          {google ? (
            <>
              <div
                className={
                  "registration__external-google registration__external-google-" +
                  this.state.device
                }>
                <div
                  className={
                    "registration__external-google-button registration__external-google-button-" +
                    this.state.device
                  }
                  onClick={this.getexternalLoginUrl}
                  id="google">
                  <div className="registration__google-logo-container">
                    <img
                      src={require("./../../../assets/logos/gg.png")}
                      alt="Login con Google"
                      className="registration__gg-logo"
                    />
                  </div>
                  <div className="registration__gg-text">Accedi con Google</div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {facebook ? (
            <>
              <div
                className={
                  "registration__external-facebook registration__external-facebook-" +
                  this.state.device
                }>
                <div
                  className={
                    "registration__external-facebook-button registration__external-facebook-button-" +
                    this.state.device
                  }
                  onClick={this.getexternalLoginUrl}
                  id="facebook">
                  <div className="registration__fb-logo-container">
                    <img
                      src={require("./../../../assets/logos/fb.png")}
                      alt="Login con Facebook"
                      className="registration__fb-logo"
                    />
                  </div>
                  <div className="registration__fb-text">
                    Accedi con Facebook
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  // Render the Recaptcha component
  getRecaptcha(): Object {
    switch (this.state.device) {
      case "tablet-landscape":
      case "tablet-portrait":
        return (
          <>
            <div className="registration__registration-items-recaptcha-container">
              <ReCAPTCHA
                sitekey={config.recaptcha.sitekey}
                onChange={this.handleReCAPTCHAChanges}
                size="compact"
              />
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="registration__registration-items-recaptcha-container">
              <ReCAPTCHA
                sitekey={config.recaptcha.sitekey}
                onChange={this.handleReCAPTCHAChanges}
                size="normal"
              />
            </div>
          </>
        );
    }
  }
}
