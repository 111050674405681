import React from "react";
import FlatBox from "./../../flatbox/FlatBox";
import Loader from "./../../loader/Loader";
import { getDevice } from "../../../lib/responsive-lib";
import { Redirect } from "react-router-dom";
import { getDanger } from "./../../../api/danger-api/api";
import config from "./../../../config/config";
import {
  checkPrivacy,
  setUtagView,
  checkLogin,
} from "./../../../lib/general-lib";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import "./SearchAddressResults.css";
interface SearchAddressResultsProps {
  cookies?: any;
  setMapsCoordinates: any;
  center: any;
  setMapZoom: any;
  disableMarker: any;
  markerCurrentPosition: any;
  enableMarker: any;
  changePanMap: any;
  setMarkerCoordinates: any;
  setMapStatus: any;
}

interface SearchAddressResultsState {
  device: String;
  arrayOfDangers: Array<any>;
  loader: Boolean;
  redirectTo: string;
  footerStatus: Boolean;
  renderInfo: boolean;
  renderHazardInfo: boolean;
  mobileSheetLayout: string;
  currentHazardInfo: any;
  error: boolean;
  errorTitle: string;
  errorText: string;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}
export default class SearchAddressResults extends React.Component<
  SearchAddressResultsProps,
  SearchAddressResultsState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      arrayOfDangers: [],
      loader: false,
      footerStatus: false,
      renderInfo: false,
      renderHazardInfo: false,
      mobileSheetLayout: "small",
      currentHazardInfo: {},
      error: false,
      errorTitle: "",
      errorText: "",
      msg: false,
      msgTitle: "",
      msgText: "",
      redirectTo: "",
    };

    // Method binding
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleHazardInfo = this.toggleHazardInfo.bind(this);
    this.getPies = this.getPies.bind(this);
    this.toggleSheet = this.toggleSheet.bind(this);
    this.showHazardInfo = this.showHazardInfo.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    const { cookies } = this.props;
    if (!checkPrivacy()) {
      this.setState({ redirectTo: "privacy" });
    } else if (
      cookies.get("derris_current_coordinates") === undefined ||
      cookies.get("derris_current_coordinates") === ""
    ) {
      this.setState({ redirectTo: "back" });
    } else {
      this.props.setMapStatus(true);
      this.props.setMapZoom(18);
      this.getDangerByCoord();
      this.props.enableMarker();
      this.props.setMapsCoordinates(cookies.get("derris_current_coordinates"));
      this.props.setMarkerCoordinates(
        cookies.get("derris_current_coordinates")
      );
      this.setState({ device: getDevice() }, () => {
        if (this.state.device === "mobile") {
          this.props.changePanMap({
            x: 0,
            y: 300,
          });
        } else {
          this.props.changePanMap({
            x: 0,
            y: 100,
          });
        }
      });
      setUtagView(
        {
          page_url: config.analytics.baseurl + "/results",
          first_level: "results",
        },
        false
      );
    }
  }

  // React componentWillUnmount
  componentWillUnmount() {
    this.props.disableMarker();
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.markerCurrentPosition.lat !==
        this.props.markerCurrentPosition.lat ||
      prevProps.markerCurrentPosition.lng !==
        this.props.markerCurrentPosition.lng
    ) {
      this.getDangerByCoord();
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToPrivecyCheck()}
        {this.renderRedirect()}
        {this.renderBack()}
        {this.renderRedirectToStart()}
        <div
          className={
            "searchaddressresults__container searchaddressresults__container-" +
            this.state.device
          }
        >
          <FlatBox layout={this.state.mobileSheetLayout}>
            {/* Only on mobile device */}
            {this.state.device === "mobile" ? (
              <>
                <div
                  className="searchaddressresults__handle-container"
                  onClick={this.toggleSheet}
                >
                  <div className="searchaddressresults__handle"></div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* Hazards result view */}
            {this.state.renderInfo === false &&
            this.state.renderHazardInfo === false ? (
              <>
                <div
                  className={
                    "searchaddressresults__responses-container searchaddressresults__responses-container-" +
                    this.state.device
                  }
                >
                  <div
                    className={
                      "font-generic searchaddressresults__responses-title-row searchaddressresults__responses-title-row-" +
                      this.state.device
                    }
                  >
                    Pericolosità della tua area
                  </div>
                  {this.checkDangersResults(this.state.arrayOfDangers) === 0 ? (
                    <>
                      <div
                        className={
                          "font-generic searchaddressresults__responses-text-1-row searchaddressresults__responses-text-1-row-" +
                          this.state.device
                        }
                      >
                        Le icone indicano la{" "}
                        <span className="bold">pericolosità</span> dell’area
                        geografica indicata, in termini di{" "}
                        <span className="bold">probabilità</span> che accada un
                        evento e/o di <span className="bold">intensità</span>{" "}
                        che potrebbe avere.
                      </div>
                      <div
                        className={
                          "font-generic searchaddressresults__responses-text-4-row searchaddressresults__responses-text-4-row-" +
                          this.state.device
                        }
                      >
                        Clicca sulle icone per scoprire maggiori dettagli.
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      "font-generic searchaddressresults__responses-images-row searchaddressresults__responses-images-row-" +
                      this.state.device
                    }
                  >
                    {this.getPies()}
                  </div>
                  <div
                    className={
                      "font-generic searchaddressresults__responses-text-2-row searchaddressresults__responses-text-2-row-" +
                      this.state.device
                    }
                  >
                    Per conoscere la vulnerabilità della tua impresa, puoi
                    rispondere ad alcune domande.
                  </div>
                  <div
                    className={
                      "font-generic searchaddressresults__responses-text-3-row searchaddressresults__responses-text-3-row-" +
                      this.state.device
                    }
                  >
                    <span
                      className="searchaddressresults__responses-info-button"
                      onClick={this.toggleInfo}
                    >
                      Scopri di più sul livello di pericolosità
                    </span>
                  </div>
                  {this.state.device !== "mobile" ? (
                    <>
                      <div
                        className={
                          "searchaddressresults__responses-button-row searchaddressresults__responses-button-row-" +
                          this.state.device
                        }
                      >
                        <div
                          onClick={this.nextStep}
                          className="searchaddressresults__responses-button"
                        >
                          Continua
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>{" "}
              </>
            ) : (
              ""
            )}

            {/* Results info view */}
            {this.state.renderInfo === true ? (
              <>
                <div className="searchaddressresults__info-close">
                  <img
                    onClick={this.toggleInfo}
                    src={
                      require("./../../../assets/system/icon-system-angle-right.svg")
                        .default
                    }
                    alt="Indietro"
                    className="searchaddressresults__info-arrow"
                  />
                </div>
                <div className="searchaddressresults__info-container">
                  <div
                    className={
                      "searchaddressresults__info-title searchaddressresults__info-title-" +
                      this.state.device
                    }
                  >
                    Valutazione del Livello di pericolosità
                  </div>
                  <div className="searchaddressresults__info-text">
                    Il livello di pericolosità viene valutato sulla base di
                    mappe corrispondenti ai 7 pericoli analizzati da DERRIS con
                    l’obiettivo di fornire alle imprese un’indicazione
                    qualitativa del livello di pericolosità. Le mappe sono state
                    realizzate da UnipolSai con il supporto metodologico del
                    Dott. Alessandro Trigila PhD (ISPRA), del Dott. Fausto
                    Guzzetti PhD (CNR IRPI) e del Prof. Stefano Tibaldi, a
                    partire da dati, ove possibile, pubblici e disponibili per
                    l’intero territorio nazionale, scaricati o resi disponibili
                    da ISPRA e da CNR IRPI. In assenza di dati pubblici
                    disponibili per l’intero territorio nazionale, si è fatto
                    riferimento a mappe elaborate da CESI e Meteotec per gli
                    scopi del progetto”
                  </div>
                  {this.state.device !== "mobile" ? (
                    <>
                      <div className="searchaddressresults__info-button">
                        <div
                          onClick={this.nextStep}
                          className="searchaddressresults__responses-button"
                        >
                          Continua
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {/* Hazard info view */}
            {this.state.renderInfo === false &&
            this.state.renderHazardInfo === true ? (
              <>
                <div className="searchaddressresults__info-close">
                  <img
                    onClick={this.toggleHazardInfo}
                    src={
                      require("./../../../assets/system/icon-system-angle-right.svg")
                        .default
                    }
                    alt="Indietro"
                    className="searchaddressresults__info-arrow"
                  />
                </div>
                <div className="searchaddressresults__hazard-info-container">
                  <div
                    className={
                      "searchaddressresults__info-title searchaddressresults__info-title-" +
                      this.state.device
                    }
                  >
                    Dettaglio Pericolosità della tua area
                  </div>
                  <div className="searchaddressresults__pie-row">
                    {this.getPie(this.state.currentHazardInfo)}
                  </div>
                  <div className="searchaddressresults__info-text">
                    {this.state.currentHazardInfo.Description}
                  </div>
                  <div className="searchaddressresults__get-more">
                    <span
                      className="searchaddressresults__responses-info-button"
                      onClick={this.toggleInfo}
                    >
                      Scopri di più sul livello di pericolosità
                    </span>
                  </div>
                  {this.state.device !== "mobile" ? (
                    <>
                      <div className="searchaddressresults__info-button">
                        <div
                          onClick={this.nextStep}
                          className="searchaddressresults__responses-button"
                        >
                          Continua
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {/* Only on mobile device */}
            {this.state.device === "mobile" ? (
              <>
                <div className="searchaddressresults__info-button-mobile">
                  <div
                    onClick={this.nextStep}
                    className="searchaddressresults__responses-button searchaddressresults__responses-button-mobile"
                  >
                    Continua
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </FlatBox>
        </div>
        {this.renderLoader()}
      </>
    );
  }

  // Toggle sheet
  toggleSheet(): void {
    if (this.state.mobileSheetLayout === "small") {
      this.setState({ mobileSheetLayout: "large" });
    } else {
      this.setState({ mobileSheetLayout: "small" });
    }
  }

  // Toggle info
  toggleInfo(): void {
    if (this.state.renderInfo === false) {
      this.setState({ renderInfo: true });
    } else {
      this.setState({ renderInfo: false });
    }
  }

  // Toggle hazard info
  toggleHazardInfo(): void {
    if (this.state.renderHazardInfo === false) {
      this.setState({ renderHazardInfo: true });
    } else {
      this.setState({ renderHazardInfo: false });
    }
  }

  // Get style for pie rendering
  pieStyle = (degree: Number) => ({
    transform: "rotate(" + degree + "deg)",
  });

  // Redirect to step Registration
  renderRedirect(): Object {
    return this.state.redirectTo === "registration" ? (
      <Redirect push to={{ pathname: "/" + config.steps[2] }} />
    ) : (
      ""
    );
  }

  // Redirect to step position
  renderBack(): Object {
    return this.state.redirectTo === "back" ? (
      <Redirect push to={{ pathname: "/" + config.steps[1] }} />
    ) : (
      ""
    );
  }

  // Redirect to first step
  renderRedirectToPrivecyCheck(): Object {
    return this.state.redirectTo === "privacy" ? (
      <Redirect push to={{ pathname: "/" + config.steps[0] }} />
    ) : (
      ""
    );
  }

  // Redirect to step Start
  renderRedirectToStart(): Object {
    return this.state.redirectTo === "start" ? (
      <Redirect push to={{ pathname: "/" + config.steps[3] }} />
    ) : (
      ""
    );
  }

  // Go to the next step
  nextStep(): void {
    if (checkLogin()) {
      this.setState({ redirectTo: "start" });
    } else {
      this.setState({ redirectTo: "registration" });
    }
  }

  // Get the danger by coords with webservices
  getDangerByCoord(): void {
    this.setState({ loader: true }, () => {
      getDanger(
        this.props.markerCurrentPosition.lat,
        this.props.markerCurrentPosition.lng
      )
        .then((result) => {
          if (result.status === 200) {
            const { cookies } = this.props;
            result.json().then((res: any) => {
              this.setState({
                arrayOfDangers: JSON.parse(res),
                loader: false,
              });
              cookies.set(
                "derris_current_danger",
                JSON.stringify(this.cleanPerils(JSON.parse(res))),
                { path: "/" }
              );
            });
          } else {
            console.error(
              "Error while retrieving risks - Status: " + result.status
            );
            this.setState({
              msg: true,
              msgTitle: "Avviso",
              msgText:
                "Si è verificato un problema nel recupero dei fattori di rischio",
            });
            this.setState({ loader: false });
          }
        })
        .catch((error) => {
          console.error("Error while retrieving risks -  " + error);
          this.setState({
            msg: true,
            msgTitle: "Avviso",
            msgText:
              "Si è verificato un problema nel recupero dei fattori di rischio",
          });
          this.setState({ loader: false });
        });
    });
  }

  // Check the danger results status
  checkDangersResults(results: any): number {
    if (
      (!Array.isArray(results) || results.length === 0) &&
      this.state.loader
    ) {
      return 2;
    } else if (
      results.find((currenteResults: any) => currenteResults.Level !== "NA") ===
        undefined &&
      !this.state.loader
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  // Clear perils from useless info
  cleanPerils(perils: any): any {
    if (perils !== undefined) {
      return perils.map((peril: any) => {
        return {
          Id: peril.Id,
          Level: peril.Level,
          Name: peril.Name,
        };
      });
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Get pies charts
  getPies(): Object {
    if (this.checkDangersResults(this.state.arrayOfDangers) === 1) {
      return (
        <>
          <div className="searchaddressresults__pie-message">
            Ci scusiamo, c’è stato un errore nel recuperare le informazioni di
            pericolosità della tua zona. Puoi tuttavia proseguire nell’analisi
            per analizzare nello specifico la vulnerabilità della tua impresa.
          </div>
        </>
      );
    } else if (this.checkDangersResults(this.state.arrayOfDangers) === 2) {
      return (
        <>
          <div className="searchaddressresults__pie-message">
            Il tool sta caricando le informazioni sui fattori di rischio della
            tua zona. Ti chiediamo un attimo per fornirti il risultato.
          </div>
        </>
      );
    } else {
      return this.state.arrayOfDangers.map((danger, index) => {
        if (danger.Level !== null && danger.Level !== undefined) {
          return (
            <div
              key={index}
              id={danger.Id}
              className={
                "searchaddressresults__pie-item " +
                (danger.Description !== undefined && danger.Description !== ""
                  ? "clickable"
                  : "")
              }
              onClick={
                danger.Description !== undefined && danger.Description !== ""
                  ? this.showHazardInfo
                  : undefined
              }
            >
              <div className="searchaddressresults__pie-container">
                <div className="searchaddressresults__pie-img">
                  <div key={index} className="searchaddressresults__pie-hold">
                    <div
                      key={index}
                      className="searchaddressresults__pie"
                      style={this.pieStyle(
                        this.convertRiskToNumber(danger.Level)
                      )}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="searchaddressresults__pie-danger-name">
                <div>{danger.Name}</div>
              </div>
              <div className="searchaddressresults__pie-danger-level">
                <div>{this.convertRiskToWord(danger.Level)}</div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              id={danger.Name.toLowerCase() + "-0"}
              className={
                "searchaddressresults__pie-item " +
                (danger.Description !== undefined && danger.Description !== ""
                  ? "clickable"
                  : "")
              }
              onClick={
                danger.Description !== undefined && danger.Description !== ""
                  ? this.showHazardInfo
                  : undefined
              }
            >
              <div className="searchaddressresults__pie-container">
                <div className="searchaddressresults__pie-img">
                  <div key={index} className="searchaddressresults__pie-hold">
                    <div
                      key={index}
                      className="searchaddressresults__pie"
                      style={this.pieStyle(0)}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="searchaddressresults__pie-danger-name">
                <div>{danger.Name}</div>
              </div>
              <div className="searchaddressresults__pie-danger-level">
                <div>N/A</div>
              </div>
            </div>
          );
        }
      });
    }
  }

  // Get pie charts
  getPie(danger: any): Object {
    if (danger.Level !== null && danger.Level !== undefined) {
      return (
        <div
          id={danger.Id}
          className="searchaddressresults__pie-item"
          onClick={this.showHazardInfo}
        >
          <div className="searchaddressresults__pie-container">
            <div className="searchaddressresults__pie-img">
              <div className="searchaddressresults__pie-hold">
                <div
                  className="searchaddressresults__pie"
                  style={this.pieStyle(this.convertRiskToNumber(danger.Level))}
                ></div>
              </div>
            </div>
          </div>
          <div className="searchaddressresults__pie-danger-name">
            <div>{danger.Name}</div>
          </div>
          <div className="searchaddressresults__pie-danger-level">
            <div>{this.convertRiskToWord(danger.Level)}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={danger.Name.toLowerCase() + "-0"}
          className="searchaddressresults__pie-item"
          onClick={this.showHazardInfo}
        >
          <div className="searchaddressresults__pie-container">
            <div className="searchaddressresults__pie-img">
              <div className="searchaddressresults__pie-hold">
                <div
                  className="searchaddressresults__pie"
                  style={this.pieStyle(0)}
                ></div>
              </div>
            </div>
          </div>
          <div className="searchaddressresults__pie-danger-name">
            <div>{danger.Name}</div>
          </div>
          <div className="searchaddressresults__pie-danger-level">
            <div>N/A</div>
          </div>
        </div>
      );
    }
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Set current hazard info
  showHazardInfo(e: React.MouseEvent) {
    this.setState({
      currentHazardInfo: this.state.arrayOfDangers.find(
        (item) => item.Id.toString() === e.currentTarget.id
      ),
      renderHazardInfo: true,
    });
  }

  // Convert risk value to number
  convertRiskToNumber(risk: String): Number {
    switch (risk) {
      case "L":
        return 40;
      case "M":
        return 90;
      case "MH":
        return 120;
      case "H":
        return 140;
      case "NA":
        return 0;
      default:
        return 0;
    }
  }

  // Convert risk value to word
  convertRiskToWord(risk: String): String {
    switch (risk) {
      case "L":
        return "Basso";
      case "M":
        return "Medio";
      case "MH":
        return "Medio/Alto";
      case "H":
        return "Alto";
      case "NA":
        return "N/A";
      default:
        return "";
    }
  }
}
