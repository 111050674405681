import React from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import MyMap from './mymap/MyMap';
import { getDevice } from './../../lib/responsive-lib';
import config from './../../config/config'
import './MapBackground.css';

interface MapBackgroundProps {
    google: any;
    mapCenter: Object;
    mapZoom: number;
    markerCurrentPosition: Object;
    markerVisible: Boolean;
    mapClickableIcons: any;
    mapFullscreenControl: any;
    mapKeyboardShortcuts: any;
    mapTypeControl: any;
    mapRotateControl: any;
    mapScaleControl: any;
    mapStreetViewControl: any;
    mapZoomControl: any;
    markerDraggable: any;
    infoWindowIsVisible: Boolean;
    panMap: any;
    setCookiesAddressInfo: any;
    mapBackgroundEnabled: boolean;
    getCookiesAddressInfo: any;
}

interface MapBackgroundState {
    mapRender: Boolean;
    device: String;
    footerStatus: Boolean;
}
export class MapBackground extends React.Component<MapBackgroundProps, MapBackgroundState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            mapRender: false,
            device: '',
            footerStatus: false
        }

        // Method binding
        this.handleLessText = this.handleLessText.bind(this);
        this.handleMoreText = this.handleMoreText.bind(this);
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            mapRender: true,
            device: getDevice()
        });
    }

    // React render
    render() {
        return <>
            <div className={this.props.mapBackgroundEnabled ? "mapbackground__container mapbackground__container-" + this.state.device : ''} >
                <div id="map" className={this.props.mapBackgroundEnabled ? "mapbackground__map" : ''}></div>
            </div>
            {this.props.mapBackgroundEnabled ? this.renderMap() : ''}
        </>
    }

    // Show less text on footer
    handleLessText(e: React.MouseEvent): void {
        this.setState({ footerStatus: false })
    }

    // Show more text on footer
    handleMoreText(e: React.MouseEvent): void {
        this.setState({ footerStatus: true })
    }

    // Render Google Maps
    renderMap(): Object {
        if (this.state.mapRender) {
            return <>
                <MyMap
                    mapCenter={this.props.mapCenter}
                    mapZoom={this.props.mapZoom}
                    markerCurrentPosition={this.props.markerCurrentPosition}
                    markerVisible={this.props.markerVisible}
                    mapClickableIcons={this.props.mapClickableIcons}
                    mapFullscreenControl={this.props.mapFullscreenControl}
                    mapKeyboardShortcuts={this.props.mapKeyboardShortcuts}
                    mapTypeControl={this.props.mapTypeControl}
                    mapRotateControl={this.props.mapRotateControl}
                    mapScaleControl={this.props.mapScaleControl}
                    mapStreetViewControl={this.props.mapStreetViewControl}
                    mapZoomControl={this.props.mapZoomControl}
                    markerDraggable={this.props.markerDraggable}
                    infoWindowIsVisible={this.props.infoWindowIsVisible}
                    panMap={this.props.panMap}
                    setCookiesAddressInfo={this.props.setCookiesAddressInfo}
                    getCookiesAddressInfo={this.props.getCookiesAddressInfo}
                />
            </>;
        } else {
            return <></>;
        }
    }
}

export default GoogleApiWrapper({
    apiKey: (config.maps.apiKey)
})(MapBackground)