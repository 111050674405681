import "./home-banner.css";

interface HomeBannerProps {}

export function HomeBanner({}: HomeBannerProps) {
  return (
    <div className="home-banner-main-container">
      <div className="home-banner-container">
        <div>
          <span>
            <strong>Partner del progetto LIFE DERRIS:</strong>
          </span>
          <div className="home-banner-icon-container">
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/1.png")})`,
              }}></div>
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/2.png")})`,
              }}></div>
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/3.png")})`,
              }}></div>
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/4.png")})`,
              }}></div>
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/5.png")})`,
              }}></div>
            <div
              style={{
                backgroundImage: `url(${require("./../../assets/logos/partners/6.png")})`,
              }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
