import React from "react";
import { getDevice } from "../../../lib/responsive-lib";
import "./QuestionsSheets.css";

interface QuestionsSheetsProps {
  questions: any;
  currentHazard: number;
  sheetStyle: any;
  currentQuestion: number;
  handleMultiAnswer: any;
  handleAnswer: any;
  setMultiAnswer: any;
}

interface QuestionsSheetsState {
  device: String;
}
export default class QuestionsSheets extends React.Component<
  QuestionsSheetsProps,
  QuestionsSheetsState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
    };
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return this.props.questions.length > 0
      ? this.props.questions
          .map((currentQuestion: any, indexQuestions: number) => {
            if (currentQuestion.Hazard.Id === this.props.currentHazard) {
              if (
                (currentQuestion.answerSaved === undefined ||
                  currentQuestion.answerSaved === "") &&
                currentQuestion.Answer !== undefined &&
                currentQuestion.Answer !== null &&
                currentQuestion.Answer.MainValue !== undefined
              ) {
                currentQuestion.answerSaved =
                  currentQuestion.Answer.MainValue === "Y" ? "yes" : "no";
              }
              return (
                <div
                  key={indexQuestions}
                  className={
                    "questionssheets__questionsheet questionssheets__questionsheet-" +
                    (indexQuestions === this.props.currentQuestion
                      ? "enabled"
                      : "disabled") +
                    " questionssheets__questionsheet-" +
                    this.state.device
                  }
                  style={this.props.sheetStyle}>
                  <div className="questionssheets__questionsheet-header">
                    <div className="questionssheets__question-header-logo-container">
                      <img
                        src={require("./../../../assets/product/rischi/compact/" +
                          currentQuestion.Hazard.Name.toLowerCase().replace(
                            "à",
                            "a"
                          ) +
                          "-compact.svg")}
                        alt={currentQuestion.Hazard.Name}
                        className={
                          "questionssheets__question-header-logo questionssheets__question-header-logo-" +
                          this.state.device
                        }
                      />
                    </div>
                    <div className="questionssheets__question-header-title-container">
                      {currentQuestion.Hazard.Name}
                    </div>
                  </div>
                  <div
                    className={
                      "questionssheets__questionsheet-multiple-answer " +
                      (currentQuestion.ShowMultipleAnswers &&
                      indexQuestions === this.props.currentQuestion
                        ? "shifted"
                        : "standard")
                    }>
                    <div className="questionssheets__questionsheet-answer-text">
                      {currentQuestion.Text}
                    </div>
                    <div
                      className={
                        "questionssheets__questionsheet-answer-list-container " +
                        (currentQuestion.ShowMultipleAnswers &&
                        indexQuestions === this.props.currentQuestion
                          ? "shifted"
                          : "standard")
                      }>
                      <ul className="questionssheets__questionsheet-answer-list">
                        {currentQuestion.MultipleAnswers.map(
                          (question: any, questionIndex: number) => {
                            return (
                              <li
                                key={questionIndex}
                                className="questionssheets__questionsheet-answer-item">
                                <div className="questionssheets__questionsheet-icon-container">
                                  {question.checked ? (
                                    <img
                                      id={
                                        "on-" +
                                        indexQuestions +
                                        "-" +
                                        questionIndex
                                      }
                                      src={require("./../../../assets/system/square_fill.png")}
                                      className="questionssheets__box-img"
                                      alt={
                                        "Risposta " + question.Name + " attiva"
                                      }
                                      onClick={
                                        indexQuestions ===
                                        this.props.currentQuestion
                                          ? this.props.handleMultiAnswer
                                          : undefined
                                      }
                                    />
                                  ) : (
                                    <img
                                      id={
                                        "off-" +
                                        indexQuestions +
                                        "-" +
                                        questionIndex
                                      }
                                      src={require("./../../../assets/system/square_empty.png")}
                                      className="questionssheets__box-img"
                                      alt={
                                        "Risposta " +
                                        question.Name +
                                        " disattiva"
                                      }
                                      onClick={
                                        indexQuestions ===
                                        this.props.currentQuestion
                                          ? this.props.handleMultiAnswer
                                          : undefined
                                      }
                                    />
                                  )}
                                </div>
                                <div className="questionssheets__questionsheet-text-container">
                                  {question.Name}
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  {/* Button Bar Standard*/}
                  <div
                    className={
                      "questionssheets__button-container questionssheets__button-container-" +
                      (indexQuestions === this.props.currentQuestion
                        ? "enabled"
                        : "disabled") +
                      " " +
                      (currentQuestion.ShowMultipleAnswers &&
                      indexQuestions === this.props.currentQuestion
                        ? "shifted"
                        : "standard")
                    }>
                    <div
                      id={
                        "yes-" +
                        indexQuestions +
                        "-" +
                        currentQuestion.Hazard.Id +
                        "-" +
                        (currentQuestion.ShowMultipleAnswersWhen !== null
                          ? "multiple"
                          : "single")
                      }
                      className={
                        "questionssheets__button-yes-container " +
                        (indexQuestions === this.props.currentQuestion
                          ? "questionssheets__button-yes-container-click"
                          : "")
                      }
                      onClick={
                        indexQuestions === this.props.currentQuestion
                          ? this.props.handleAnswer
                          : undefined
                      }>
                      <div className="questionssheets__button-img-container">
                        {currentQuestion.answerSaved !== undefined &&
                        currentQuestion.answerSaved === "yes" ? (
                          <img
                            src={require("./../../../assets/system/circle_full.png")}
                            alt="Risposta sì non selezionata"
                            className={
                              "questionssheets__yes-img-full questionssheets__yes-img-full-" +
                              this.state.device
                            }
                          />
                        ) : (
                          <img
                            src={require("./../../../assets/system/circle_empty.png")}
                            alt="Risposta no selezionata"
                            className={
                              "questionssheets__yes-img-empty questionssheets__yes-img-empty-" +
                              this.state.device
                            }
                          />
                        )}
                      </div>
                      <div className="questionssheets__button-text-container">
                        Sì
                      </div>
                    </div>
                    <div
                      id={
                        "no-" +
                        indexQuestions +
                        "-" +
                        currentQuestion.Hazard.Id +
                        "-" +
                        (currentQuestion.ShowMultipleAnswersWhen !== null
                          ? "multiple"
                          : "single")
                      }
                      className={
                        "questionssheets__button-no-container " +
                        (indexQuestions === this.props.currentQuestion
                          ? "questionssheets__button-no-container-click"
                          : "")
                      }
                      onClick={
                        indexQuestions === this.props.currentQuestion
                          ? this.props.handleAnswer
                          : undefined
                      }>
                      <div className="questionssheets__button-img-container">
                        {currentQuestion.answerSaved !== undefined &&
                        currentQuestion.answerSaved === "no" ? (
                          <img
                            src={require("./../../../assets/system/circle_full.png")}
                            alt="Risposta no non selezionata"
                            className={
                              "questionssheets__no-img-full questionssheets__no-img-full-" +
                              this.state.device
                            }
                          />
                        ) : (
                          <img
                            src={require("./../../../assets/system/circle_empty.png")}
                            alt="Risposta no selezionata"
                            className={
                              "questionssheets__no-img-empty questionssheets__no-img-empty-" +
                              this.state.device
                            }
                          />
                        )}
                      </div>
                      <div className="questionssheets__button-text-container">
                        No
                      </div>
                    </div>
                  </div>
                  {/* Button Bar Multi Answer*/}
                  <div
                    className={
                      "questionssheets__button-multi-container questionssheets__button-multi-container-" +
                      (indexQuestions === this.props.currentQuestion
                        ? "enabled"
                        : "disabled") +
                      " " +
                      (currentQuestion.ShowMultipleAnswers &&
                      indexQuestions === this.props.currentQuestion
                        ? "shifted"
                        : "standard")
                    }>
                    <div
                      id={
                        "multi-" +
                        indexQuestions +
                        "-" +
                        currentQuestion.Hazard.Id
                      }
                      onClick={
                        indexQuestions === this.props.currentQuestion
                          ? this.props.setMultiAnswer
                          : undefined
                      }
                      className="questionssheets__button-multi-ok">
                      Conferma
                    </div>
                  </div>
                </div>
              );
            } else return null;
          })
          .filter((item: any) => item !== null)
      : [];
  }
}
