import React from "react";
import { getDevice } from "../../lib/responsive-lib";
import { Redirect } from "react-router-dom";
import config from "../../config/config";
import { logout } from "../../lib/general-lib";
import "./UserMenu.css";

interface UserMenuProps {
  cookies: any;
}

interface UserMenuState {
  device: String;
  redirectToRecovery: boolean;
  goToSites: boolean;
}
export default class UserMenu extends React.Component<
  UserMenuProps,
  UserMenuState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      redirectToRecovery: false,
      goToSites: false,
    };

    // Method binding
    this.redirectToRecovery = this.redirectToRecovery.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToRecovery()}
        {this.renderRedirectToSites()}
        <div className="usermenu__usermenu-container">
          <div className="usermenu__usermenu-email">{this.getUserEmail()}</div>
          <div
            className="usermenu__usermenu-change-password"
            onClick={this.redirectToRecovery}
          >
            Modifica password
          </div>
          <div className="usermenu__usermenu-row"></div>
          <div
            className="usermenu__usermenu-logout"
            onClick={() => {
              this.setState({ goToSites: true });
            }}
          >
            Le tue sedi
          </div>
          <div
            className="usermenu__usermenu-logout"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </div>
        </div>
      </>
    );
  }

  getUserEmail(): string {
    const { cookies } = this.props;
    return cookies.get("derris_user");
  }

  // Redirect to recovery component
  redirectToRecovery(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({ redirectToRecovery: true });
  }

  // Render the redirect component
  renderRedirectToRecovery() {
    return this.state.redirectToRecovery === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[6] }} />
    ) : (
      ""
    );
  }

  // Render the redirect component
  renderRedirectToSites() {
    return this.state.goToSites === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[3] }} />
    ) : (
      ""
    );
  }
}
