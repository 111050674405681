import { getAuthResponse } from './../proxy/api';
import config from './../../config/config';

/**
 * getQuestionnaire
 * 
 * (Call webservices to get questionnaire)
 * 
 * @param {String} siteId Place Id
 * @param {String} token token for auth
 * @param {String} force force or not to create a new questionnaire
 * @returns Promise calling questionnaire service
 */
export const getQuestionnaire = (siteId: string, token: string, create: boolean): Promise<any> => {
    switch (create) {
        case false:
            return getAuthResponse(
                "GET",
                config.webservices.places.endpoint + "/" + siteId + "/questionnaire",
                null,
                token
            );
        case true:
        default:
            return getAuthResponse(
                "POST",
                config.webservices.places.endpoint + "/" + siteId + "/questionnaire?force=true",
                null,
                token
            );
    }
}


/**
 * checkQuestionnaire
 * 
 * (Call webservices to check if questionnaire exist)
 * 
 * @param {String} siteId Place Id
 * @param {String} token token for auth
 * @returns Promise calling questionnaire service
 */
export const checkQuestionnaire = (siteId: string, token: string): Promise<any> => {
    return getAuthResponse(
        "HEAD",
        config.webservices.places.endpoint + "/" + siteId + "/questionnaire",
        null,
        token
    );
}


/**
 * saveQuestionnaire
 * 
 * (Call webservices to save questionnaire)
 * 
 * @param {String} siteId Place Id
 * @param {String} token token for auth
 * @param {String} responses responses to save
 * @returns Promise calling questionnaire service
 */
export const saveQuestionnaire = (siteId: string, token: string, responses: any): Promise<any> => {
    return getAuthResponse(
        "PUT",
        config.webservices.places.endpoint + "/" + siteId + "/questionnaire",
        JSON.stringify(responses),
        token
    );
}