import React from "react";
import ProgressBar from "./../../progressbar/ProgressBar";
import { Redirect } from "react-router-dom";
import { getDevice } from "./../../../lib/responsive-lib";
import Loader from "./../../loader/Loader";
import MainBox from "./../../mainbox/MainBox";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import { checkPrivacy, checkLogin } from "./../../../lib/general-lib";
import {
  createNewPlace,
  getPlaces,
  deletePlaces,
} from "./../../../api/places-api/api";
import { setUtagView, trimString } from "./../../../lib/general-lib";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import ConfirmPopup from "./../../confirmpopup/ConfirmPopup";
import "./Start.css";
import config from "../../../config/config";
import { HomeBanner } from "../../home-banner";

interface StartProps {
  cookies?: any;
  disableMarker: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  setMapStatus: any;
  getCookiesAddressInfo: any;
  deleteCookiesCoordinates: any;
}

interface StartState {
  device: String;
  places: Array<any>;
  redirectToPrivacy: Boolean;
  redirectToNewPalce: boolean;
  newPlacesChecked: boolean;
  loader: Boolean;
  redirectToQuestionnaire: boolean;
  placeId: string;
  editMode: boolean;
  placeSelected: { [key: number]: any };
  error: boolean;
  errorTitle: string;
  errorText: string;
  confirm: boolean;
  confirmTitle: string;
  confirmText: string;
  confirmOkText: string;
  confirmKoText: string;
  confirmCallBack: Object;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class Start extends React.Component<StartProps, StartState> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      places: [],
      redirectToPrivacy: false,
      redirectToNewPalce: false,
      newPlacesChecked: false,
      loader: false,
      redirectToQuestionnaire: false,
      placeId: "",
      editMode: false,
      placeSelected: {},
      error: false,
      errorTitle: "",
      errorText: "",
      confirm: false,
      confirmTitle: "",
      confirmText: "",
      confirmOkText: "",
      confirmKoText: "",
      confirmCallBack: {},
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.redirectToNewPlace = this.redirectToNewPlace.bind(this);
    this.startQuestionnaire = this.startQuestionnaire.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.togglePlaceCheckbox = this.togglePlaceCheckbox.bind(this);
    this.deleteSelectedPlaces = this.deleteSelectedPlaces.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.toggleConfirmPopup = this.toggleConfirmPopup.bind(this);
    this.performDeleting = this.performDeleting.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    if (!checkPrivacy() || !checkLogin()) {
      this.setState({ redirectToPrivacy: true });
    } else {
      if (getDevice() !== "mobile") {
        this.props.setMapStatus(true);
      }
      this.setState(
        {
          device: getDevice(),
          loader: true,
        },
        () => {
          this.saveSelectedPlace();
        }
      );
      // Analytics
      setUtagView(
        {
          page_url: config.analytics.baseurl + "/start",
          first_level: "start",
        },
        false
      );
    }
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.newPlacesChecked !== this.state.newPlacesChecked &&
      this.state.newPlacesChecked === true
    ) {
      this.getAllPlaces();
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToPrivecyCheck()}
        {this.renderRedirectToNewPlace()}
        {this.renderRedirectToQuestionnaire()}
        <div className="start__container">
          <MainBox>
            <div className="start__header">
              <MBHeader
                device={this.state.device}
                loginEnabled={true}
                closeEnabled={false}
                cookies={this.props.cookies}
                parent={config.steps[3]}
              />
            </div>
            <div className="start__progressbar">
              <ProgressBar step={"03"} />
            </div>
            <div
              className={
                "start__text-row-1 start__text-row-1-" + this.state.device
              }>
              <span className="bold">
                Selezionando l’indirizzo potrai iniziare il questionario di
                autovalutazione.
              </span>{" "}
              Oppure usa <span className="bold">Seleziona ed elimina</span> per
              eliminare le tue sedi.
            </div>
            <div
              className={
                "start__text-row-2 start__text-row-2-" +
                (this.state.editMode ? "on" : "off") +
                " start__text-row-2-" +
                this.state.device
              }>
              <img
                onClick={this.toggleEditMode}
                src={require("./../../../assets/misc/trash_" +
                  (this.state.editMode ? "OFF" : "ON") +
                  ".svg")}
                alt="Icona seleziona ed elimina"
                className="start__trash-icon"
              />
              <div
                onClick={this.toggleEditMode}
                className="start__text-row-2-text-container">
                Seleziona ed elimina
              </div>
              <div className="start__close-edit-mode-icon-container">
                <img
                  onClick={this.toggleEditMode}
                  src={
                    require("./../../../assets/system/icon-system-close-black.svg")
                      .default
                  }
                  alt="Icona seleziona ed elimina"
                  className={
                    "start__close-edit-mode-icon start__close-edit-mode-icon-" +
                    (this.state.editMode ? "off" : "on")
                  }
                />
              </div>
            </div>
            <div
              className={
                "start__search-results-container start__search-results-container-" +
                (this.state.editMode ? "on" : "off") +
                " start__search-results-container-" +
                this.state.device
              }>
              {this.getResults()}
            </div>
            {this.state.editMode === false ? (
              <div
                className={
                  "start__add-button-container start__add-button-container-" +
                  this.state.device
                }>
                <div
                  className="start__add-button"
                  onClick={this.redirectToNewPlace}>
                  <div className="start__add-button-img-container">
                    <img
                      src={
                        require("./../../../assets/product/icon-product-plus.svg")
                          .default
                      }
                      alt="Cerca luogo"
                      className="start__add-button-img"
                    />
                  </div>
                  <div className="start__add-button-text-container">
                    Aggiungi sede
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={
                  "start__delete-button-container start__delete-button-container-" +
                  this.state.device
                }>
                <div
                  className="start__delete-button"
                  onClick={this.deleteSelectedPlaces}>
                  <div className="start__delete-button-text-container">
                    Elimina
                  </div>
                </div>
              </div>
            )}
          </MainBox>
          <HomeBanner />
        </div>
        {this.renderLoader()}
        {this.renderError()}
        {this.renderConfirmPopup()}
      </>
    );
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Render the ErrorPopup component
  renderConfirmPopup(): Object {
    return this.state.confirm ? (
      <ConfirmPopup
        confirmTitle={this.state.confirmTitle}
        confirmText={this.state.confirmText}
        textOk={this.state.confirmOkText}
        textKo={this.state.confirmKoText}
        actionOk={this.state.confirmCallBack}
        actionKo={this.toggleConfirmPopup}
      />
    ) : (
      <></>
    );
  }

  // Toggle ErrorPopup component
  toggleConfirmPopup(): void {
    if (this.state.confirm === false) {
      this.setState({ confirm: true });
    } else {
      this.setState({ confirm: false });
    }
  }

  // Ask for deleting places
  deleteSelectedPlaces() {
    if (this.countSelectedPlaces() === 0) {
      this.setState({
        msgTitle: "Errore",
        msgText: "Nessuna sede selezionata",
        msg: true,
      });
    }
    if (this.countSelectedPlaces() > 0) {
      this.setState({
        confirm: true,
        confirmTitle: "Attenzione",
        confirmText:
          "Sei sicuro di voler cancellare le sedi selezionate? (" +
          this.countSelectedPlaces() +
          " selezionata/e)",
        confirmOkText: "Elimina",
        confirmKoText: "Annulla",
        confirmCallBack: this.performDeleting,
      });
    }
  }

  countSelectedPlaces(): number {
    return Object.keys(this.state.placeSelected).length === 0
      ? 0
      : Object.keys(this.state.placeSelected).reduce(
          (accumulator: any, currentValue: any) => {
            return this.state.placeSelected[currentValue].checked === true
              ? accumulator + 1
              : accumulator;
          },
          0
        );
  }

  // Delete the places selected
  performDeleting(): void {
    let placeToDelete = this.countSelectedPlaces();
    let placeCounter = 0;
    let placeDeleted = 0;
    Object.keys(this.state.placeSelected).forEach((key: any) => {
      if (this.state.placeSelected[key].checked === true) {
        placeCounter = placeCounter + 1;
        const { cookies } = this.props;
        deletePlaces(
          this.state.placeSelected[key].id,
          cookies.get("derris_token")
        )
          .then((result) => {
            if (result.status === 200) {
              result.json().then((res: any) => {
                if (JSON.parse(res).Id !== undefined) {
                  placeDeleted = placeDeleted + 1;
                  if (
                    placeToDelete === placeCounter &&
                    placeToDelete === placeDeleted
                  ) {
                    this.setState(
                      {
                        loader: false,
                        confirm: false,
                        msgTitle: "Avviso",
                        msgText: "Cancellazione avvenuta con successo",
                        msg: true,
                        editMode: false,
                      },
                      () => {
                        this.getAllPlaces();
                      }
                    );
                  } else if (
                    placeToDelete === placeCounter &&
                    placeToDelete > placeDeleted
                  ) {
                    this.setState(
                      {
                        loader: false,
                        confirm: false,
                        msgTitle: "Errore",
                        msgText:
                          "Cancellate solo " +
                          placeDeleted +
                          " sedi su " +
                          placeToDelete +
                          " richieste.",
                        msg: true,
                        editMode: false,
                      },
                      () => {
                        this.getAllPlaces();
                      }
                    );
                  }
                }
              });
            } else {
              console.error(
                "Error deleting place " +
                  this.state.placeSelected[key].id +
                  " - Status: " +
                  result.status
              );
            }
          })
          .catch((error) => {
            console.error(
              "Error deleting place " +
                this.state.placeSelected[key].id +
                " - " +
                error
            );
          });
      }
    });
  }

  // Save the selected places
  saveSelectedPlace() {
    const { cookies } = this.props;
    if (
      this.props.getCookiesAddressInfo() !== undefined &&
      cookies.get("derris_current_danger") !== undefined
    ) {
      const street = this.getAddressItem(
        this.props.getCookiesAddressInfo(),
        "route"
      );
      const streetNumber =
        this.getAddressItem(
          this.props.getCookiesAddressInfo(),
          "street_number"
        ) !== undefined
          ? this.getAddressItem(
              this.props.getCookiesAddressInfo(),
              "street_number"
            )
          : { short_name: "1" };
      const town = this.getAddressItem(
        this.props.getCookiesAddressInfo(),
        "locality"
      );
      const province = this.getAddressItem(
        this.props.getCookiesAddressInfo(),
        "administrative_area_level_2"
      );
      const zipcode = this.getAddressItem(
        this.props.getCookiesAddressInfo(),
        "postal_code"
      );
      const { cookies } = this.props;
      const lat =
        cookies.get("derris_current_coordinates") !== undefined
          ? cookies.get("derris_current_coordinates").lat
          : null;
      const lng =
        cookies.get("derris_current_coordinates") !== undefined
          ? cookies.get("derris_current_coordinates").lng
          : null;
      if (
        street !== undefined &&
        street.short_name !== undefined &&
        town !== undefined &&
        town.short_name !== undefined
      ) {
        createNewPlace(
          street,
          streetNumber,
          town,
          province,
          zipcode,
          lat,
          lng,
          1,
          cookies.get("derris_token"),
          cookies.get("derris_current_danger")
        )
          .then((result) => {
            if (result && result.status === 200) {
              this.props.deleteCookiesCoordinates();
              this.setState({ newPlacesChecked: true });
            } else {
              this.setState({ newPlacesChecked: true });
            }
          })
          .catch((error) => {
            this.setState({ newPlacesChecked: true });
          });
      } else {
        this.setState({ newPlacesChecked: true });
      }
    } else {
      this.setState({ newPlacesChecked: true });
    }
  }

  // Toggle the place edito mode status
  toggleEditMode() {
    if (this.state.editMode === false) {
      this.setState({ editMode: true });
    } else {
      this.setState({ editMode: false });
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Get address item from Google maps reverse geocode
  getAddressItem(addressStruct: Array<any>, item: string): any {
    return addressStruct.find((currentItem) => {
      return currentItem.types[0] === item;
    });
  }

  // Rendere redirect component
  renderRedirectToPrivecyCheck(): Object {
    return this.state.redirectToPrivacy === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[0] }}
      />
    ) : (
      ""
    );
  }

  // Build the results code
  getResults(): Object {
    return (
      <>
        {this.state.places.map((address: any, index: number) => {
          return (
            <span
              key={index}
              className="start__search-results-item-container">
              <div
                className={
                  "start__search-results-checkbox-container start__search-results-checkbox-container-" +
                  (this.state.editMode ? "on" : "off")
                }>
                {this.renderCurrentCheckbox(address.Id)}
              </div>
              <div
                id={address.Id}
                className={
                  "start__search-results-item start__search-results-item-" +
                  (this.state.editMode ? "on" : "off")
                }
                onClick={this.startQuestionnaire}>
                <div
                  className={
                    "start__search-results-text-container start__search-results-text-container-" +
                    (this.state.editMode ? "on" : "off")
                  }>
                  <div className="start__search-results-item-row-1">
                    {address.Street}, {address.StreetNumber}
                  </div>
                  <div className="start__search-results-item-row-2">
                    {address.Town}, {address.Province}
                  </div>
                </div>
                <div
                  className={
                    "start__search-results-arrow-container start__search-results-arrow-container-" +
                    (this.state.editMode ? "on" : "off")
                  }>
                  <img
                    src={
                      require("./../../../assets/system/icon-system-angle-right.svg")
                        .default
                    }
                    alt="Scegli sede"
                    className="start__search-arrow"
                  />
                </div>
              </div>
            </span>
          );
        })}
      </>
    );
  }

  // Render privacy icon
  renderCurrentCheckbox(id: any): any {
    return this.state.placeSelected[id] !== undefined &&
      this.state.placeSelected[id].checked !== undefined &&
      this.state.placeSelected[id].checked === true ? (
      <img
        onClick={this.togglePlaceCheckbox}
        src={require("./../../../assets/system/square_fill.png")}
        alt="Deselezione sede"
        className="start__search-results-checkbox-icon"
        id={id}
      />
    ) : (
      <img
        onClick={this.togglePlaceCheckbox}
        src={require("./../../../assets/system/square_empty.png")}
        alt="Seleziona sede"
        className="start__search-results-checkbox-icon"
        id={id}
      />
    );
  }

  // Toggle the state of place checkbox
  togglePlaceCheckbox(e: React.MouseEvent): void {
    const currentId: number = parseInt(e.currentTarget.id, 10);
    if (
      this.state.placeSelected[currentId] !== undefined &&
      this.state.placeSelected[currentId].checked !== undefined &&
      this.state.placeSelected[currentId].checked === true
    ) {
      this.setState((prevState) => {
        prevState.placeSelected[currentId].checked = false;
        return { placeSelected: prevState.placeSelected };
      });
    } else {
      this.setState((prevState) => {
        prevState.placeSelected[currentId] = {
          checked: true,
          id: currentId,
        };
        return { placeSelected: prevState.placeSelected };
      });
    }
  }

  // Get all places available
  getAllPlaces() {
    const { cookies } = this.props;
    getPlaces("", "", cookies.get("derris_token"))
      .then((result) => {
        if (result.status === 200) {
          result.json().then((res: any) => {
            this.setState({
              places: JSON.parse(res),
              loader: false,
            });
          });
        } else {
          console.error("Error getting place - Status: " + result.status);
          result.json().then((res: any) => {
            this.setState({
              errorTitle: "Errore",
              errorText:
                "Problema nel recupero delle sedi. " +
                trimString(res.Description),
              error: true,
              loader: false,
            });
          });
        }
      })
      .catch((error) => {
        console.error("Error getting place - " + error);
        this.setState({
          errorTitle: "Errore",
          errorText: "Problema nel recupero delle sedi. " + trimString(error),
          error: true,
          loader: false,
        });
      });
  }

  // Rediret to new place view
  redirectToNewPlace() {
    this.setState({ redirectToNewPalce: true });
  }

  // Rendere redirect component
  renderRedirectToNewPlace(): Object {
    return this.state.redirectToNewPalce === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[1] }}
      />
    ) : (
      ""
    );
  }

  // Start the questionnarie for the slelected place
  startQuestionnaire(e: React.MouseEvent) {
    if (this.state.editMode === false) {
      this.setState({ placeId: e.currentTarget.id }, () => {
        this.redirectToQuestionnaire();
      });
    }
  }

  // Rediret to the questionnarie
  redirectToQuestionnaire() {
    this.setState({ redirectToQuestionnaire: true });
  }

  // Rendere redirect to the questionnarie
  renderRedirectToQuestionnaire(): Object {
    return this.state.redirectToQuestionnaire === true ? (
      <Redirect
        to={{
          state: { placeId: this.state.placeId },
          pathname: "/" + config.steps[8] + "/" + this.state.placeId,
        }}
      />
    ) : (
      ""
    );
  }
}
