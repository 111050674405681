import React, { createRef } from "react";
import { getDevice } from "../../../lib/responsive-lib";
import {
  saveCompanyInfo,
  getCompanyInfo,
  editCompanyInfo,
  getFinalPlan,
} from "../../../api/company-api/api";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import Loader from "./../../loader/Loader";
import {
  setUtagView,
  setUtagLink,
  trimString,
  validateEmail,
} from "./../../../lib/general-lib";
import config from "../../../config/config";
import "./ResiliencyPrint.css";

interface ResiliencyPrintProps {
  cookies: any;
  placeInfo: any;
  changeSuggestionType: any;
  results: any;
}

interface ResiliencyPrintState {
  device: String;
  loader: boolean;
  company: string;
  vatNumber: string;
  address: string;
  manager: string;
  technical: string;
  formSaved: boolean;
  fieldFillStatus: boolean;
  error: boolean;
  errorTitle: string;
  errorText: string;
  planPreLoaded: boolean;
  emailPopupStatus: boolean;
  email: Array<string>;
  validEmail: Array<boolean>;
  numberOfEmail: number;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class ResiliencyPrint extends React.Component<
  ResiliencyPrintProps,
  ResiliencyPrintState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      loader: false,
      company: "",
      vatNumber: "",
      address: "",
      manager: "",
      technical: "",
      formSaved: false,
      fieldFillStatus: false,
      error: false,
      errorTitle: "",
      errorText: "",
      planPreLoaded: false,
      emailPopupStatus: false,
      email: [],
      validEmail: [],
      numberOfEmail: 3,
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onVatNumberChange = this.onVatNumberChange.bind(this);
    this.onManagerChange = this.onManagerChange.bind(this);
    this.emptyAddress = this.emptyAddress.bind(this);
    this.savePlan = this.savePlan.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.editPlan = this.editPlan.bind(this);
    this.handleSaveButton = this.handleSaveButton.bind(this);
    this.handleDocumentViewClick = this.handleDocumentViewClick.bind(this);
    this.toggleEmailPopup = this.toggleEmailPopup.bind(this);
    this.onEmailchange = this.onEmailchange.bind(this);
    this.makeEmailEditable = this.makeEmailEditable.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.checkEmailAddress = this.checkEmailAddress.bind(this);
    this.hanldeSendEmails = this.hanldeSendEmails.bind(this);
    this.onTechnicalChange = this.onTechnicalChange.bind(this);
    this.handleReturnToTips = this.handleReturnToTips.bind(this);
  }

  // Class variables
  private searchBoxListener: any = undefined;
  private addressInput = createRef<HTMLInputElement>();
  private company: string = "Ragione Sociale";
  private vatNumber: string = "Partita IVA";
  private address: string = "Indirizzo Sede Legale";
  private addressDefault: string = "Sede Operativa";
  private manager: string = "Responsabile di direzione";
  private technical: string =
    "Referente tecnico per l'adattamento ai cambiamenti climatici";
  private emailInputsRef: Array<any> = [];

  // React componentDidMount
  componentDidMount() {
    this.setState({ device: getDevice() });
    this.initSearchBox();
    this.getPlan();
    this.setState({
      email: Array(this.state.numberOfEmail).join(".").split("."),
    });
    setUtagView(
      {
        page_url:
          config.analytics.baseurl +
          "/tips/" +
          this.props.placeInfo.Id +
          "/plan",
        first_level: "tips",
        second_level: "plan",
      },
      true
    );
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (
      prevState.company !== this.state.company ||
      prevState.vatNumber !== this.state.vatNumber ||
      prevState.address !== this.state.address ||
      prevState.manager !== this.state.manager ||
      prevState.technical !== this.state.technical
    ) {
      if (
        this.state.company !== "" &&
        this.state.vatNumber !== "" &&
        this.state.address !== "" &&
        this.state.manager !== "" &&
        this.state.technical !== ""
      ) {
        this.setState({
          fieldFillStatus: true,
          formSaved: false,
        });
      } else {
        this.setState({
          fieldFillStatus: false,
          formSaved: false,
        });
      }
    }
  }

  // React componentWillUnmount
  componentWillUnmount() {
    google.maps.event.removeListener(this.searchBoxListener);
  }

  // React render
  public render() {
    return (
      <>
        <div
          className={
            "resiliencyprint__container resiliencyprint__container-" +
            this.state.device
          }
        >
          <div
            className={
              "resiliencyprint__header resiliencyprint__header-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__header-row-1 resiliencyprint__header-row-1-" +
                this.state.device
              }
            >
              Anagrafica Aziendale
            </div>
            <div
              className={
                "resiliencyprint__header-row-2 resiliencyprint__header-row-2-" +
                this.state.device
              }
            >
              Potrai scegliere se ricevere il tuo{" "}
              <span className="bold">Piano</span> tramite{" "}
              <span className="bold">Email</span> oppure{" "}
              <span className="bold">Stamparlo</span>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box">
                {this.company} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="resiliencyprint__edit-form-input-company"
                  type="text"
                  className="resiliencyprint__edit-form-input"
                  onChange={this.onCompanyChange}
                  value={this.state.company}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box">
                {this.vatNumber} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="resiliencyprint__edit-form-input-vatnumber"
                  type="text"
                  className="resiliencyprint__edit-form-input"
                  onChange={this.onVatNumberChange}
                  value={this.state.vatNumber}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box">
                {this.address} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="searchaddress__search-input"
                  type="text"
                  placeholder=""
                  className="resiliencyprint__edit-form-input"
                  onClick={this.emptyAddress}
                  ref={this.addressInput}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box default-text">
                {this.addressDefault} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="resiliencyprint__edit-form-input-place"
                  type="text"
                  className="resiliencyprint__edit-form-input default-text"
                  defaultValue={this.getPlace(this.props.placeInfo)}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box">
                {this.manager} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="resiliencyprint__edit-form-input-manager"
                  type="text"
                  className="resiliencyprint__edit-form-input"
                  onChange={this.onManagerChange}
                  value={this.state.manager}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "resiliencyprint__edit-form resiliencyprint__edit-form-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__edit-form-field resiliencyprint__edit-form-field-" +
                this.state.device
              }
            >
              <div className="resiliencyprint__edit-form-label-box">
                {this.technical} *
              </div>
              <div className="resiliencyprint__edit-form-input-box">
                <input
                  id="resiliencyprint__edit-form-input-manager"
                  type="text"
                  className="resiliencyprint__edit-form-input"
                  onChange={this.onTechnicalChange}
                  value={this.state.technical}
                />
              </div>
            </div>
          </div>
          <div className="resiliencyprint__form-note">
            * La compilazione di tutti i campi è obbligatoria
          </div>
          <div className="resiliencyprint__form-advice">
            Potrai in qualsiasi momento modificare, integrare o aggiornare il
            tuo piano, tornando nella sezione Suggerimenti. In particolare per
            completare il tuo piano, puoi compilare le informazioni aggiuntive
            (Responsabile, Data di conclusione prevista, Budget previsto e Note)
            per ciascun suggerimento selezionato. Per proseguire nella
            compilazione di tali informazioni, clicca{" "}
            <span className="link" onClick={this.handleReturnToTips}>
              qui.
            </span>
          </div>
          <div
            className={
              "resiliencyprint__button-bar resiliencyprint__button-bar-" +
              this.state.device
            }
          >
            <div
              className={
                "resiliencyprint__button-save-container resiliencyprint__button-save-container-" +
                this.state.device
              }
            >
              <div
                className={
                  "resiliencyprint__button-save resiliencyprint__button-save-" +
                  this.state.device +
                  " resiliencyprint__button-save-" +
                  (this.state.formSaved ? "on" : "off") +
                  " resiliencyprint__button-save-" +
                  (this.state.fieldFillStatus ? "enabled" : "disabled")
                }
                onClick={this.handleSaveButton}
              >
                {this.getSaveButtonContent()}
              </div>
            </div>
            <div
              className={
                "resiliencyprint__button-view-container resiliencyprint__button-view-container-" +
                this.state.device
              }
            >
              <div
                className={
                  "resiliencyprint__button-view resiliencyprint__button-view-" +
                  this.state.device +
                  " resiliencyprint__button-view-" +
                  (this.state.formSaved || this.state.planPreLoaded
                    ? "on"
                    : "off")
                }
                onClick={this.handleDocumentViewClick}
              >
                Visualizza PDF
              </div>
            </div>
            <div
              className={
                "resiliencyprint__button-send-container resiliencyprint__button-send-container-" +
                this.state.device
              }
            >
              <div
                className={
                  "resiliencyprint__button-send resiliencyprint__button-send-" +
                  this.state.device +
                  " resiliencyprint__button-send-" +
                  (this.state.formSaved || this.state.planPreLoaded
                    ? "on"
                    : "off")
                }
                onClick={this.toggleEmailPopup}
              >
                Invia PDF
              </div>
            </div>
          </div>
        </div>
        {this.renderLoader()}
        {this.renderError()}
        {this.getEmailPopup()}
      </>
    );
  }

  // Return the email popup code
  getEmailPopup() {
    if (this.state.emailPopupStatus) {
      return (
        <>
          <div className="emailpopup__container">
            <div className="emailpopup__background"></div>
            <div
              className={
                "emailpopup__popup emailpopup__popup-" + this.state.device
              }
            >
              <div
                className="emailpopup__close-icon-container"
                onClick={this.toggleEmailPopup}
              >
                <img
                  src={
                    require("./../../../assets/system/icon-system-close-black.svg")
                      .default
                  }
                  alt="Close popup email"
                  className="emailpopup__close-icon"
                />
              </div>
              <div className="emailpopup__title-container">
                Ricevi il tuo Piano di Autovalutazione via Email
              </div>
              <div
                className={
                  "emailpopup__sub-container emailpopup__sub-container-" +
                  this.state.device
                }
              >
                Puoi aggiungere anche altri indirizzi oltre a quello già fornito
                in fase di registrazione
              </div>
              <div
                className={
                  "emailpopup__body-container emailpopup__body-container-" +
                  this.state.device
                }
              >
                <div
                  className={
                    "emailpopup__edit-form-title emailpopup__edit-form-title-" +
                    this.state.device
                  }
                >
                  Stai inviando a:
                </div>
                {this.getEmailInputFields()}
              </div>
              <div className="emailpopup__footer-container">
                <div className="emailpopup__footer-button-container">
                  <div
                    className="emailpopup__button-ok"
                    onClick={this.hanldeSendEmails}
                  >
                    Invia
                  </div>
                </div>
                <div className="emailpopup__footer-text-container">
                  Puoi inviare fino a un massimo di 3 Email
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  // Return the input fields code
  getEmailInputFields() {
    return (
      <>
        <div
          className={
            "emailpopup__edit-form-field emailpopup__edit-form-field" +
            this.state.device
          }
        >
          {this.state.email.map((currentEmail: string, index: number) => {
            if (!this.state.validEmail[index]) {
              this.emailInputsRef[index] = createRef<HTMLInputElement>();
              return (
                <span key={index}>
                  <div
                    className={
                      "emailpopup__edit-form-input-box emailpopup__edit-form-input-box-" +
                      this.state.device
                    }
                  >
                    <input
                      id={"email-" + index.toString()}
                      type="text"
                      className={
                        "emailpopup__edit-form-input emailpopup__edit-form-input-" +
                        this.state.device
                      }
                      onChange={this.onEmailchange}
                      onBlur={this.onEmailBlur}
                      value={currentEmail}
                      ref={this.emailInputsRef[index]}
                    />
                  </div>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  <div
                    id={"email-" + index.toString()}
                    className={
                      "emailpopup__edit-form-input-box emailpopup__edit-form-input-box-" +
                      this.state.device
                    }
                    onClick={this.makeEmailEditable}
                  >
                    <div
                      id={"email-" + index.toString()}
                      className={
                        "emailpopup__edit-form-input-text emailpopup__edit-form-input-text-" +
                        this.state.device
                      }
                    >
                      {currentEmail}
                    </div>
                  </div>
                </span>
              );
            }
          })}
        </div>
      </>
    );
  }

  // Handle return to tips
  handleReturnToTips(e: React.MouseEvent) {
    if (this.props.results.NTotUnspecifiedSuggestions > 0) {
      this.props.changeSuggestionType("OTHER");
    } else if (this.props.results.NPlannedSuggestions > 0) {
      this.props.changeSuggestionType("DA_APPLICARE");
    } else if (this.props.results.NDoneSuggestions > 0) {
      this.props.changeSuggestionType("GIA_IN_ESSERE");
    } else if (this.props.results.NNASuggestions > 0) {
      this.props.changeSuggestionType("NON_APPLICABILI");
    }
  }

  // Handle the save button
  handleSaveButton(e: React.MouseEvent) {
    if (this.state.planPreLoaded) {
      this.editPlan();
    } else {
      this.savePlan();
    }
  }

  // Handle the click on the button to get the document
  handleDocumentViewClick(e: React.MouseEvent) {
    const { cookies } = this.props;
    const url =
      config.webservices.plan.endpoint +
      "/" +
      this.props.placeInfo.Id +
      "/plan?token=" +
      cookies.get("derris_token");
    if (window.open(url, "_blank") === null) {
      setUtagLink(
        {
          page_url:
            config.analytics.baseurl +
            "/tips/" +
            this.props.placeInfo.Id +
            "/plan",
          first_level: "tips",
          second_level: "plan",
          event_name: "stampa_piano",
          ga_eventCategory: "stampa_piano",
          ga_eventaction: "Stampa",
          ga_eventLabel: "fail",
        },
        true
      );
    } else {
      setUtagLink(
        {
          page_url:
            config.analytics.baseurl +
            "/tips/" +
            this.props.placeInfo.Id +
            "/plan",
          first_level: "tips",
          second_level: "plan",
          event_name: "stampa_piano",
          ga_eventCategory: "stampa_piano",
          ga_eventaction: "Stampa",
          ga_eventLabel: "success",
        },
        true
      );
    }
  }

  // Open/close the popup for insert multiple email address
  toggleEmailPopup() {
    if (this.state.emailPopupStatus) {
      this.setState({ emailPopupStatus: false });
    } else {
      this.setState({ emailPopupStatus: true });
    }
  }

  // Make email field editable
  makeEmailEditable(e: React.MouseEvent) {
    const emailId = Number(e.currentTarget.id.split("-")[1]);
    const newValidEmail = [...this.state.validEmail];
    newValidEmail[emailId] = false;
    this.setState({ validEmail: newValidEmail }, () => {
      if (this.emailInputsRef[emailId] !== null) {
        this.emailInputsRef[emailId].current.focus();
      }
    });
  }

  // Send the builded document by email
  hanldeSendEmails(e: React.MouseEvent) {
    this.setState(
      {
        emailPopupStatus: false,
        loader: true,
      },
      () => {
        if (this.checkEmailAddress()) {
          const { cookies } = this.props;
          getFinalPlan(
            this.props.placeInfo.Id,
            this.state.email.filter((item) => item !== ""),
            cookies.get("derris_token")
          )
            .then((result: any) => {
              if (result.status === 200) {
                this.setState({
                  loader: false,
                  msg: true,
                  msgTitle: "Avviso",
                  msgText: "Email inviate con successo",
                });
                setUtagLink(
                  {
                    page_url:
                      config.analytics.baseurl +
                      "/tips/" +
                      this.props.placeInfo.Id +
                      "/plan",
                    first_level: "tips",
                    second_level: "plan",
                    event_name: "stampa_piano",
                    ga_eventCategory: "stampa_piano",
                    ga_eventaction: "Invio email",
                    ga_eventLabel: "success",
                  },
                  true
                );
              } else {
                this.setState({ loader: false }, () => {
                  result.json().then((res: any) => {
                    console.error("Error sending document. " + res.Description);
                    this.setState({
                      loader: false,
                      msg: true,
                      msgTitle: "Errore",
                      msgText:
                        "Impossibile generare il documento. " +
                        trimString(res.Description),
                    });
                    setUtagLink(
                      {
                        page_url:
                          config.analytics.baseurl +
                          "/tips/" +
                          this.props.placeInfo.Id +
                          "/plan",
                        first_level: "tips",
                        second_level: "plan",
                        event_name: "stampa_piano",
                        ga_eventCategory: "stampa_piano",
                        ga_eventaction: "Invio email",
                        ga_eventLabel: "fail",
                      },
                      true
                    );
                  });
                });
              }
            })
            .catch((error) => {
              this.setState({ loader: false }, () => {
                console.error("Error sending document. " + error);
                this.setState({
                  loader: false,
                  msg: true,
                  msgTitle: "Errore",
                  msgText:
                    "Impossibile generare il documento. " +
                    trimString(error.toString()),
                });
                setUtagLink(
                  {
                    page_url:
                      config.analytics.baseurl +
                      "/tips/" +
                      this.props.placeInfo.Id +
                      "/plan",
                    first_level: "tips",
                    second_level: "plan",
                    event_name: "stampa_piano",
                    ga_eventCategory: "stampa_piano",
                    ga_eventaction: "Invio email",
                    ga_eventLabel: "fail",
                  },
                  true
                );
              });
            });
        }
      }
    );
  }

  // Check email address validity before request pdf
  checkEmailAddress(): boolean {
    const badAddress = this.state.email
      .map((currentEmail: string) =>
        currentEmail !== "" && !validateEmail(currentEmail)
          ? currentEmail
          : null
      )
      .filter((item) => item !== null);
    if (badAddress.length === 0) {
      return true;
    } else {
      this.setState({
        loader: false,
        msg: true,
        msgTitle: "Errore",
        msgText:
          "I seguenti indirizzi non sono validi: " + badAddress.join(", "),
      });
      return false;
    }
  }

  // Save data in the form
  savePlan(): void {
    if (!this.state.formSaved) {
      this.setState({ loader: true }, () => {
        if (
          this.state.company !== "" &&
          this.state.vatNumber !== "" &&
          this.state.address !== "" &&
          this.state.manager !== "" &&
          this.state.technical !== ""
        ) {
          const { cookies } = this.props;
          saveCompanyInfo(
            this.state.company,
            this.state.vatNumber,
            this.state.manager,
            this.state.technical,
            { Id: this.props.placeInfo.Id },
            this.state.address,
            cookies.get("derris_token")
          )
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res: any) => {
                  this.setState({
                    loader: false,
                    formSaved: true,
                  });
                  setUtagLink(
                    {
                      page_url:
                        config.analytics.baseurl +
                        "/tips/" +
                        this.props.placeInfo.Id +
                        "/plan",
                      first_level: "tips",
                      second_level: "plan",
                      event_name: "stampa_piano",
                      ga_eventCategory: "stampa_piano",
                      ga_eventaction: "Salva",
                      ga_eventLabel: "success",
                    },
                    true
                  );
                });
              } else {
                result.json().then((res: any) => {
                  console.error(
                    "Error while save company info. " + res.Description
                  );
                  this.setState({
                    loader: false,
                    error: true,
                    errorTitle: "Errore",
                    errorText:
                      "Impossibile salvare le informazioni inserite. " +
                      trimString(res.Description),
                  });
                  setUtagLink(
                    {
                      page_url:
                        config.analytics.baseurl +
                        "/tips/" +
                        this.props.placeInfo.Id +
                        "/plan",
                      first_level: "tips",
                      second_level: "plan",
                      event_name: "stampa_piano",
                      ga_eventCategory: "stampa_piano",
                      ga_eventaction: "Salva",
                      ga_eventLabel: "fail",
                    },
                    true
                  );
                });
              }
            })
            .catch((error) => {
              console.error("Error while save company info. " + error);
              this.setState({
                loader: false,
                error: true,
                errorTitle: "Errore",
                errorText:
                  "Impossibile salvare le informazioni inserite. " +
                  trimString(error.toString()),
              });
              setUtagLink(
                {
                  page_url:
                    config.analytics.baseurl +
                    "/tips/" +
                    this.props.placeInfo.Id +
                    "/plan",
                  first_level: "tips",
                  second_level: "plan",
                  event_name: "stampa_piano",
                  ga_eventCategory: "stampa_piano",
                  ga_eventaction: "Salva",
                  ga_eventLabel: "fail",
                },
                true
              );
            });
        } else {
          this.setState({
            loader: false,
            msg: true,
            msgTitle: "Avviso",
            msgText:
              "I seguenti campi non sono stati compilati correttamente: " +
              this.getEmptyFlieds(),
          });
        }
      });
    }
  }

  // Save data in the form
  editPlan(): void {
    if (!this.state.formSaved) {
      this.setState({ loader: true }, () => {
        if (
          this.state.company !== "" &&
          this.state.vatNumber !== "" &&
          this.state.address !== "" &&
          this.state.manager !== "" &&
          this.state.technical !== ""
        ) {
          const { cookies } = this.props;
          editCompanyInfo(
            this.state.company,
            this.state.vatNumber,
            this.state.manager,
            this.state.technical,
            { Id: this.props.placeInfo.Id },
            this.state.address,
            cookies.get("derris_token")
          )
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res: any) => {
                  this.setState({
                    loader: false,
                    formSaved: true,
                  });
                });
                setUtagLink(
                  {
                    page_url:
                      config.analytics.baseurl +
                      "/tips/" +
                      this.props.placeInfo.Id +
                      "/plan",
                    first_level: "tips",
                    second_level: "plan",
                    event_name: "stampa_piano",
                    ga_eventCategory: "stampa_piano",
                    ga_eventaction: "Salva",
                    ga_eventLabel: "success",
                  },
                  true
                );
              } else {
                result.json().then((res: any) => {
                  console.error(
                    "Error while edit company info. " + res.Description
                  );
                  this.setState({
                    loader: false,
                    error: true,
                    errorTitle: "Errore",
                    errorText:
                      "Impossibile modificare l'anagrafica aziendale. " +
                      trimString(res.Description),
                  });
                  setUtagLink(
                    {
                      page_url:
                        config.analytics.baseurl +
                        "/tips/" +
                        this.props.placeInfo.Id +
                        "/plan",
                      first_level: "tips",
                      second_level: "plan",
                      event_name: "stampa_piano",
                      ga_eventCategory: "stampa_piano",
                      ga_eventaction: "Salva",
                      ga_eventLabel: "fail",
                    },
                    true
                  );
                });
              }
            })
            .catch((error) => {
              console.error("Error while edit company info. " + error);
              this.setState({
                loader: false,
                error: true,
                errorTitle: "Errore",
                errorText:
                  "Impossibile modificare l'anagrafica aziendale. " +
                  trimString(error.toString()),
              });
              setUtagLink(
                {
                  page_url:
                    config.analytics.baseurl +
                    "/tips/" +
                    this.props.placeInfo.Id +
                    "/plan",
                  first_level: "tips",
                  second_level: "plan",
                  event_name: "stampa_piano",
                  ga_eventCategory: "stampa_piano",
                  ga_eventaction: "Salva",
                  ga_eventLabel: "fail",
                },
                true
              );
            });
        } else {
          this.setState({
            loader: false,
            msg: true,
            msgTitle: "Avviso",
            msgText:
              "I seguenti campi non sono stati compilati correttamente: " +
              this.getEmptyFlieds(),
          });
        }
      });
    }
  }

  // Save data in the form
  getPlan(): void {
    this.setState({ loader: true }, () => {
      const { cookies } = this.props;
      getCompanyInfo(cookies.get("derris_token"))
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res: any) => {
              this.setState(
                {
                  manager: JSON.parse(res).Director,
                  company: JSON.parse(res).Name,
                  vatNumber: JSON.parse(res).VATNumber,
                  address: this.getPlace(JSON.parse(res).OperationalAddress),
                  technical: JSON.parse(res).TechnicalContact,
                },
                () => {
                  if (
                    this.addressInput.current !== undefined &&
                    this.addressInput.current !== null
                  ) {
                    this.addressInput.current.value = this.getPlace(
                      JSON.parse(res).OperationalAddress
                    );
                    this.setState({
                      loader: false,
                      formSaved: true,
                      planPreLoaded: true,
                    });
                  } else {
                    this.setState({
                      loader: false,
                      formSaved: true,
                      planPreLoaded: true,
                    });
                  }
                }
              );
            });
          } else if (result.status === 404) {
            result.json().then((res: any) => {
              console.error("No existent plan found. " + res.Description);
              this.setState({ loader: false });
            });
          } else {
            result.json().then((res: any) => {
              console.error(
                "Error while getting company info. " + res.Description
              );
              this.setState({ loader: false });
            });
          }
        })
        .catch((error) => {
          console.error("Error while getting company info " + error);
          this.setState({ loader: false });
        });
    });
  }

  // Return the field empty in the form
  getEmptyFlieds(): string {
    const emptyFields: Array<any> = [];
    if (this.state.address === "") {
      emptyFields.push(this.address);
    }
    if (this.state.company === "") {
      emptyFields.push(this.company);
    }
    if (this.state.vatNumber === "") {
      emptyFields.push(this.vatNumber);
    }
    if (this.state.manager === "") {
      emptyFields.push(this.manager);
    }
    if (this.state.technical === "") {
      emptyFields.push(this.technical);
    }
    return emptyFields.join(", ");
  }

  // Return the correct content for the save button
  getSaveButtonContent(): Object {
    switch (this.state.formSaved) {
      case true:
        return (
          <>
            <img
              src={require("./../../../assets/product/EEE017F1-146D-4D4D-8F81-3B80261E3D4B.png")}
              alt="Piano salvato"
              className={
                "resiliencyprint__button-save-icon resiliencyprint__button-save-icon-" +
                this.state.device
              }
            />
            Piano salvato
          </>
        );
      case false:
        return <>Salva piano</>;
      default:
        return <></>;
    }
  }

  // Return the place string
  getPlace(addressObject: any): string {
    if (addressObject.Street !== undefined && addressObject.Town) {
      return (
        addressObject.Street +
        ", " +
        addressObject.StreetNumber +
        ", " +
        addressObject.Town +
        ", " +
        addressObject.Province +
        ", " +
        addressObject.ZipCode
      );
    } else {
      return "";
    }
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Initialize Google Search Box
  initSearchBox(): void {
    /*global google*/
    const input = document.getElementById(
      "searchaddress__search-input"
    ) as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.searchBoxListener = searchBox.addListener("places_changed", () => {
      this.setAddress(searchBox.getPlaces()[0]);
    });
  }

  // Empty address var on state
  emptyAddress(e: React.MouseEvent): void {
    if (
      this.addressInput.current !== undefined &&
      this.addressInput.current !== null
    ) {
      this.addressInput.current.value = "";
      this.setState({ address: "" });
    }
  }

  // Handle email change
  onEmailchange(e: React.FormEvent<HTMLInputElement>): void {
    const newEmail = [...this.state.email];
    const emailId = Number(e.currentTarget.id.split("-")[1]);
    newEmail[emailId] = e.currentTarget.value;
    this.setState({ email: newEmail });
  }

  //Handle email blur
  onEmailBlur(e: React.FormEvent<HTMLInputElement>): void {
    const emailId = Number(e.currentTarget.id.split("-")[1]);
    const newValidEmail = [...this.state.validEmail];
    if (validateEmail(this.state.email[emailId])) {
      newValidEmail[emailId] = true;
    } else {
      newValidEmail[emailId] = false;
    }
    this.setState({ validEmail: newValidEmail });
  }

  // Handle company changes
  onCompanyChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ company: e.currentTarget.value });
  }

  // Handle vat number changes
  onVatNumberChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ vatNumber: e.currentTarget.value });
  }

  // Handle manager change
  onManagerChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ manager: e.currentTarget.value });
  }

  // Handle technical change
  onTechnicalChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ technical: e.currentTarget.value });
  }
  // Set the address search results
  setAddress(place: any): void {
    this.setState({ address: place.formatted_address });
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }
}
