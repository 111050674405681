import config from './../../config/config';
import { getResponse } from './../proxy/api'
/**
 * callServices
 * 
 * (Call webservice to get courses list)
 * 
 * @param {String} wstoken Current user token (Moodle params: 'wstoken')
 * @param {String} wsfunction Current webservices function (Moodle params: 'wsfunction')
 * @param {String} moodlewsrestformat Current format for rest webservices (Moodle params: 'moodlewsrestformat')
 * @returns Promise calling courses list service
 */
export const getDanger = (lat: String, lng: String): Promise<any> => {
    return getResponse(
        "GET",
        config.webservices.danger.endpoint + "?lat=" + lat + "&lng=" + lng,
        null
    )
}