import React from "react";
import { getDevice } from "../../lib/responsive-lib";
import MenuItems from "./menuItems/MenuItems";
import "./Sidebar.css";

interface SidebarProps {
  sidebarStatus: boolean;
  placeInfo: any;
  currentView: string;
  results: any;
  placeId: string;
  locked: boolean;
  changeSuggestionType?: any;
  suggestionType?: string;
  toggleSidebar?: any;
  toggleResiliencyView: any;
  toggleResiliencyPrintView: any;
}

interface SidebarState {
  device: String;
}
export default class Sidebar extends React.Component<
  SidebarProps,
  SidebarState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
    };
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return (
      <>
        <div
          className={
            "sidebar__container sidebar__container-" +
            this.state.device +
            (this.props.sidebarStatus && !this.props.locked
              ? " open"
              : " close") +
            (this.props.sidebarStatus && !this.props.locked
              ? " open-"
              : " close-") +
            this.state.device
          }
        >
          <div className={"sidebar_header sidebar_header-" + this.state.device}>
            <img
              src={
                require("./../../assets/product/icon-product-pin-blue.svg")
                  .default
              }
              alt="Icona sede selezionata"
              className="sidebar_header-geo-icon"
            />
            <div
              className={
                "sidebar_header-title sidebar_header-title-" + this.state.device
              }
            >
              Sede selezionata
            </div>
            <div
              className={
                "sidebar_header-address sidebar_header-address-" +
                this.state.device
              }
            >
              <div
                className={
                  "sidebar_header-row1 sidebar_header-row1-" + this.state.device
                }
              >
                {this.props.placeInfo.Street},{" "}
                {this.props.placeInfo.StreetNumber}
              </div>
              <div
                className={
                  "sidebar_header-row2 sidebar_header-row2-" + this.state.device
                }
              >
                {this.props.placeInfo.ZipCode}, {this.props.placeInfo.Town}
              </div>
            </div>
          </div>
          <div className={"sidebar_body sidebar_body-" + this.state.device}>
            <MenuItems
              currentView={this.props.currentView}
              results={this.props.results}
              placeId={this.props.placeId}
              changeSuggestionType={this.props.changeSuggestionType}
              suggestionType={this.props.suggestionType}
              toggleSidebar={this.props.toggleSidebar}
              toggleResiliencyView={this.props.toggleResiliencyView}
              toggleResiliencyPrintView={this.props.toggleResiliencyPrintView}
            />
          </div>
        </div>
      </>
    );
  }
}
