import React from 'react';
import { getDevice } from '../../../lib/responsive-lib';
import HazardTip from './hazardTip/HazardTip';
import './HazardsTips.css';

interface HazardsTipsProps {
    results: any;
    currentHazard: number;
    handleChoice: any;
    location?: any;
    suggestionType: any;
    placeId: string;
    cookies: any;
    reloadSuggestions: any;
    switchToHazard: any;
}

interface HazardsTipsState {
    device: String;
    currentSuggestions: any;
    editMode: boolean;
    hazardsChecked: boolean;
}

export default class HazardsTips extends React.Component<HazardsTipsProps, HazardsTipsState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: '',
            currentSuggestions: {},
            editMode: false,
            hazardsChecked: false
        }

        // Method binding
        this.handleChoice = this.handleChoice.bind(this);
        this.getNotEmptyHazard = this.getNotEmptyHazard.bind(this);
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            device: getDevice(),
            currentSuggestions: this.orderSuggestios(this.filterSuggestions(this.getCurrentSuggestions()))
        });
    }

    // React componentDidUpdate
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.currentHazard !== this.props.currentHazard) {
            this.setState({ currentSuggestions: this.orderSuggestios(this.filterSuggestions(this.getCurrentSuggestions())) });
        }
        if (JSON.stringify(prevProps.results) !== JSON.stringify(this.props.results)) {
            this.setState({ currentSuggestions: this.orderSuggestios(this.filterSuggestions(this.getCurrentSuggestions())) });
        }
        if (prevProps.suggestionType !== this.props.suggestionType) {
            this.setState({ currentSuggestions: this.orderSuggestios(this.filterSuggestions(this.getCurrentSuggestions())) });
        }
        if (prevProps.suggestionType !== this.props.suggestionType && this.props.results.SuggestionsByPeril !== undefined) {
            this.getNotEmptyHazard();
        }
        if (this.props.results.SuggestionsByPeril !== undefined && this.state.hazardsChecked === false) {
            this.getNotEmptyHazard();
        }
        if (this.props.results.SuggestionsByPeril !== undefined && (JSON.stringify(prevProps.results.SuggestionsByPeril) !== JSON.stringify(this.props.results.SuggestionsByPeril))) {
            this.getNotEmptyHazard();
        }
    }

    // React render
    public render() {
        return <>
            <div className="hazardstips__container">
                <div className="hazardstips__container-flex">
                    {this.getCurrentPageContent()}
                </div>
            </div>
        </>
    }

    // Return the first not empty hazard
    getNotEmptyHazard() {
        this.setState({ hazardsChecked: true }, () => {
            if (!this.isValidSuggestionsArray(this.getCurrentSuggestions())) {
                const BreakException = {};
                try {
                    this.props.results.SuggestionsByPeril.forEach((currentHazard: any) => {
                        if (this.isValidSuggestionsArray(currentHazard.Suggestions)) {
                            this.props.switchToHazard(currentHazard.Id, currentHazard.Name);
                            throw BreakException;
                        }
                    });
                } catch (e) { }
            }
        });
    }

    // Return true if a suggestion array is valid for the current suggestion type
    isValidSuggestionsArray(suggestions: Array<any>): Boolean {
        if (Array.isArray(suggestions)) {
            return suggestions.find((currentSuggestion: any) =>
                currentSuggestion.State !== undefined &&
                ((currentSuggestion.State !== null && currentSuggestion.State.Id === this.getTipIdByName(this.props.suggestionType)) ||
                    (currentSuggestion.State === null && this.getTipIdByName(this.props.suggestionType) === 0))
            ) !== undefined;
        } else return false
    }

    // Return the tip Id bay tip name
    getTipIdByName(tipName: string): number {
        switch (tipName) {
            case "NON_APPLICABILI":
                return 1;
            case "GIA_IN_ESSERE":
                return 2;
            case "DA_APPLICARE":
                return 3;
            default:
                return 0;
        }
    }

    // Build the page content
    getCurrentPageContent(): any {
        switch (true) {
            case (this.state.currentSuggestions.Suggestions !== undefined && this.state.currentSuggestions.Suggestions.length > 0):
                return <>
                    {this.state.currentSuggestions.Suggestions.map((suggestion: any, index: number) =>
                        <HazardTip
                            suggestion={suggestion}
                            handleChoice={this.handleChoice}
                            key={index}
                            suggestionType={this.props.suggestionType}
                            placeId={this.props.placeId}
                            cookies={this.props.cookies}
                            reloadSuggestions={this.props.reloadSuggestions}
                        />)}
                </>;
            case (this.props.results.NTotUnspecifiedSuggestions === 0 && this.props.suggestionType === 'OTHER'):
                return <>
                    <div className="hazardstips__container-message">
                        Tutti i suggerimenti sono stati organizzati, ora puoi accedere direttamente alla sezione di tuo interesse (indice di resilienza o stampa il piano) o modificare le tue scelte dal dal  menù in alto a sinistra.
                    </div>
                </>
            default:
                return <>
                    <div className="hazardstips__container-message">
                        Al momento per questo rischio non ci sono suggerimenti da visualizzare.
                </div>
                </>
        }
    }

    // Get the current suggestion from the suggestions
    getCurrentSuggestions(): any {
        return (this.props.currentHazard !== 0 && this.props.results !== {}) ?
            this.props.results.SuggestionsByPeril.find((currentSuggestion: any) => currentSuggestion.Id === this.props.currentHazard)
            : {};
    }

    filterSuggestions(suggestionsObj: any): Array<any> {
        if (suggestionsObj.Suggestions === undefined) return suggestionsObj;
        let currentSuggestionsObj = { ...suggestionsObj };
        switch (this.props.suggestionType) {
            case "DA_APPLICARE":
                currentSuggestionsObj.Suggestions = currentSuggestionsObj.Suggestions.map((suggestion: any) => suggestion.State !== null && suggestion.State.Id === 3 ? suggestion : null).filter((item: any) => item !== null);
                return currentSuggestionsObj;
            case "GIA_IN_ESSERE":
                currentSuggestionsObj.Suggestions = currentSuggestionsObj.Suggestions.map((suggestion: any) => suggestion.State !== null && suggestion.State.Id === 2 ? suggestion : null).filter((item: any) => item !== null);
                return currentSuggestionsObj;
            case "NON_APPLICABILI":
                currentSuggestionsObj.Suggestions = currentSuggestionsObj.Suggestions.map((suggestion: any) => suggestion.State !== null && suggestion.State.Id === 1 ? suggestion : null).filter((item: any) => item !== null);
                return currentSuggestionsObj;
            case "OTHER":
                currentSuggestionsObj.Suggestions = currentSuggestionsObj.Suggestions.map((suggestion: any) => suggestion.State === null ? suggestion : null).filter((item: any) => item !== null);
                return currentSuggestionsObj;
            default:
                return suggestionsObj;
        }
    }

    // Order the suggestions array
    orderSuggestios(suggestionsObj: any): Array<any> {
        if (suggestionsObj.Suggestions !== undefined) {
            let currentSuggestions = { ...suggestionsObj };
            let suggestionsOrdered: Array<any> = [];
            suggestionsObj.Suggestions.forEach((suggestion: any) => {
                suggestionsOrdered[Number(suggestion.Order) - 1] = suggestion;
            });
            currentSuggestions.Suggestions = suggestionsOrdered;
            return currentSuggestions;
        } else {
            return [];
        }
    }

    // Handle click on tips radio buttons
    handleChoice(hazardId: string, suggestionId: string, choice: string, choiceText: string, suggestionText: string) {
        this.props.handleChoice(hazardId, suggestionId, choice, choiceText, suggestionText);
    }
}