import React from "react";
import config from "./../../../config/config";
import { getDevice } from "./../../../lib/responsive-lib";
import MainBox from "./../../mainbox/MainBox";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import Loader from "./../../loader/Loader";
import { recoverPassword } from "./../../../api/auth-api/api";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import { Redirect } from "react-router-dom";
import { setUtagView, validateEmail } from "./../../../lib/general-lib";
import "./PasswordRecovery.css";

interface PasswordRecoveryProps {
  cookies?: any;
  disableMarker: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  setMapStatus: any;
}

interface PasswordRecoveryState {
  device: String;
  loader: Boolean;
  id: string;
  errorBox: Boolean;
  errorBoxText: string;
  error: boolean;
  errorTitle: string;
  errorText: string;
  redirectToLogin: boolean;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class PasswordRecovery extends React.Component<
  PasswordRecoveryProps,
  PasswordRecoveryState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      loader: false,
      id: "",
      errorBox: false,
      errorBoxText: "",
      error: false,
      errorTitle: "",
      errorText: "",
      redirectToLogin: false,
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.onIdChange = this.onIdChange.bind(this);
    this.performPasswordRecovery = this.performPasswordRecovery.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.props.disableMarker();
    this.props.setInfoWindowVisibility(false);
    this.props.setMapZoom(config.maps.default.zoom);
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    this.setState({ device: getDevice() });
    // Analytics
    setUtagView(
      {
        page_url: config.analytics.baseurl + "/recovery",
        first_level: "recovery",
      },
      false
    );
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToLogin()}
        <div className="passrec__container">
          <MainBox>
            <div className="passrec__header">
              <MBHeader
                device={this.state.device}
                loginEnabled={false}
                closeEnabled={true}
                cookies={this.props.cookies}
                parent={config.steps[5]}
                previous="login"
              />
            </div>
            <div className="passrec__content">
              <div
                className={"passrec__title passrec__title-" + this.state.device}
              >
                Inserisci qui il tuo Indirizzo Email e riceverai tutte le
                istruzioni per ottenere una nuova password.
              </div>
              <div className="passrec__msg-container">
                <div
                  className={
                    "passrec__msg-box passrec__msg-box-" +
                    this.state.device +
                    " " +
                    (this.state.errorBox ? "enabled" : "disabled")
                  }
                >
                  <div className="passrec__error-content">
                    <div className="passrec__error-ico-container">
                      <img
                        src={
                          require("./../../../assets/system/icon-system-error.svg")
                            .default
                        }
                        alt="Error Logo"
                        className="passrec__error-ico"
                      />
                    </div>
                    <div className="passrec__error-msg-container">
                      {this.state.errorBoxText}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "passrec__recovery-form passrec__recovery-form-" +
                  this.state.device
                }
              >
                <div
                  className={
                    "passrec__input-box passrec__input-box-" + this.state.device
                  }
                >
                  <input
                    id="passrec__input-id"
                    type="text"
                    className="passrec__id"
                    onChange={this.onIdChange}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="passrec__button-access-container">
                <div
                  className={"passrec__button-access"}
                  onClick={this.performPasswordRecovery}
                >
                  Invia
                </div>
              </div>
            </div>
          </MainBox>
        </div>
        {this.renderLoader()}
        {this.renderError()}
      </>
    );
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Handle email changes
  onIdChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ id: e.currentTarget.value });
  }

  // Perform password recovery
  performPasswordRecovery() {
    if (!validateEmail(this.state.id)) {
      this.setState({
        errorBoxText: "Indirizzo email non corretto",
        errorBox: true,
      });
    } else {
      this.setState({ loader: true }, () => {
        recoverPassword(this.state.id)
          .then((result) => {
            if (result.status === 200) {
              this.setState({
                msgTitle: "Richiesta inviata",
                msgText:
                  "Controlla la tua posta elettronica: tra poco riceverai un link per il reset della password",
                msg: true,
                loader: false,
              });
            } else if (result.status === 400) {
              result.json().then((res: any) => {
                if (res.Description !== undefined) {
                  this.setState({
                    errorBoxText: res.Description.split("-")[1],
                    errorBox: true,
                    loader: false,
                  });
                  console.error("Error: " + res.Description);
                } else {
                  this.setState({
                    errorBoxText: "Errore nel recupero della password",
                    errorBox: true,
                    loader: false,
                  });
                  console.error("Password recovery error");
                }
              });
            } else {
              this.setState({
                errorBoxText: "Errore nel recupero della password",
                errorBox: true,
                loader: false,
              });
              console.error(
                "Password recovery error - Status: " + result.status
              );
            }
          })
          .catch((error) => {
            this.setState({
              errorBoxText: "Errore nel recupero della password",
              errorBox: true,
              loader: false,
            });
            console.error("Password recovery error - " + error);
          });
      });
    }
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else if (this.state.msg === true) {
      this.setState({
        msg: false,
        redirectToLogin: true,
      });
    }
  }

  // Rendere redirect component
  renderRedirectToLogin(): Object {
    return this.state.redirectToLogin === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[5] }} />
    ) : (
      ""
    );
  }
}
