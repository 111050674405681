import React from 'react';
import { getDevice } from '../../../lib/responsive-lib';
import { getResiliencyIndex } from './../../../api/suggestions-api/api';
import Loader from './../../loader/Loader';
import './ResiliencyIndex.css';

interface ResiliencyIndexProps {
    cookies: any;
    placeId: string;
}

interface ResiliencyIndexState {
    device: String;
    resiliencyIndex: Array<any>;
    errorMsg: string;
    loader: boolean;
}

export default class ResiliencyIndex extends React.Component<ResiliencyIndexProps, ResiliencyIndexState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: '',
            resiliencyIndex: [],
            errorMsg: '',
            loader: false
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            device: getDevice()
        });
        this.localGetResiliencyIndex();
    }

    // React render
    public render() {
        return this.state.resiliencyIndex.length > 0 || this.state.errorMsg !== '' ? <>
            <div className={"resiliencyindex__container resiliencyindex__container-" + this.state.device}>
                <div className="resiliencyindex__header">
                    <div className={"resiliencyindex__header-row-1 resiliencyindex__header-row-1-" + this.state.device}>
                        Indice di relisilienza
                    </div>
                    <div className={"resiliencyindex__header-row-2 resiliencyindex__header-row-2-" + this.state.device}>
                        Aspetti gestiti dall’Azienda
                    </div>
                </div>
                {this.state.errorMsg === '' ? <>
                    <div className={"resiliencyindex__table-container resiliencyindex__table-container-" + this.state.device}>
                        {this.getIndexTable()}
                    </div>
                    <div className={"resiliencyindex__content-footer resiliencyindex__content-footer-" + this.state.device}>
                        <div className={"resiliencyindex__content-footer-box resiliencyindex__content-footer-box-left resiliencyindex__content-footer-box-" + this.state.device}>
                            <div className="resiliencyindex__table-circle resiliencyindex__table-circle-green"></div>
                            <div className={"resiliencyindex__content-footer-box-text resiliencyindex__content-footer-box" + this.state.device}>
                                Aspetto gestio dall’azienda
                            </div>
                        </div>
                        <div className={"resiliencyindex__content-footer-box resiliencyindex__content-footer-box-center resiliencyindex__content-footer-box-" + this.state.device}>
                            <div className="resiliencyindex__table-circle resiliencyindex__table-circle-orange"></div>
                            <div className={"resiliencyindex__content-footer-box-text resiliencyindex__content-footer-box" + this.state.device}>
                                Aspetto parzialmente gestito dall’azienda
                            </div>
                        </div>
                        <div className={" resiliencyindex__content-footer-box resiliencyindex__content-footer-box-right resiliencyindex__content-footer-box-" + this.state.device}>
                            <div className="resiliencyindex__table-circle resiliencyindex__table-circle-red"></div>
                            <div className={"resiliencyindex__content-footer-box-text resiliencyindex__content-footer-box" + this.state.device}>
                                Aspetto attualmente non gestito dall’azienda
                            </div>
                        </div>
                    </div>
                </> : <>
                        <div className={"resiliencyindex__msg-container resiliencyindex__msg-container-" + this.state.device}>
                            {this.state.errorMsg}
                        </div>
                    </>
                }
            </div>
            {this.renderLoader()}
        </> : <></>
    }

    // Get resiliency index
    localGetResiliencyIndex() {
        const { cookies } = this.props;
        this.setState({ loader: true }, () => {
            getResiliencyIndex(this.props.placeId, cookies.get('derris_token')).then(result => {
                if (result.status === 200) {
                    result.json().then((res: any) => {
                        if (JSON.parse(res).length > 0) {
                            this.setState({
                                resiliencyIndex: JSON.parse(res),
                                loader: false
                            })
                        } else {
                            this.setState({
                                errorMsg: "Problema nel generare le informazioni sull'indice di resilienza.",
                                loader: false
                            });
                        }
                    });
                } else {
                    this.setState({
                        errorMsg: "Problema nel generare le informazioni sull'indice di resilienza. Status: " + result.status,
                        loader: false
                    });
                    console.error("Error while getting resiliency index. Status: " + result.status);
                }
            }).catch(error => {
                this.setState({
                    errorMsg: "Problema nel generare le informazioni sull'indice di resilienza. " + error,
                    loader: false
                });
                console.error("Error while getting resiliency index. " + error);
            });
        })

    }

    getIndexTable(): Object {
        return <>
            <table className="resiliencyindex__table">
                <thead>
                    <tr className="resiliencyindex__table-tr-header">
                        <th>Attuale</th>
                        <th>Dopo gli interventi</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.resiliencyIndex.map((row: any, index: number) => <tr key={index} className="resiliencyindex__table-tr-row">
                        <td className="resiliencyindex__table-td resiliencyindex__table-td-currentvalue">
                            {this.getCircle(row.CurrentValue)}
                        </td>
                        <td className="resiliencyindex__table-td resiliencyindex__table-td-futurevalue">
                            {this.getCircle(row.FutureValue)}
                        </td>
                        <td className="resiliencyindex__table-td resiliencyindex__table-td-descriptions">
                            {row.Description}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </>
    }

    getCircle(colorValue: number): Object {
        switch (colorValue) {
            case 0:
                return <div className="resiliencyindex__table-circle resiliencyindex__table-circle-red"></div>
            case 1:
                return <div className="resiliencyindex__table-circle resiliencyindex__table-circle-orange"></div>
            case 2:
                return <div className="resiliencyindex__table-circle resiliencyindex__table-circle-green"></div>
            default:
                return <></>
        }

    }

    // Render Loader component
    renderLoader(): Object {
        return (this.state.loader) ? <Loader /> : <></>;
    }
}