import React from "react";
import { getDevice } from "../../lib/responsive-lib";
import { Redirect } from "react-router-dom";
import config from "../../config/config";
import UserMenu from "../userMenu/UserMenu";
import "./QwHeader.css";

interface QwHeaderProps {
  currentHazard: number;
  lastHazard: number;
  questions: any;
  partialResultsVisible: boolean;
  hazardDone: any;
  switchToHazard: any;
  toggleSidebar: any;
  title: string;
  hazardBarStatus: boolean;
  cookies: any;
  sidebarStatus: boolean;
  toggleMenuOverlayStatus: any;
  menuOverlayStatus: boolean;
}

interface QwHeaderState {
  device: String;
  barData: Array<any>;
  localCurrentPosition: number;
  redirectString: string;
  userMenuStatus: boolean;
  redirectToRecovery: boolean;
}
export default class QwHeader extends React.Component<
  QwHeaderProps,
  QwHeaderState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      barData: [],
      localCurrentPosition: 0,
      redirectString: "",
      userMenuStatus: false,
      redirectToRecovery: false,
    };

    // Method binding
    this.goToHazard = this.goToHazard.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.redirectToRecovery = this.redirectToRecovery.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
      localCurrentPosition: this.getBarPosition(this.props.currentHazard),
    });
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevProps.questions) !==
      JSON.stringify(this.props.questions)
    ) {
      if (this.props.questions !== undefined)
        this.setState({ barData: this.buildBarData() });
    }
    if (
      prevProps.currentHazard !== this.props.currentHazard &&
      this.props.partialResultsVisible === false
    ) {
      this.setState({
        localCurrentPosition: this.getBarPosition(this.props.currentHazard),
      });
    }
    if (
      prevProps.partialResultsVisible !== this.props.partialResultsVisible &&
      this.props.partialResultsVisible === false
    ) {
      this.setState({
        localCurrentPosition: this.getBarPosition(this.props.currentHazard),
      });
    }
    if (
      prevProps.menuOverlayStatus !== this.props.menuOverlayStatus &&
      this.props.menuOverlayStatus === false
    ) {
      this.setState({ userMenuStatus: false });
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToRecovery()}
        <div
          className={
            "qwheader__container qwheader__container-" + this.state.device
          }>
          <div
            className={
              "qwheader__level1bar qwheader__level1bar-" + this.state.device
            }>
            <div className="qwheader__level1bar-bmenu">
              <img
                src={
                  require("./../../assets/system/icon-system-burger-men.svg")
                    .default
                }
                alt="Burger menù"
                className={"qwheader__logo qwheader__logo-" + this.state.device}
                onClick={this.toggleSidebar}
              />
            </div>
            <div
              className={
                "qwheader__level1bar-title qwheader__level1bar-title-" +
                this.state.device
              }>
              {this.props.title}
            </div>
            <div className="qwheader__level1bar-userlogo">
              <div
                className={
                  "qwheader__userlogo-box qwheader__userlogo-box-" +
                  this.state.device
                }>
                <img
                  src={
                    require("./../../assets/product/icon-product-user-no-log-white.svg")
                      .default
                  }
                  alt="Menù utente"
                  className={
                    "qwheader__userlogo qwheader__userlogo-" + this.state.device
                  }
                  onClick={this.toggleUserMenu}
                />
                {this.state.userMenuStatus ? (
                  <UserMenu cookies={this.props.cookies} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {this.props.hazardBarStatus ? (
            <>
              <div
                className={
                  "qwheader__level2bar qwheader__level2bar-" + this.state.device
                }>
                <div
                  className={
                    "qwheader__level2bar-icons-row qwheader__level2bar-icons-row-" +
                    this.state.device
                  }>
                  {this.getDangerBar()}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  // Render the bar with the various dangers
  getDangerBar(): any {
    switch (true) {
      case this.state.device === "mobile" &&
        this.state.localCurrentPosition === 0:
        return this.state.barData.map((danger: any, index: number) =>
          index <= 2 ? this.getDangerIcon(danger.id, danger) : ""
        );
      case this.state.device === "mobile" &&
        this.state.localCurrentPosition > 0 &&
        this.state.localCurrentPosition < this.state.barData.length - 1:
        return this.state.barData.map((danger: any, index: number) =>
          index >= this.state.localCurrentPosition - 1 &&
          index <= this.state.localCurrentPosition + 1
            ? this.getDangerIcon(danger.id, danger)
            : ""
        );
      case this.state.device === "mobile" &&
        this.state.localCurrentPosition === this.state.barData.length - 1:
        return this.state.barData.map((danger: any, index: number) =>
          index >= this.state.localCurrentPosition - 2
            ? this.getDangerIcon(danger.id, danger)
            : ""
        );
      default:
        return this.state.barData.map((danger: any, index: number) =>
          this.getDangerIcon(danger.id, danger)
        );
    }
  }

  // Render a signle danger
  getDangerIcon(dangerId: number, danger: any): any {
    const hazard = this.props.partialResultsVisible
      ? this.props.lastHazard
      : this.props.currentHazard;
    return (
      <div
        key={dangerId}
        id={danger.name.toLowerCase()}
        onClick={this.goToHazard}
        style={this.getDangerStyle(danger.name.toLowerCase())}
        className={
          "qwheader__level2bar-icon-container qwheader__level2bar-icon-container-" +
          (hazard === dangerId ? "full" : "compact") +
          " " +
          danger.status +
          " qwheader__level2bar-icon-container-" +
          this.state.device +
          " qwheader__level2bar-icon-container-" +
          this.state.device +
          "-" +
          (hazard === dangerId ? "full" : "compact")
        }>
        <div
          key={"logo-" + dangerId}
          className={
            "qwheader__level2bar-icon-box qwheader__level2bar-icon-box-" +
            (hazard === dangerId ? "full" : "compact") +
            " " +
            danger.status
          }>
          <img
            key={dangerId}
            src={require("./../../assets/product/rischi/" +
              (hazard === dangerId ? "full" : "compact") +
              "/" +
              danger.name.toLowerCase().replace("à", "a") +
              "-" +
              (hazard === dangerId ? "full" : "compact") +
              ".svg")}
            alt={danger.name}
            className={
              "qwheader__danger-logo qwheader__danger-logo-" +
              this.state.device +
              " qwheader__danger-logo-" +
              (hazard === dangerId ? "full" : "compact") +
              " " +
              danger.status
            }
          />
        </div>
        <div
          key={"text-" + dangerId}
          className={
            "qwheader__level2bar-icon-text qwheader__level2bar-icon-text-" +
            (hazard === dangerId ? "full" : "compact") +
            " " +
            danger.status
          }>
          {danger.name.toUpperCase()}
        </div>
      </div>
    );
  }

  // Build the array of Object for the progress bar
  buildBarData(): Array<Object> {
    let barData: Array<Object> = [];
    let currentHazard = "";
    this.props.questions.forEach((question: any) => {
      if (currentHazard === "") {
        barData.push({
          name: question.Hazard.Name,
          id: question.Hazard.Id,
        });
        currentHazard = question.Hazard.Name;
      }
      if (currentHazard !== question.Hazard.Name) {
        barData.push({
          name: question.Hazard.Name,
          id: question.Hazard.Id,
        });
        currentHazard = question.Hazard.Name;
      }
    });
    return barData;
  }

  // Toggle user menu
  toggleUserMenu(): void {
    if (this.props.sidebarStatus) {
      this.props.toggleSidebar();
    }
    if (this.state.userMenuStatus) {
      this.setState({ userMenuStatus: false });
      this.props.toggleMenuOverlayStatus(false);
    } else {
      this.setState({ userMenuStatus: true });
      this.props.toggleMenuOverlayStatus(true);
    }
  }

  // Toggle sidebar
  toggleSidebar(): void {
    this.setState({ userMenuStatus: false }, () => {
      this.props.toggleSidebar();
    });
  }

  // Redirect to recovery component
  redirectToRecovery() {
    this.setState({ redirectToRecovery: true });
  }

  // Render the redirect component
  renderRedirectToRecovery() {
    return this.state.redirectToRecovery === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[6] }}
      />
    ) : (
      ""
    );
  }

  // Get the index for the current hazard in the header bar
  getBarPosition(hazardId: number) {
    return this.state.barData.findIndex((item: any) => item.id === hazardId);
  }

  // Return the style for an hazard button
  getDangerStyle(hazard: string): any {
    return this.checkHazardDone(hazard) ? { cursor: "pointer" } : {};
  }

  // Switch to a different hazard
  goToHazard(e: React.MouseEvent) {
    if (this.checkHazardDone(e.currentTarget.id)) {
      this.props.switchToHazard(
        this.getHazardIdByName(e.currentTarget.id),
        e.currentTarget.id
      );
    }
  }

  // Return the hazard id by name
  getHazardIdByName(hazard: string): number {
    return this.state.barData.find(
      (hazardInfo: any) =>
        hazardInfo.name.toLowerCase() === hazard.toLowerCase()
    ).id;
  }

  // Check if an Hazard is completed
  checkHazardDone(hazard: string): boolean {
    return (
      this.props.hazardDone.find(
        (currentHazard: any) =>
          currentHazard.name.toLowerCase() === hazard.toLowerCase()
      ) !== undefined
    );
  }
}
