import React from "react";
import config from "../../../../config/config";
import { getDevice } from "../../../../lib/responsive-lib";
import MainBox from "../../../mainbox/MainBox";
import { Redirect } from "react-router-dom";
import "./Disclaimer.css";

interface DisclaimerProps {
  cookies?: any;
  disableMarker: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  setMapStatus: any;
}

interface DisclaimerState {
  device: String;
  closeDisclaimer: boolean;
}

export default class Disclaimer extends React.Component<
  DisclaimerProps,
  DisclaimerState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      closeDisclaimer: false,
    };

    // Method binding
    this.closeDisclaimerBox = this.closeDisclaimerBox.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.props.disableMarker();
    this.props.setInfoWindowVisibility(false);
    this.props.setMapZoom(config.maps.default.zoom);
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    this.setState({ device: getDevice() });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderBack()}
        <div className="disclaimer__container">
          <MainBox style={{ overflow: "hidden" }}>
            <div
              className={
                "disclaimer__close-container disclaimer__close-container-" +
                this.state.device
              }
              onClick={this.closeDisclaimerBox}
            >
              <img
                src={
                  require("./../../../../assets/system/icon-system-close-black.svg")
                    .default
                }
                alt="Close"
                className="disclaimer__close-icon"
              />
            </div>
            {this.getPrivacyTitle()}
            {this.getPrivacyText()}
          </MainBox>
        </div>
      </>
    );
  }

  getPrivacyTitle() {
    return (
      <>
        <div
          className={"disclaimer__title disclaimer__title-" + this.state.device}
        >
          Disclaimer
        </div>
      </>
    );
  }

  getPrivacyText() {
    return (
      <>
        <div className="disclaimer__msg-container">
          Il presente strumento di autovalutazione (“Tool”) ed il relativo piano
          di azione che viene generato come report finale (“Deliverable”) sono
          elaborati, nell’ambito del “Progetto Derris”, per scopi meramente
          informativi al fine di dare la possibilità alle aziende di piccole e
          medie dimensioni (i “Destinatari”) di contribuire ad accrescere la
          propria consapevolezza sulla loro esposizione ai rischi derivanti da
          eventi climatici estremi come, ad esempio, alluvioni, pioggia,
          grandine, fulmini, vento, frane, ondate di caldo o gelo (i “Rischi”),
          fornendo degli spunti per l’implementazione di soluzioni che riducano
          o prevengano i relativi danni.
          <br />
          <br />
          La compilazione del Tool ed il rilascio del relativo Deliverable non
          potranno essere intesi e/o interpretati come valutazione e/o
          assessment, raccomandazione e/o dichiarazione di ragionevolezza, né
          potranno essere utilizzati da chiunque, ivi inclusi, advisor, esperti
          o consulenti incaricati dai Destinatari come base per eventuali
          analisi ed hanno scopo meramente divulgativo né si sostituiscono a
          quanto richiesto ai Destinatari da disposizioni di legge e/o
          regolamento e/o per ordine di Autorità.
          <br />
          <br />
          I contenuti del Deliverable sono elaborati sulla base di informazioni
          e dati ad oggi disponibili, raccolti ed elaborati mediante
          compilazione del Tool da parte dei Destinatari stessi e potrebbero
          subire pertanto scostamenti - anche rilevanti – o non rispondere alle
          reali circostanze di fatto tenuto anche conto di rischi ed eventi
          meteo-climatici [condizioni economiche e fattori di mercato] e/o, in
          generale, di fattori esogeni non noti o non prevedibili.
          <br />
          <br />
          Il Tool e il Deliverable non hanno alcuna pretesa di esaustività in
          relazione a tutte le informazioni che potrebbero essere necessarie per
          effettuare una valutazione sul livello di esposizione ai Rischi, sul
          grado di vulnerabilità dei Destinatari e sui suggerimenti per limitare
          eventuali danni in caso di catastrofe. Le informazioni ivi contenute
          non sostituiscono le valutazioni e le analisi che devono essere
          condotte autonomamente dai Destinatari. La restituzione del
          Deliverable non comporta l’assunzione di alcun obbligo di fornire
          informazioni aggiuntive in relazione ai potenziali Rischi o di
          aggiornare e/o integrare il Deliverable o di correggere eventuali
          inesattezze nello stesso contenute che potrebbero divenire manifeste.
          <br />
          <br />
          Con la restituzione del Deliverable non viene inoltre rilasciata
          alcuna dichiarazione e/o garanzia, espressa o implicita, in relazione
          all’accuratezza, alla completezza, alla correttezza o all’equità delle
          informazioni e dei dati nonché delle opinioni, delle stime e delle
          proiezioni eventualmente contenute nel Deliverable stesso. Di
          conseguenza, non viene assunta alcuna responsabilità, diretta e/o
          indiretta, espressa o implicita, contrattuale, statutaria o di sorta
          in merito al Tool e/o al Deliverable o in relazione a qualsivoglia
          errore e/o omissione ivi incluso – a mero titolo esemplificativo e non
          esaustivo - con riferimento (i) al livello di pericolosità attribuito
          a seguito della geolocalizzazione dei Destinatari ed (ii) all’indice
          di rischio emerso dalla compilazione del Tool sulla base del livello
          di pericolosità attribuito a seguito della geolocalizzazione fornita
          dai Destinatari stessi.
          <br />
          <br />
          La compilazione del Tool e l’elaborazione del Deliverable non ha
          alcuna finalità pubblicitaria e/o promozionale e non costituisce
          pertanto un invito all’investimento, né potrà costituire un’offerta al
          pubblico o contrattuale o una promozione, né prevede l’assunzione di
          impegni di alcuna natura da parte dei Destinatari e/o [dei Partners
          del “Progetto Derris”] in relazione alla sottoscrizione di prodotti
          assicurativi per la prevenzione dei Rischi.
          <br />
          <br />
          I Destinatari dichiarano che la compilazione del Tool e la ricezione
          del Deliverable non comportano violazione delle / né contravvengono
          alle disposizioni di legge e/o regolamento e/o contrattuali
          applicabili nella giurisdizione di provenienza.
          <br />
          <br />
          La titolarità dei diritti di proprietà industriale e/o intellettuale
          afferenti al Tool ed al Deliverable è riservata. I Destinatari, così
          come eventuali soggetti terzi che abbiano accesso al sito e/o
          interagiscano con il Tool (i"Soggetti Terzi""), si impegnano ad
          utilizzare il Tool ed il Deliverable esclusivamente nell’ambito del e
          per le finalità connesse al “Progetto Derris”, a non depositare
          brevetti o registrare marchi o modelli o altrimenti esercitare altri
          titoli di privativa industriale connessi o relativi ad idee,
          invenzioni o anche indirettamente ricollegabili al Tool ed al
          Deliverable e/o agli eventuali ulteriori risultati ottenuti da o
          nell’ambito del “Progetto Derris”. Nel caso in cui simili titoli
          vengano depositati e/o registrati e/o altrimenti esercitati, i
          Destinatari e/o i Soggetti Terzi si impegnano a trasferirne
          gratuitamente la proprietà esclusiva ed ogni diritto di sfruttamento
          [ai beneficiari del “Progetto Derris”, essendo peraltro beninteso che
          graveranno sui Destinatari e/o sui Soggetti Terzi anche le eventuali
          spese per la procedura volta al conseguimento dei titoli di cui sopra.
          I Destinatari e/o i Soggetti Terzi si impegnano altresì ad astenersi
          dal copiare, duplicare, riprodurre o registrare, in qualsiasi forma e
          con qualsiasi mezzo, il Tool ed il Deliverable e/o i contenuti degli
          stessi. La menzione del Tool da parte dei Destinatari e/o dei Soggetti
          Terzi in ambiti diversi dal “Progetto Derris” dovrà sempre precisare
          che il Tool è stato elaborato nell’ambito del medesimo “Progetto
          Derris”.
          <br />
          <br />
        </div>
      </>
    );
  }

  // Close the privay box
  closeDisclaimerBox(): void {
    this.setState({ closeDisclaimer: true });
  }

  // Rendere privacy component
  renderBack(): Object {
    return this.state.closeDisclaimer === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[0] }} />
    ) : (
      ""
    );
  }
}
