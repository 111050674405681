import React from 'react';
import { getDevice } from './../../lib/responsive-lib';
import './MainBox.css';

interface MainBoxProps {
    style?: any;
}
interface MainBoxState {
    device: String;
}
export default class MainBox extends React.Component<MainBoxProps, MainBoxState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({ device: getDevice() });
    }

    // React render
    public render() {
        return <>
            <div className={"mainbox__container mainbox__container-" + this.state.device} style={this.props.style}>
                <div className="mainbox__children-container">
                    {this.props.children}
                </div>
            </div>
        </>;
    }
}