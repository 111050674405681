const baseurl = window.location.origin;
//const baseurl = "https://asdevwebapp.azurewebsites.net";
//const baseurl = "https://cram.derris.eu";

export default {
  main: {
    baseurl: window.location.origin,
  },
  analytics: {
    baseurl: window.location.origin,
    environment: "prod",
  },
  webservices: {
    auth: {
      local: {
        endpoint: baseurl + "/api/v1.0/account/login",
      },
      remote: {
        endpoint: baseurl + "/api/v1.0/account/externallogins",
      },
      remoteToken: {
        endpoint: baseurl + "/api/v1.0/Account/ObtainLocalAccessToken",
      },
    },
    passwordRecovery: {
      endpoint: baseurl + "/api/v1.0/account/forgotpassword",
    },
    changePassword: {
      endpoint: baseurl + "/api/v1.0/account/setpassword",
    },
    registration: {
      local: {
        endpoint: baseurl + "/api/v1.0/account/register",
      },
      remote: {
        endpoint: baseurl + "/api/v1.0/account/registerexternal",
      },
    },
    company: {
      endpoint: baseurl + "/api/v1.0/company",
    },
    plan: {
      endpoint: baseurl + "/api/v1.0/site",
    },
    proxy: {
      endpoint: baseurl + "/api/v1.0/proxy",
    },
    danger: {
      endpoint: baseurl + "/api/v1.0/site/perils",
    },
    places: {
      endpoint: baseurl + "/api/v1.0/site",
    },
  },
  maps: {
    apiKey: "AIzaSyCmv0Zhl0GPDV9Au60zZUiIQgu6xkttl74",
    default: {
      name: "Bologna, Piazza Maggiore",
      center: {
        lat: 44.493785,
        lng: 11.343094,
      },
      panMap: {
        x: 0,
        y: 0,
      },
      zoom: 12,
      options: {
        mapClickableIcons: false,
        mapFullscreenControl: false,
        mapKeyboardShortcuts: false,
        mapTypeControl: false,
        mapRotateControl: false,
        mapScaleControl: false,
        mapStreetViewControl: false,
        mapZoomControl: false,
      },
    },
  },
  marker: {
    default: {
      name: "Bologna, Piazza Maggiore",
      center: {
        lat: 44.493785,
        lng: 11.343094,
      },
      options: {
        visible: false,
        draggable: false,
      },
    },
  },
  infoWindow: {
    default: {
      text: "",
      visible: false,
      options: {},
    },
  },
  recaptcha: {
    sitekey: "6Lfy1rkUAAAAAAzVPwYTwqXY3Y1miF6-sYIrxOOR",
    secret: "6Lfy1rkUAAAAAJYiTZAQ2n5NIFjebsBKvyV3pAI1",
    enabled: false,
  },
  steps: [
    "welcome",
    "position",
    "registration",
    "start",
    "results",
    "login",
    "recovery",
    "resetpwd",
    "questionnaire",
    "privacy",
    "tips",
    "disclaimer",
  ],
  password: {
    minLength: 4,
  },
};
