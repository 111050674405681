import React from "react";
import { Link, Redirect } from "react-router-dom";
import MainBox from "./../../mainbox/MainBox";
import ProgressBar from "./../../progressbar/ProgressBar";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import { getDevice } from "./../../../lib/responsive-lib";
import config from "./../../../config/config";
import { setUtagView } from "./../../../lib/general-lib";
import "./Welcome.css";
import { HomeBanner } from "../../home-banner";

interface WelcomeProps {
  cookies?: any;
  setMapsCoordinates: any;
  center: any;
  setInfoWindowVisibility: any;
  setMapStatus: any;
}

interface WelcomeState {
  privacyCheck: Boolean;
  redirect: Boolean;
  device: String;
  login: Boolean;
  openPrivacy: boolean;
  openDisclaimer: boolean;
}

export default class Welcome extends React.Component<
  WelcomeProps,
  WelcomeState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      privacyCheck: false,
      redirect: false,
      device: "",
      login: false,
      openPrivacy: false,
      openDisclaimer: false,
    };

    // Method binding
    this.togglePrivayBox = this.togglePrivayBox.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
    this.renderLogin = this.renderLogin.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.openPrivacyText = this.openPrivacyText.bind(this);
    this.openDisclaimerText = this.openDisclaimerText.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    this.props.setMapsCoordinates(config.maps.default.center);
    this.props.setInfoWindowVisibility(false);
    this.setState({
      device: getDevice(),
      privacyCheck: this.props.cookies
        ? this.props.cookies.get("derris_privacy_check") === "true"
        : false,
    });
    // Analytics
    setUtagView(
      {
        page_url: config.analytics.baseurl + "/welcome",
        first_level: "welcome",
      },
      false
    );
    //this.togglePrivayBox();
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirect()}
        {this.renderLogin()}
        {this.renderPrivacy()}
        {this.renderDisclaimer()}
        <div className="welcome__container">
          <MainBox>
            <div className="welcome__text-row-header">
              <MBHeader
                device={this.state.device}
                loginEnabled={true}
                closeEnabled={false}
                cookies={this.props.cookies}
                parent={config.steps[0]}
              />
            </div>
            <div className="welcome__text-row-progressbar">
              <ProgressBar step={"00"} />
            </div>
            <div className="welcome__text-row-0 light">
              Benvenuto nello strumento di autovalutazione del rischio climatico
              (CRAM tool) del <span className="bold">progetto DERRIS</span>.
              Questo tool ha l'obiettivo di{" "}
              <span className="bold">aiutare le PMI italiane</span> a:
            </div>
            <div className="welcome__text-row-1 light">
              <ol>
                <li>
                  <span className="bold">Valutare i rischi</span> cui l’azienda
                  è esposta.
                </li>
                <li>
                  <span className="bold">Elaborare un Piano di Azione</span> con
                  i possibili <span className="bold">interventi</span> per{" "}
                  <span className="bold">prevenire e gestire</span> questi
                  rischi.
                </li>
              </ol>
            </div>
            <div className="welcome__text-row-2 light">
              Il tool analizza 7 fenomeni meteoclimatici: alluvione, pioggia,
              vento, grandine, fulmini, temperatura, frane.
            </div>
            <div className="welcome__text-row-3 light">
              <div className="welcome__text-row-3-a light">
                <a
                  href="http://www.derris.eu/benvenuto-nel-cram-tool-di-derris-2165/"
                  rel="noopener noreferrer"
                  target="_blank">
                  Per saperne di più
                </a>
              </div>
            </div>
            <div className="welcome__text-row-privacy light">
              <div className="welcome__text-privacy-box">
                {this.renderPrivacyBox()}
              </div>
              <div className="welcome__text-privacy-text">
                Dichiaro di aver preso visione dell'
                <span
                  onClick={this.openPrivacyText}
                  className="link">
                  informativa sulla privacy
                </span>{" "}
                e dei{" "}
                <span
                  onClick={this.openDisclaimerText}
                  className="link">
                  termini e condizioni del servizio
                </span>
                .
              </div>
            </div>
            <div
              className={
                "welcome__text-row-bottom welcome__text-row-bottom-" +
                this.state.device
              }>
              <div className="welcome__text-row-bottom-button-container">
                <div
                  className={
                    "welcome__text-row-bottom-button font-generic " +
                    (this.state.privacyCheck ? "enabled" : "disabled")
                  }
                  onClick={this.setRedirect}>
                  Avanti
                </div>
              </div>
              <div className="welcome__text-row-bottom-euro-logo">
                <img
                  src={require("./../../../assets/logos/euro.png")}
                  alt="Europa Logo"
                  className="welcome__text-row euro-logo"
                />
              </div>
            </div>
          </MainBox>
          <HomeBanner />
        </div>
      </>
    );
  }

  // Render privacy icon
  renderPrivacyBox(): any {
    return this.state.privacyCheck ? (
      <img
        onClick={this.togglePrivayBox}
        src={require("./../../../assets/system/square_fill.png")}
        alt="Europa Logo"
        className="welcome__text-row euro-logo"
      />
    ) : (
      <img
        onClick={this.togglePrivayBox}
        src={require("./../../../assets/system/square_empty.png")}
        alt="Europa Logo"
        className="welcome__text-row euro-logo"
      />
    );
  }

  // Toggle privacy checkbox
  togglePrivayBox(): void {
    const { cookies } = this.props;
    if (this.state.privacyCheck) {
      cookies.set("derris_privacy_check", false, {});
      this.setState({ privacyCheck: false });
    } else {
      cookies.set("derris_privacy_check", true, {});
      this.setState({ privacyCheck: true });
    }
  }

  // Redirect to next step
  setRedirect(e: React.MouseEvent): void {
    if (this.state.privacyCheck) {
      this.setState({ redirect: true });
    }
  }

  // Redirect to login
  handleLoginClick(e: React.MouseEvent): void {
    if (this.state.privacyCheck) {
      const { cookies } = this.props;
      cookies.set("derris_origin_page", config.steps[0], { path: "/" });
      this.setState({ login: true });
    }
  }

  // Rendere redirect component
  renderRedirect(): Object {
    return this.state.redirect === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[1] }}
      />
    ) : (
      ""
    );
  }

  // Rendere redirect component
  renderLogin(): Object {
    return this.state.login === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[5] }}
      />
    ) : (
      ""
    );
  }

  openPrivacyText() {
    this.setState({
      openPrivacy: true,
    });
  }

  openDisclaimerText() {
    this.setState({
      openDisclaimer: true,
    });
  }

  // Rendere privacy component
  renderPrivacy(): Object {
    return this.state.openPrivacy === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[9] }}
      />
    ) : (
      ""
    );
  }

  // Rendere privacy component
  renderDisclaimer(): Object {
    return this.state.openDisclaimer === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[11] }}
      />
    ) : (
      ""
    );
  }
}
