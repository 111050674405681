import config from './../config/config';
import CryptoJS from 'crypto-js';

/**
 * getCookie
 * 
 * (Get cookie by name)
 * 
 * @param {String} cookieName Name of the cookie to get
 * @returns {String} Cookie content on success, false on failure
 */
export const getCookie = (cookieName: String): any => {
    const name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

/**
 * setCookie
 * 
 * (set cookie)
 * 
 * @param {String} cookieName Name of the cookie to set
 * @param {String} cookieValue Value of the cookie
 * @param {String} exdays Expiration date of the cookie
 */
export const setCookie = (cookieName: String, cookieValue: String, exdays: number): void => {
    let expires = "";
    if (exdays === 0) {
        expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    } else {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }
    document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
}

/**
 * getCookie
 * 
 * (Get cookie by name)
 * 
 * @param {String} cookieName Name of the cookie to get
 * @returns {String} Cookie content on success, false on failure
 */
export const logout = (): any => {
    setCookie("derris_token", "", 0);
    setCookie("derris_user", "", 0);
    setCookie("derris_privacy_check", "", 0);
    setCookie("derris_origin_page", "", 0);
    setCookie("derris_current_province", "", 0);
    setCookie("derris_current_danger", "", 0);
    setCookie("derris_current_coordinates", "", 0);
    setCookie("derris_current_addrress", "", 0);
    returnToHome();
}

/**
 * returnToHome
 * 
 * (Return to app home)
 * 
 */
export const returnToHome = (): void => {
    window.location.assign("/" + config.steps[0] + "/");
}

/**
 * checkPrivacy
 * 
 * (check privacy flag in true)
 * 
 * @return {Boolean} True if privacy is checked, false otherwise
 */
export const checkPrivacy = (): Boolean => {
    return getCookie("derris_user") || getCookie("derris_privacy_check");
}

/**
 * checkLogin
 * 
 * (check login)
 * 
 * @return {Boolean} True if user is logged in, false otherwise
 */
export const checkLogin = (): Boolean => {
    return getCookie("derris_user");
}

/**
 * setUtagView
 * 
 * (set Utag View object)
 * @param {String} data Data to set on utag view
 * @return {Boolean} True on success, false otherwise
 */
export const setUtagView = (data: object, localize: boolean): void => {
    let intervalCounter = 0;
    var setUtagViewInterval = setInterval(() => {
        intervalCounter++;
        if (intervalCounter >= 40) {
            clearInterval(setUtagViewInterval);
        }
        // @ts-ignore
        if (window !== undefined && window.utag !== undefined) {
            clearInterval(setUtagViewInterval);
            // @ts-ignore
            window.utag.view({ ...getUtagUserIdPreload(), ...getUtagPreload("page"), ...getUtagLocationPreload(localize), ...data });
        }
    }, 100, data, localize);
}

/**
 * setUtagLink
 * 
 * (set Utag Link object)
 * @param {String} data Data to set on utag view
 * @return {Boolean} True on success, false otherwise
 */

export const setUtagLink = (data: object, localize: boolean): void => {
    let intervalCounter = 0;
    var setUtagLinkInterval = setInterval(() => {
        intervalCounter++;
        if (intervalCounter >= 40) {
            clearInterval(setUtagLinkInterval);
        }
        // @ts-ignore
        if (window !== undefined && window.utag !== undefined) {
            clearInterval(setUtagLinkInterval);
            // @ts-ignore
            window.utag.link({ ...getUtagUserIdPreload(), ...getUtagPreload("action"), ...getUtagLocationPreload(localize), ...data });
        }
    }, 100, data, localize);
}

/**
 * isNumber
 * 
 * (Check if string contains number)
 * @param {String} string String to check
 * @return {Boolean} True on success, false otherwise
 */
export const isNumber = (string: string): boolean => {
    return /\d/.test(string);
}

/**
 * convertHazardToAnalyticsStep
 * 
 * (Convert hazards names in analytics step value)
 * @param {String} hazardName Name of the hazard
 * @return {String} Number of step on success, empty string  otherwise
 */
export const convertHazardToAnalyticsStep = (hazardName: string): string => {
    switch (hazardName.toLowerCase()) {
        case 'alluvione':
            return "1";
        case 'grandine':
            return "2";
        case 'temperatura':
            return "3";
        case 'pioggia':
            return "4";
        case 'vento':
            return "5";
        case 'fulminazione':
            return "6";
        case 'frana':
        case 'frane':
            return "7";
        case 'sostenibilità':
        case 'sostenibilita':
        case 'sostenibilità\'':
            return "8";
        case 'resilienza':
            return "9";
        default:
            return "";
    }
}

/**
* getHazardFirstQuestionByHazardName
* 
* (Return the first question with an hazard searching by string)
* @param {String} questions Questions array
* @param {String} hazard Hazard name
* @return {Object} Object with question details
*/
export const getHazardFirstQuestionByHazardName = (questions: any, hazard: string): any => {
    return questions.find((question: any) => question.Hazard.Name.toLowerCase() === hazard.toLowerCase());
}

/**
* getHazardFirstQuestionIndex
* 
* (Return the first question with an hazard searching by string)
* @param {String} questions Questions array
* @param {String} hazard  Hazard name
* @return {Number} Index oft he the first question with an hazard
*/
export const getHazardFirstQuestionIndex = (questions: any, hazard: string): number => {
    return questions.findIndex((question: any) => question.Hazard.Name.toLowerCase() === hazard.toLowerCase());
}

/**
* cleanString
* 
* (Trim a string)
* @param {String} string String to trim
* @return {String} String trimmed
*/
export const trimString = (string: string): string => {
    // eslint-disable-next-line
    return string.replace(/^[\-\s]+|[\-\s]+$/gm, '');
}

/**
* validateEmail
* 
* (Check if the email address is valid)
* @param {String} string Email to check
* @return {Boolean} True if string is a correct email address false otherwise
*/
export const validateEmail = (email: string) => {
    // eslint-disable-next-line 
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(String(email).toLowerCase());
}

/**
* capitalizeString
* 
* (Capitalize a string)
*
* @param {String} string String to capitalize
* @return {Boolean} String capitalized
*/
export const capitalizeString = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

// Get default value for Analytics
const getUtagPreload = (virtual: string): object => {
    return {
        page_environment: config.analytics.environment,
        virtual: virtual
    }
}

// Get user id value for Analytics
const getUtagUserIdPreload = (): object => {
    return getCookie("derris_user") ? { user_id: CryptoJS.SHA256(getCookie("derris_user")).toString() } : {};
}

// get location value for Analytics
const getUtagLocationPreload = (localize: boolean): object => {
    if (localize && getCookie("derris_current_province")) {
        return {
            location: getCookie("derris_current_province")
        };
    } else return {};
}