import React from 'react';
import { getDevice } from './../../lib/responsive-lib';
import './FlatBox.css';

interface FlatBoxProps {
    children: any;
    layout: string;
}

interface FlatBoxState {
    device: String;
}
export default class FlatBox extends React.Component<FlatBoxProps, FlatBoxState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({ device: getDevice() });
    }

    // React render
    public render() {
        return <>
            <div className={"flatbox__container flatbox__container-" + this.state.device + (this.state.device === 'mobile' ? " flatbox__container-" + this.props.layout : '')}>
                {this.props.children}
            </div>
        </>;
    }
}