import config from './../../config/config';
import { getAuthResponse } from './../proxy/api'

/**
 * saveCompanyInfo
 * 
 * (Call webservices to save company data)
 * 
 * @param {String} name Name of company
 * @param {String} vatnumber Vat number
 * @param {String} director Director of company
 * @param {String} operationaladdress Operational addresss
 * @param {String} registeredaddress Legal address
 * @param {String} token Token for bearer auth
 * @returns Promise calling company service
 */
export const saveCompanyInfo = (company: string, vatnumber: string, manager: string, technical: string, operationaladdress: any, registeredaddress: string, token: string): Promise<any> => {
    return getAuthResponse(
        "POST",
        config.webservices.company.endpoint,
        JSON.stringify({
            name: company,
            vatnumber: vatnumber,
            director: manager,
            operationaladdress: operationaladdress,
            registeredaddress: registeredaddress,
            technicalcontact: technical
        }),
        token
    );
}

/**
 * saveCompanyInfo
 * 
 * (Call webservices to save company data)
 * 
 * @param {String} name Name of company
 * @param {String} vatnumber Vat number
 * @param {String} director Director of company
 * @param {String} operationaladdress Operational addresss
 * @param {String} registeredaddress Legal address
 * @param {String} token Token for bearer auth
 * @returns Promise calling company service
 */
export const getCompanyInfo = (token: string): Promise<any> => {
    return getAuthResponse(
        "GET",
        config.webservices.company.endpoint,
        null,
        token
    );
}

/**
 * editCompanyInfo
 * 
 * (Call webservices to edit company data)
 * 
 * @param {String} name Name of company
 * @param {String} vatnumber Vat number
 * @param {String} director Director of company
 * @param {String} operationaladdress Operational addresss
 * @param {String} registeredaddress Legal address
 * @param {String} token Token for bearer auth
 * @returns Promise calling company service
 */
export const editCompanyInfo = (company: string, vatnumber: string, manager: string, technical: string, operationaladdress: any, registeredaddress: string, token: string): Promise<any> => {
    return getAuthResponse(
        "PUT",
        config.webservices.company.endpoint,
        JSON.stringify({
            name: company,
            vatnumber: vatnumber,
            director: manager,
            operationaladdress: operationaladdress,
            registeredaddress: registeredaddress,
            technicalcontact: technical
        }),
        token
    );
}

/**
 * getFinalPlan
 * 
 * (Call webservices to get plan by email or pdf)
 * 
 * @param {String} siteId Id of the current place
 * @param {String} additionalAddresses Array with additional email address to send do
 * @param {String} token Token for bearer auth
 * @returns Promise calling plan service
 */
export const getFinalPlan = (siteId: string, additionalAddresses: Array<string>, token: string): Promise<any> => {
    return getAuthResponse(
        "GET",
        config.webservices.plan.endpoint + "/" + siteId + "/planbymail" + getFinalPlanParameters(additionalAddresses),
        null,
        token
    );
}

// Build the parameters string to get final plan
const getFinalPlanParameters = (additionalAddresses: Array<string>) => {
    return additionalAddresses.length > 0 ? "?" + additionalAddresses.map((currentAddress: string) => "additionalAddresses=" + currentAddress).join("&") : "";
}