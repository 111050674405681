import config from './../../config/config';
import { getResponse } from './../proxy/api'

/**
 * getStandardRegistration
 * 
 * (Call webservices to perform a standard user registration)
 * 
 * @param {String} firstname Firstname of the user
 * @param {String} lastname Lastname of the user
 * @param {String} email Email of the user
 * @param {String} password Password of the user
 * @param {String} repassword Confirm of the Password of the user
 * @returns Promise calling auth service
 */
export const getStandardRegistration = (firstname: String, lastname: String, email: String, password: String, repassword: String): Promise<any> => {
    return getResponse(
        "POST",
        config.webservices.registration.local.endpoint,
        JSON.stringify({
            firstName: firstname,
            surname: lastname,
            email: email,
            password: password,
            confirmPassword: repassword
        })
    );
}

/**
 * getStandardRegistration
 * 
 * (Call webservices to perform a standard user registration)
 * 
 * @param {String} firstname Firstname of the user
 * @param {String} lastname Lastname of the user
 * @param {String} email Email of the user
 * @param {String} password Password of the user
 * @param {String} repassword Confirm of the Password of the user
 * @returns Promise calling auth service
 */
export const getExternalRegistration = (firstname: String, lastname: String, username: String, provider: String, externalAccessToken: String): Promise<any> => {
    return getResponse(
        "POST",
        config.webservices.registration.remote.endpoint,
        JSON.stringify({
            firstName: firstname,
            surname: lastname,
            username: username,
            provider: provider,
            externalAccessToken: externalAccessToken
        })
    );
}