import React from 'react';
import { getDevice } from '../../lib/responsive-lib';
import { checkLogin, isNumber } from './../../lib/general-lib';
import { Redirect } from 'react-router-dom';
import { getQuestionnaire, checkQuestionnaire } from './../../api/questionnaire-api/api'
import { saveSuggestions, getSuggestions } from './../../api/suggestions-api/api';
import {
    setUtagView,
    setUtagLink,
    convertHazardToAnalyticsStep,
    getHazardFirstQuestionByHazardName,
    getHazardFirstQuestionIndex,
    trimString
} from './../../lib/general-lib';
import Sidebar from './../sidebar/Sidebar';
import QwHeader from './../qwHeader/QwHeader';
import ErrorPopup from './../errorpopup/ErrorPopup';
import Loader from './../loader/Loader';
import config from './../../config/config';
import HazardsTips from './hazardsTips/HazardsTips';
import ResiliencyIndex from './resiliencyIndex/ResiliencyIndex';
import ResiliencyPrint from './resiliencyPrint/ResiliencyPrint';
import './TipsView.css';

interface TipsViewProps {
    cookies?: any;
    setMapStatus: any;
    location: any;
    history?: any;
}

interface TipsViewState {
    device: String;
    redirectToHome: boolean;
    sidebarStatus: boolean;
    partialResultsVisible: boolean;
    error: boolean;
    errorTitle: string;
    errorText: string;
    msg: boolean;
    msgTitle: string;
    msgText: string;
    loader: boolean;
    questions: Array<any>;
    questionsInitial: Array<any>;
    currentHazard: number;
    lastHazard: number;
    questionId: any;
    currentQuestion: number;
    currentQuestionId: any;
    questionnaireLoaded: boolean;
    introChecked: boolean;
    placeId: string;
    results: any;
    hazardDone: Array<any>;
    currentProvince: string;
    placeInfo: any;
    sidebarLocked: boolean;
    suggestionsQueue: any;
    suggestionType: string;
    resiliencyIndexStatus: boolean;
    resiliencyIndexPrintStatus: boolean;
    headerTitle: string;
    menuOverlayStatus: boolean;
    numOfHazard: number;
}

export default class TipsView extends React.Component<TipsViewProps, TipsViewState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: '',
            redirectToHome: false,
            sidebarStatus: false,
            partialResultsVisible: false,
            error: false,
            errorTitle: '',
            errorText: '',
            loader: false,
            questions: [],
            questionsInitial: [],
            currentHazard: 0,
            lastHazard: 0,
            currentQuestion: 0,
            currentQuestionId: undefined,
            placeId: '',
            questionId: undefined,
            questionnaireLoaded: false,
            introChecked: false,
            results: {},
            hazardDone: [],
            currentProvince: '',
            placeInfo: {},
            sidebarLocked: true,
            suggestionsQueue: [],
            suggestionType: "OTHER",
            resiliencyIndexStatus: false,
            resiliencyIndexPrintStatus: false,
            headerTitle: 'SUGGERIMENTI',
            msg: false,
            msgTitle: '',
            msgText: '',
            menuOverlayStatus: false,
            numOfHazard: 0
        }

        // Method binding
        this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
        this.switchToHazard = this.switchToHazard.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleChoice = this.handleChoice.bind(this);
        this.changeSuggestionType = this.changeSuggestionType.bind(this);
        this.reloadSuggestions = this.reloadSuggestions.bind(this);
        this.toggleResiliencyView = this.toggleResiliencyView.bind(this);
        this.toggleResiliencyPrintView = this.toggleResiliencyPrintView.bind(this);
        this.toggleMenuOverlayStatus = this.toggleMenuOverlayStatus.bind(this);
        this.toggleMenuOverlay = this.toggleMenuOverlay.bind(this);
        this.getTipOrigin = this.getTipOrigin.bind(this);

        // Default values
        this.tipsChoice[1] = "Non applicabile / Non so";
        this.tipsChoice[2] = "Già in essere";
        this.tipsChoice[3] = "Metti nel piano";
    }

    // Object properties
    tipsChoice: Array<string> = [];

    // React componentDidMount
    componentDidMount() {
        this.props.setMapStatus(false);
        if (!checkLogin()) {
            this.setState({ redirectToHome: true });
        } else {
            this.setState({ device: getDevice() }, () => {
                this.parseUrl();
                if (this.props.location !== undefined && this.props.location.state !== undefined) {
                    if (this.props.location.state.tips !== undefined) {
                        this.setState({ suggestionType: this.props.location.state.tips });
                    }
                    if (this.props.location.state.resiliencyIndexStatus !== undefined && this.props.location.state.resiliencyIndexStatus === true) {
                        this.toggleResiliencyView(true);
                    }
                    if (this.props.location.state.resiliencyPrintStatus !== undefined && this.props.location.state.resiliencyPrintStatus === true) {
                        this.toggleResiliencyPrintView(true);
                    }
                }
            });
        }
    }

    // React componentDidUpdate
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (prevState.resiliencyIndexPrintStatus !== this.state.resiliencyIndexPrintStatus || prevState.resiliencyIndexStatus !== this.state.resiliencyIndexStatus) {
            this.setState({ headerTitle: this.getHeaderTitle() })
        }
    }

    // React render
    public render() {
        return <>
            {this.renderRedirectToHome()}
            <div className={"tipsview__container-sidebar-overlay tipsview__container-sidebar-overlay-" + (this.state.sidebarStatus && !this.state.sidebarLocked ? "enabled" : "disabled")} onClick={this.toggleSidebar}></div>
            <div className={"tipsview__container tipsview__container-" + this.state.device}>
                <Sidebar
                    sidebarStatus={this.state.sidebarStatus}
                    placeInfo={this.state.placeInfo}
                    currentView={this.getCurrentViewName()}
                    results={this.state.results}
                    placeId={this.state.placeId}
                    locked={this.state.sidebarLocked}
                    suggestionType={this.state.suggestionType}
                    changeSuggestionType={this.changeSuggestionType}
                    toggleSidebar={this.toggleSidebar}
                    toggleResiliencyView={this.toggleResiliencyView}
                    toggleResiliencyPrintView={this.toggleResiliencyPrintView}
                />
                <QwHeader
                    currentHazard={this.state.currentHazard}
                    lastHazard={this.state.lastHazard}
                    questions={this.state.questions}
                    partialResultsVisible={this.state.partialResultsVisible}
                    hazardDone={this.state.hazardDone}
                    switchToHazard={this.switchToHazard}
                    toggleSidebar={this.toggleSidebar}
                    title={this.state.headerTitle}
                    hazardBarStatus={!(this.state.resiliencyIndexStatus || this.state.resiliencyIndexPrintStatus)}
                    cookies={this.props.cookies}
                    sidebarStatus={this.state.sidebarStatus}
                    toggleMenuOverlayStatus={this.toggleMenuOverlayStatus}
                    menuOverlayStatus={this.state.menuOverlayStatus}
                />
                {this.state.resiliencyIndexStatus ? <>
                    <ResiliencyIndex
                        cookies={this.props.cookies}
                        placeId={this.state.placeId}
                    />
                </> : <></>}
                {this.state.resiliencyIndexPrintStatus ? <>
                    <ResiliencyPrint
                        cookies={this.props.cookies}
                        placeInfo={this.state.placeInfo}
                        changeSuggestionType={this.changeSuggestionType}
                        results={this.state.results}
                    />
                </> : <></>}
                {!this.state.resiliencyIndexPrintStatus && !this.state.resiliencyIndexStatus ? <>
                    <HazardsTips
                        results={this.state.results}
                        currentHazard={this.state.currentHazard}
                        handleChoice={this.handleChoice}
                        suggestionType={this.state.suggestionType}
                        placeId={this.state.placeId}
                        cookies={this.props.cookies}
                        reloadSuggestions={this.reloadSuggestions}
                        switchToHazard={this.switchToHazard}
                    />
                </> : <></>}
            </div>
            {this.renderLoader()}
            {this.renderError()}
        </>
    }

    // Return the name of the current view
    getCurrentViewName(): string {
        switch (true) {
            case (this.state.resiliencyIndexStatus):
                return "resiliencyIndex";
            case (this.state.resiliencyIndexPrintStatus):
                return "resiliencyIndexPrint";
            default:
                return "TipsView"
        }
    }

    // Parse the current url
    parseUrl() {
        const urlPieces = window.location.pathname.split("/").filter(item => item !== '');
        if (
            urlPieces[0] === config.steps[10] &&
            urlPieces[1] !== undefined &&
            isNumber(urlPieces[1])
        ) {
            switch (urlPieces[2] === undefined) {
                case false:
                    this.setState({
                        device: getDevice(),
                        loader: true,
                        placeId: urlPieces[1]
                    }, () => {
                        this.getQuestionnarie(urlPieces[1], urlPieces[2]);
                    });
                    break;
                case true:
                default:
                    this.setState({
                        device: getDevice(),
                        loader: true,
                        placeId: urlPieces[1]
                    }, () => {
                        this.getQuestionnarie(urlPieces[1], null);
                    });
                    break;
            }
        } else {
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare i suggerimenti"
            });
        }
    }

    // Toggle resiliency view
    toggleResiliencyView(status?: boolean) {
        if (status !== undefined) {
            this.props.history.push("/tips/" + this.state.placeId + "/resiliency");
            this.setState({
                resiliencyIndexStatus: status,
                resiliencyIndexPrintStatus: !status

            });
        } else {
            this.setState({
                resiliencyIndexStatus: false,
                resiliencyIndexPrintStatus: false
            });
        }
    }

    // Toggle resiliency print view
    toggleResiliencyPrintView(status?: boolean) {
        if (status !== undefined) {
            this.props.history.push("/tips/" + this.state.placeId + "/plan");
            this.setState({
                resiliencyIndexPrintStatus: status,
                resiliencyIndexStatus: !status
            });
        } else {
            this.setState({
                resiliencyIndexPrintStatus: false,
                resiliencyIndexStatus: false
            });
        }
    }

    // Return a string with the heade title
    getHeaderTitle(): string {
        switch (true) {
            case (this.state.resiliencyIndexPrintStatus !== undefined && this.state.resiliencyIndexPrintStatus):
                return "STAMPA PIANO"
            case (this.state.resiliencyIndexStatus !== undefined && this.state.resiliencyIndexStatus):
                return "INDICE DI RESILIENZA";
            case (this.state.resiliencyIndexPrintStatus !== undefined && this.state.resiliencyIndexPrintStatus === false && this.state.resiliencyIndexStatus !== undefined && this.state.resiliencyIndexStatus === false):
                return "SUGGERIMENTI";
            default:
                return ""
        }
    }

    // Start a new questionnaire
    getQuestionnarie(placeId: string, hazard: string | null) {
        const { cookies } = this.props;
        checkQuestionnaire(placeId, cookies.get('derris_token')).then(result => {
            if (result.status === 200) {
                this.localGetQuestionnaire(placeId, cookies.get('derris_token'), hazard);
            } else if (result.status === 404 || result.status === 400 || result.status === 401) {
                result.json().then((res: any) => {
                    console.error("Error while init questionnaire. " + res.Description);
                    this.setState({
                        error: true,
                        errorTitle: "Errore",
                        errorText: "Impossibile inizializzare i suggerimenti. " + trimString(res.Description)
                    });
                });
            }
        }).catch(error => {
            console.error("Error while init questionnaire. " + error);
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare i suggerimenti. " + trimString(error.toString())
            });
        });
    }

    // Handle click on tips radio buttons (all the response will be processed by a queue)
    handleChoice(hazardId: string, suggestionId: string, choice: string, choiceText: string, suggestionText: string): void {
        this.setState((prevState: any) => ({
            suggestionsQueue: [...prevState.suggestionsQueue, {
                "Id": suggestionId,
                "State": {
                    "Id": Number(choice),
                    "Name": this.tipsChoice[Number(choice)]
                }
            }]
        }), () => {
            let newResults: any = { ...this.state.results };
            switch (choice) {
                case "1":
                    newResults.NPlannedSuggestions = this.state.results.NPlannedSuggestions;
                    newResults.NDoneSuggestions = this.state.results.NDoneSuggestions;
                    newResults.NNASuggestions = this.state.results.NNASuggestions + 1;
                    break;
                case "2":
                    newResults.NPlannedSuggestions = this.state.results.NPlannedSuggestions;
                    newResults.NDoneSuggestions = this.state.results.NDoneSuggestions + 1;
                    newResults.NNASuggestions = this.state.results.NNASuggestions;
                    break;
                case "3":
                    newResults.NPlannedSuggestions = this.state.results.NPlannedSuggestions + 1;
                    newResults.NDoneSuggestions = this.state.results.NDoneSuggestions;
                    newResults.NNASuggestions = this.state.results.NNASuggestions;
                    break;
                default:
                    break;
            }
            switch (this.getTipOrigin(hazardId, suggestionId)) {
                case -99:
                    newResults.NTotUnspecifiedSuggestions = this.state.results.NTotUnspecifiedSuggestions - 1;
                    break;
                case 1:
                    newResults.NNASuggestions = this.state.results.NNASuggestions - 1;
                    break;
                case 2:
                    newResults.NDoneSuggestions = this.state.results.NDoneSuggestions - 1;
                    break;
                case 3:
                    newResults.NPlannedSuggestions = this.state.results.NPlannedSuggestions - 1;
                    break;
            }
            const suggestionsIndex = this.getSuggestionsIndexByHazardId(newResults, Number(hazardId));
            const suggestionIndex = this.getSuggestionIndexBySuggestionId(newResults.SuggestionsByPeril[suggestionsIndex].Suggestions, Number(suggestionId));
            newResults.SuggestionsByPeril[suggestionsIndex].Suggestions[suggestionIndex].State = {
                Id: Number(choice),
                Name: this.tipsChoice[Number(choice)]
            }
            this.setState({ results: newResults }, () => {
                setUtagLink({
                    page_url: config.analytics.baseurl + "/tips/" + this.state.placeId + "/" + this.getHazardNameByHazardId(this.state.results, this.state.currentHazard).toLowerCase(),
                    first_level: "tips",
                    second_level: this.getHazardNameByHazardId(this.state.results, this.state.currentHazard).toLowerCase(),
                    event_name: "card_interaction",
                    ga_eventCategory: "tips",
                    ga_eventaction: this.getHazardNameByHazardId(this.state.results, this.state.currentHazard).toLowerCase() + " - " + suggestionText,
                    ga_eventLabel: choiceText
                }, true);
                if (this.areAllSuggestionChecked()) {
                    setUtagLink({
                        page_url: config.analytics.baseurl + "/tips/" + this.state.placeId + "/" + this.getHazardNameByHazardId(this.state.results, this.state.currentHazard).toLowerCase(),
                        first_level: "tips",
                        second_level: this.getHazardNameByHazardId(this.state.results, this.state.currentHazard).toLowerCase(),
                        event_name: "tips-completed",
                        ga_eventCategory: "tips",
                        ga_eventaction: "Completato",
                    }, true);
                }
                this.saveSuggestions(0);
            });
        });
    }

    // Get current rip origin
    getTipOrigin(hazardId: string, suggestionId: string): any {
        const origin = this.state.results.SuggestionsByPeril.find((currentHazard: any) =>
            currentHazard.Id === hazardId).Suggestions.find((currentSuggestion: any) =>
                currentSuggestion.Id === suggestionId).State;
        if (origin !== null) {
            return origin.Id
        } else {
            return -99
        }
    }

    // Process recursively the queue of the responses
    saveSuggestions(index: number): void {
        const { cookies } = this.props;
        let localIndex = index;
        if (this.state.suggestionsQueue[localIndex] !== undefined) {
            saveSuggestions(this.state.placeInfo.Id, cookies.get('derris_token'), [this.state.suggestionsQueue[localIndex]]).then(result => {
                if (result.status === 200) {
                    const tmpSuggestionsQueue = [...this.state.suggestionsQueue];
                    tmpSuggestionsQueue.splice(localIndex, 1);
                    this.setState({ suggestionsQueue: tmpSuggestionsQueue }, () => {
                        localIndex++;
                        if (this.state.suggestionsQueue[localIndex] !== undefined) {
                            this.saveSuggestions(localIndex);
                        }
                    });
                } else {
                    console.error("Error while saving suggestions. Status: " + result.status);
                    this.setState({ suggestionsQueue: [] }, () => {
                        this.handleErrorSavingSuggestions();
                    });
                }
            }).catch(error => {
                console.error("Error while saving suggestions. " + error);
                this.setState({ suggestionsQueue: [] }, () => {
                    this.handleErrorSavingSuggestions();
                });
            });
        }
    }

    areAllSuggestionChecked(): boolean {
        return this.state.hazardDone.map((currentHazard: any, index: number) =>
            this.state.results.SuggestionsByPeril[this.getSuggestionsIndexByHazardId(this.state.results, currentHazard.id)].Suggestions.map((currentSuggestion: any) =>
                currentSuggestion.State === null ? currentSuggestion : null
            ).filter((item: any) => item !== null).length > 0 ? index : null
        ).filter((item: any) => item !== null).length === 0;
    }

    // Manage error while saving suggestions
    handleErrorSavingSuggestions(): void {
        this.setState({
            error: true,
            errorTitle: "Avviso",
            errorText: "Si sta verificando un problema durante il salvataggio delle preferenze. Si prega di verificare la connessione di rete e provare a effettuare di nuovo il login."
        });
    }

    reloadSuggestions(): void {
        const { cookies } = this.props;
        getSuggestions(this.state.placeInfo.Id, cookies.get('derris_token')).then(result => {
            if (result.status === 200) {
                result.json().then((res: any) => {
                    this.setState({ results: JSON.parse(res) })
                });
            } else {
                console.error("Error while getting suggestion. Status: " + result.status);
            }
        }).catch(error => {
            console.error("Error while getting suggestion. " + error);
        });
    }

    changeSuggestionType(viewSelected: string) {
        if (viewSelected !== undefined && viewSelected !== '') {
            this.setState({
                suggestionType: viewSelected,
                resiliencyIndexStatus: false,
                resiliencyIndexPrintStatus: false
            });
        }
    }

    // Switch the application to an Hazard (Experimental)
    switchToHazard(hazardId: number, hazardName: string) {
        this.setState({
            currentHazard: hazardId,
            lastHazard: -99,
            questionnaireLoaded: true,
            partialResultsVisible: false,
            introChecked: true
        }, () => {
            this.reloadSuggestions();
            this.props.history.push("/tips/" + this.state.placeId + "/" + hazardName.toLowerCase());
            setUtagView({
                page_url: config.analytics.baseurl + "/tips/" + this.state.placeId + "/" + hazardName.toLowerCase(),
                first_level: "tips",
                second_level: hazardName.toLowerCase(),
                step: convertHazardToAnalyticsStep(hazardName.toLowerCase())
            }, true);
        });
    }

    // Toggle sidebar status
    toggleSidebar(): void {
        if (this.state.sidebarStatus) {
            this.setState({ sidebarStatus: false });
        } else {
            this.setState({
                sidebarStatus: true,
                menuOverlayStatus: false
            });
        }
    }

    // Get a new questionnaire
    localGetQuestionnaire(placeId: string, token: string, hazard: string | null): void {
        getQuestionnaire(placeId, token, false).then(result => {
            if (result.status === 200) {
                result.json().then((res: any) => {
                    const { cookies } = this.props;
                    cookies.set('derris_current_province', JSON.parse(res).RelatedTo.Province, { path: '/' });
                    this.setState({
                        results: JSON.parse(res).Results !== undefined ? JSON.parse(res).Results : {},
                        hazardDone: this.getHazardDone(JSON.parse(res)),
                        numOfHazard: this.getNumOfHazard(JSON.parse(res)),
                        currentProvince: JSON.parse(res).RelatedTo.Province,
                        placeInfo: JSON.parse(res).RelatedTo
                    }, () => {
                        if (this.state.hazardDone.length === 0) {
                            this.setState({
                                error: true,
                                errorTitle: "Avviso",
                                errorText: "Non ci sono ancora suggerimenti per questa sede"
                            });
                        } else {
                            this.setState({
                                questions: this.parseQuestionnaire(JSON.parse(res)),
                                questionsInitial: this.parseQuestionnaire(JSON.parse(res))
                            }, () => {
                                this.initQuestionnaire(hazard).then((result: any) => {
                                    this.setState({
                                        loader: false,
                                        sidebarLocked: false
                                    });
                                });
                            });
                        }
                    })
                });
            } else if (result.status === 400) {
                result.json().then((res: any) => {
                    console.error("Error while init questionnaire. " + res.Description);
                    this.setState({
                        error: true,
                        errorTitle: "Errore",
                        errorText: "Impossibile inizializzare il questionario. " + trimString(res.Description)
                    });
                });
            }
        }).catch(error => {
            console.error("Error while init questionnaire. " + error);
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare il questionario. " + trimString(error.toString())
            });
        });
    }

    // Set the questionnaire to be looped better
    parseQuestionnaire(questions: any): Array<any> {
        let localQuestions: Array<any> = [];
        questions.Questions.forEach((question: any) => {
            let currentMultipleAnswer: Array<any> = [];
            if (question.MultipleAnswers !== null) {
                currentMultipleAnswer = question.MultipleAnswers.map((currentAnswer: any) => {
                    return {
                        Id: currentAnswer.Id,
                        Name: currentAnswer.Name,
                        checked: false
                    }
                });
                question.ShowMultipleAnswers = false;
            }
            question.MultipleAnswers = currentMultipleAnswer;
            localQuestions[question.Order - 1] = question;
        });
        return localQuestions;
    }


    // Init componet to manage the questionnaire
    initQuestionnaire(hazard: string | null): Promise<any> {
        return new Promise((resolve) => {
            if (hazard === null || !this.checkHazardName(this.state.questions, hazard) || !this.checkHazardDone(hazard)) {
                this.props.history.push("/tips/" + this.state.placeId + "/" + this.state.questions[0].Hazard.Name.toLowerCase());
                this.setState({
                    currentHazard: this.state.questions[0].Hazard.Id,
                    lastHazard: -99,
                    questionId: undefined,
                    currentQuestionId: this.state.questions[0].Id,
                    questionnaireLoaded: true
                }, () => {
                    setUtagView({
                        page_url: config.analytics.baseurl + "/tips/" + this.state.placeId + "/" + this.state.questions[0].Hazard.Name.toLowerCase(),
                        first_level: "tips",
                        second_level: this.state.questions[0].Hazard.Name.toLowerCase(),
                        step: convertHazardToAnalyticsStep(this.state.questions[0].Hazard.Name.toLowerCase())
                    }, true);
                    resolve(true);
                });
            } else {
                this.setState({
                    currentHazard: getHazardFirstQuestionByHazardName(this.state.questions, hazard).Hazard.Id,
                    lastHazard: -99,
                    questionId: undefined,
                    currentQuestion: getHazardFirstQuestionIndex(this.state.questions, hazard),
                    currentQuestionId: getHazardFirstQuestionByHazardName(this.state.questions, hazard).Id,
                    questionnaireLoaded: true,
                    introChecked: true
                }, () => {
                    setUtagView({
                        page_url: config.analytics.baseurl + "/tips/" + this.state.placeId + "/" + hazard,
                        first_level: "tips",
                        second_level: hazard,
                        step: convertHazardToAnalyticsStep(hazard)
                    }, true);
                    resolve(true);
                });
            }
        })
    }

    // Build array with a list of hazard done
    getHazardDone(questionnaire: any): Array<any> {
        return (
            questionnaire.Results === undefined ||
            questionnaire.Results.SuggestionsByPeril === undefined ||
            questionnaire.Results.SuggestionsByPeril === null ||
            questionnaire.Results.SuggestionsByPeril.length === 0
        ) ? [] : questionnaire.Results.SuggestionsByPeril.map((suggestion: any) => (
            (suggestion.Level !== undefined && suggestion.Level !== null) || (suggestion.Score !== undefined && suggestion.Score !== null) ?
                {
                    id: suggestion.Id,
                    name: suggestion.Name.toLowerCase()
                } : null
        )).filter((item: any) => item !== null);
    }

    // Return the number of hazard available (even if its questions are not yet answered)
    getNumOfHazard(questionnaire: any): number {
        let currentHazard: string = "";
        return questionnaire.Questions.reduce((sum: number, question: any) => {
            if (currentHazard !== question.Hazard.Name) {
                currentHazard = question.Hazard.Name;
                return sum + 1
            } else {
                return sum
            }
        }, 0)
    }

    // Get the suggestions object by hazard id
    getHazardNameByHazardId(results: any, hazardId: number): string {
        return results.SuggestionsByPeril.find((currentHazard: any) => currentHazard.Id === hazardId).Name;
    }

    // Get the suggestions object by hazard id
    getSuggestionsIndexByHazardId(results: any, hazardId: number): number {
        return results.SuggestionsByPeril.findIndex((currentHazard: any) => currentHazard.Id === hazardId);
    }

    // Get the suggestion object by suggestion id
    getSuggestionIndexBySuggestionId(suggestions: any, suggestiondId: number): number {
        return suggestions.findIndex((currenySuggestion: any) => currenySuggestion.Id === suggestiondId);
    }

    // Check if a string contains a valid hazard name
    checkHazardName(questions: any, hazard: string): boolean {
        return (questions.find((question: any) => question.Hazard.Name.toLowerCase() === hazard.toLowerCase()) !== undefined);
    }

    checkHazardDone(hazard: string) {
        return (this.state.hazardDone.find((currentHazard: any) => currentHazard.name.toLowerCase() === hazard.toLowerCase()) !== undefined);
    }

    // Rendere redirect component
    renderRedirectToHome(): Object {
        return (this.state.redirectToHome === true) ? <Redirect push to={{ pathname: "/" + config.steps[0] }} /> : '';
    }

    // Render Loader component
    renderLoader(): Object {
        return (this.state.loader) ? <Loader /> : <></>;
    }

    // Render the ErrorPopup component
    renderError(): Object {
        if (this.state.error) {
            return <ErrorPopup
                errorTitle={this.state.errorTitle}
                errorText={this.state.errorText}
            />
        } else if (this.state.msg) {
            return <ErrorPopup
                errorTitle={this.state.msgTitle}
                errorText={this.state.msgText}
                action={this.toggleErrorPopup}
            />
        } else {
            return <></>
        }
    }

    // Toggle overlay
    toggleMenuOverlay(): void {
        this.toggleMenuOverlayStatus(false);
    }

    // Toggle overlay status
    toggleMenuOverlayStatus(status: boolean): void {
        if (this.state.menuOverlayStatus) {
            this.setState({ menuOverlayStatus: status });
        } else {
            this.setState({ menuOverlayStatus: status });
        }
    }

    // Toggle ErrorPopup component
    toggleErrorPopup(): void {
        if (this.state.msg === false) {
            this.setState({ msg: true });
        } else {
            this.setState({ msg: false });
        }
    }
}