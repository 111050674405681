import React from "react";
import config from "./../../../config/config";
import { getDevice } from "./../../../lib/responsive-lib";
import MainBox from "./../../mainbox/MainBox";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import Loader from "./../../loader/Loader";
import { Redirect } from "react-router-dom";
import { changePassword } from "./../../../api/auth-api/api";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import "./NewPassword.css";

interface NewPasswordProps {
  cookies?: any;
  disableMarker: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  setMapsCoordinates: any;
  setMapStatus: any;
}

interface NewPasswordState {
  device: String;
  loader: Boolean;
  password: string;
  redirectToHome: boolean;
  redirectToLogin: boolean;
  email: string;
  rePassword: string;
  code: string;
  inputTypePassword: string;
  inputTypeRePassword: string;
  error: boolean;
  errorTitle: string;
  errorText: string;
  success: boolean;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class NewPassword extends React.Component<
  NewPasswordProps,
  NewPasswordState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      loader: false,
      password: "",
      rePassword: "",
      redirectToHome: false,
      redirectToLogin: false,
      email: "",
      code: "",
      inputTypePassword: "password",
      inputTypeRePassword: "password",
      error: false,
      errorTitle: "",
      errorText: "",
      msg: false,
      msgTitle: "",
      msgText: "",
      success: false,
    };

    // Method binding
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRePasswordChange = this.onRePasswordChange.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    this.props.disableMarker();
    this.props.setInfoWindowVisibility(false);
    this.props.setMapZoom(config.maps.default.zoom);
    this.setState(
      {
        device: getDevice(),
        loader: true,
      },
      () => {
        if (
          window.location.search !== undefined &&
          window.location.search !== ""
        ) {
          this.handleUrlParams(window.location.search.substring(1).split("&"));
        } else {
          this.setState({
            loader: false,
            redirectToHome: true,
          });
        }
      }
    );
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToLogin()}
        {this.renderReturnToHome()}
        <div className="newpassword__container">
          <MainBox>
            <div className="newpassword__header">
              <MBHeader
                device={this.state.device}
                loginEnabled={false}
                closeEnabled={true}
                cookies={this.props.cookies}
                parent={config.steps[5]}
                previous="login"
              />
            </div>
            <div className="newpassword__content">
              <div className="newpassword__title">
                Inserisci qui la tua nuova password e confermala prima di
                procedere.
              </div>
              <div
                className={
                  "newpassword__insert-form newpassword__insert-form-" +
                  this.state.device
                }
              >
                <div className="newpassword__input-label">Nuova Password</div>
                <div className="newpassword__input-box">
                  <input
                    id="newpassword__input-psw-1"
                    type={this.state.inputTypePassword}
                    className="newpassword__id"
                    onChange={this.onPasswordChange}
                  />
                  <img
                    src={
                      require("./../../../assets/system/icon-system-hide.svg")
                        .default
                    }
                    alt="Hide/Show password"
                    className="newpassword__show-psw-logo"
                    onClick={this.handleShowPassword}
                    id="newpassword__img-psw-1"
                  />
                </div>
                <div className="newpassword__input-label">
                  Conferma Password
                </div>
                <div className="newpassword__input-box">
                  <input
                    id="newpassword__input-psw-2"
                    type={this.state.inputTypeRePassword}
                    className="newpassword__id"
                    onChange={this.onRePasswordChange}
                  />
                  <img
                    src={
                      require("./../../../assets/system/icon-system-hide.svg")
                        .default
                    }
                    alt="Hide/Show password"
                    className="newpassword__show-psw-logo"
                    onClick={this.handleShowPassword}
                    id="newpassword__img-psw-2"
                  />
                </div>
              </div>
              <div className="newpassword__button-access-container">
                <div
                  className={"newpassword__button-access"}
                  onClick={this.handleChangePassword}
                >
                  Conferma
                </div>
              </div>
            </div>
          </MainBox>
        </div>
        {this.renderLoader()}
        {this.renderError()}
      </>
    );
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Handle password field 1 changes
  onPasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ password: e.currentTarget.value });
  }

  // Handle password field 2 changes
  onRePasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ rePassword: e.currentTarget.value });
  }

  // Manage the url params
  handleUrlParams(paramsArray: Array<string>): any {
    if (
      this.getValueByKey(this.getParam("email", paramsArray)) !== "" &&
      this.getValueByKey(this.getParam("code", paramsArray)) !== ""
    ) {
      this.setState({
        email: this.getValueByKey(this.getParam("email", paramsArray)),
        code: this.getValueByKey(this.getParam("code", paramsArray)),
        loader: false,
      });
    } else {
      this.setState({
        redirectToHome: true,
        loader: false,
      });
    }
  }

  // Get single param in params array
  getParam(parmas: string, paramsArray: Array<string>): string | undefined {
    return paramsArray.find(
      (param) => this.getKeyAndValueFromString(param)[0] === parmas
    );
  }

  // Get value from string key-value
  getValueByKey(keyValue: string | undefined): string {
    return keyValue !== undefined
      ? this.getKeyAndValueFromString(keyValue)[1]
      : "";
  }

  // Return key and value from a single url param
  getKeyAndValueFromString(string: string): Array<string> {
    const results = string.match(/([^=]+)=(.+)/i);
    if (results !== null) {
      return [results[1], results[2]];
    } else {
      return ["", ""];
    }
  }

  // Render component to return to home
  renderReturnToHome(): Object {
    return this.state.redirectToHome === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[0] }} />
    ) : (
      ""
    );
  }

  // Rendere redirect component
  renderRedirectToLogin(): Object {
    return this.state.redirectToLogin === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[5] }} />
    ) : (
      ""
    );
  }

  // Handle click on show/hide password button
  handleShowPassword(e: React.MouseEvent): void {
    switch (e.currentTarget.id) {
      case "newpassword__img-psw-1":
        if (this.state.inputTypePassword === "password") {
          this.setState({ inputTypePassword: "text" });
        } else {
          this.setState({ inputTypePassword: "password" });
        }
        break;
      case "newpassword__img-psw-2":
        if (this.state.inputTypeRePassword === "password") {
          this.setState({ inputTypeRePassword: "text" });
        } else {
          this.setState({ inputTypeRePassword: "password" });
        }
        break;
      default:
        break;
    }
  }

  // Handle click on change password button
  handleChangePassword(e: React.MouseEvent) {
    if (this.state.password !== this.state.rePassword) {
      this.setState({
        msgTitle: "Errore",
        msgText: "I due campi devono avere lo stesso contenuto",
        msg: true,
      });
    } else if (this.state.password.length < config.password.minLength) {
      this.setState({
        msgTitle: "Errore",
        msgText:
          "La password deve avere una lunghezza minima di " +
          config.password.minLength +
          " caratteri",
        msg: true,
      });
    } else {
      changePassword(
        this.state.password,
        this.state.rePassword,
        this.state.email,
        this.sanitizeCode(this.state.code)
      )
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res: any) => {
              this.setState({
                msgTitle: "Password aggiornata",
                msgText: "Ora potrai fare il login con la nuova password",
                msg: true,
                success: true,
              });
            });
          } else if (result.status === 400) {
            result.json().then((res: any) => {
              this.setState({
                errorTitle: "Errore",
                errorText: res.Description.split("-")[1],
                error: true,
              });
              console.error("Error changing password - " + res.Description);
            });
          } else {
            console.error("Error changing password - Status: " + result.status);
          }
        })
        .catch((error) => {
          console.error("Error changing password - " + error);
        });
    }
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({
        msg: false,
        redirectToLogin: true,
      });
    }
  }

  // Sanitize the token
  sanitizeCode(code: String): string {
    return code.replace(/\//gi, "$");
  }
}
