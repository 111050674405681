import React from "react";
import { Redirect } from "react-router-dom";
import { GoogleApiWrapper } from "google-maps-react";
import MainBox from "./../../mainbox/MainBox";
import ProgressBar from "./../../progressbar/ProgressBar";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import SearchAddress from "./searchAddress/SearchAddress";
import { getDevice } from "./../../../lib/responsive-lib";
import Loader from "./../../loader/Loader";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import config from "./../../../config/config";
import { checkPrivacy } from "./../../../lib/general-lib";
import { setUtagView } from "./../../../lib/general-lib";
import "./Position.css";
import { HomeBanner } from "../../home-banner";
interface PositionProps {
  cookies: any;
  setMapsCoordinates: any;
  setMarkerCoordinates: any;
  center: Object;
  setInfoWindowVisibility: any;
  setMapStatus: any;
  setCookiesCoordinates: any;
  setCookiesAddressInfo: any;
  google: any;
}

interface PositionState {
  device: String;
  currentView: string;
  loader: Boolean;
  error: Boolean;
  errorTitle: string;
  errorText: string;
  redirect: Boolean;
  redirectToPrivacy: Boolean;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export class Position extends React.Component<PositionProps, PositionState> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      currentView: "initial",
      loader: false,
      error: false,
      errorTitle: "",
      errorText: "",
      redirect: false,
      redirectToPrivacy: false,
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.toggleSearchAddress = this.toggleSearchAddress.bind(this);
    this.geolocation = this.geolocation.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.setPosition = this.setPosition.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  // Class variables
  /*global google*/
  geocoder = new google.maps.Geocoder();

  // React componentDidMount
  componentDidMount() {
    if (!checkPrivacy()) {
      this.setState({ redirectToPrivacy: true });
    } else {
      if (getDevice() !== "mobile") {
        this.props.setMapStatus(true);
      }
      this.props.setInfoWindowVisibility(false);
      this.setState({ device: getDevice() });
      // Analytics
      setUtagView(
        {
          page_url: config.analytics.baseurl + "/position",
          first_level: "position",
        },
        false
      );
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirect()}
        {this.renderRedirectToPrivecyCheck()}
        <div className="position__container">
          <MainBox>
            <div className="position__text-row-header">
              <MBHeader
                device={this.state.device}
                loginEnabled={true}
                closeEnabled={false}
                cookies={this.props.cookies}
                parent={config.steps[1]}
              />
            </div>
            <div className="position__text-row-progressbar">
              <ProgressBar step={"01"} />
            </div>
            {this.renderView()}
          </MainBox>
          <HomeBanner />
        </div>
        {this.renderLoader()}
        {this.renderError()}
      </>
    );
  }

  // Rendere redirect component
  renderRedirectToPrivecyCheck(): Object {
    return this.state.redirectToPrivacy === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[0] }}
      />
    ) : (
      ""
    );
  }

  // Render different view of this step
  renderView(): Object {
    switch (this.state.currentView) {
      case "searchAddress":
        return (
          <>
            <SearchAddress
              action={this.toggleSearchAddress}
              setPosition={this.setPosition}
              nextStep={this.nextStep}
              setCookiesAddressInfo={this.props.setCookiesAddressInfo}
            />
          </>
        );
      case "initial":
      default:
        return (
          <>
            <div className="position__text-row-0 light">
              Per valutare il rischio della tua azienda ci serve conoscerne la
              posizione.
            </div>
            <div className="position__text-row-1 light">
              Puoi cercare l'indirizzo o utilizzare la geo-localizzazione (se ti
              trovi nei locali aziendali)
            </div>
            {this.getRow2()}
          </>
        );
    }
  }

  // Render row #2
  getRow2(): Object {
    switch (this.state.device) {
      case "mobile":
        return (
          <>
            <div className="position__text-row-2-mobile">
              <div
                className="position__text-row-2-a-mobile"
                onClick={this.toggleSearchAddress}>
                <div className="position__text-row-button-search">
                  <div className="position__text-row-button-search-ico">
                    <img
                      src={
                        require("./../../../assets/product/icon-product-marker-white.svg")
                          .default
                      }
                      alt="Cerca indirizzo"
                      className="position__pin-icon"
                    />
                  </div>
                  <div className="position__text-row-button-search-text font-generic">
                    Cerca indirizzo
                  </div>
                </div>
              </div>
              <div
                className="position__text-row-2-b-mobile"
                onClick={this.geolocation}>
                <div className="position__text-row-button-gps">
                  <div className="position__text-row-button-gps-ico">
                    <img
                      src={
                        require("./../../../assets/product/icon-product-geo.svg")
                          .default
                      }
                      alt="Geolocalizzazione"
                      className="position__geo-icon"
                    />
                  </div>
                  <div className="position__text-row-button-gps-text font-generic">
                    Geolocalizzazione
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="position__text-row-2">
              <div
                className="position__text-row-2-a"
                onClick={this.toggleSearchAddress}>
                <div
                  className={
                    "position__text-row-2-a-icon position__text-row-2-a-icon-" +
                    this.state.device
                  }></div>
                <div className="position__text-row-2-a-text">
                  Ricerca Indirizzo
                </div>
              </div>
              <div
                className="position__text-row-2-b"
                onClick={this.geolocation}>
                <div
                  className={
                    "position__text-row-2-b-icon position__text-row-2-b-icon-" +
                    this.state.device
                  }></div>
                <div className="position__text-row-2-b-text">
                  Geolocalizzazione
                </div>
              </div>
            </div>
          </>
        );
    }
  }

  // Render SearchAddress component
  toggleSearchAddress(e: React.MouseEvent): void {
    if (this.state.currentView === "searchAddress") {
      this.setState({ currentView: "initial" });
    } else {
      this.setState({ currentView: "searchAddress" });
    }
  }

  // Perform geolocation
  geolocation(e: React.MouseEvent): void {
    this.setState({ loader: true }, () => {
      setTimeout(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => this.setPosition(position, true, true),
            () => this.handleLocationError(true),
            {
              enableHighAccuracy: false,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } else {
          this.handleLocationError(false);
        }
      }, 1500);
    });
  }

  // Set current position in state
  setPosition(position: any, redirect: boolean, geo: boolean): void {
    if (geo) {
      this.revGeocode({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }).then((results) => {
        this.props.setCookiesAddressInfo(results.address_components);
      });
    }
    this.props.setCookiesCoordinates({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    this.setState(
      {
        loader: false,
      },
      () => {
        this.props.setMapsCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        this.props.setMarkerCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        if (redirect) {
          this.nextStep();
        }
      }
    );
  }

  // Run Google Maps reverse geocoding
  revGeocode(latlng: any): Promise<any> {
    return new Promise((resolve) => {
      this.geocoder.geocode({ location: latlng }, (results, status) => {
        if (results[0]) {
          resolve(results[0]);
        } else {
          resolve("");
        }
      });
    });
  }

  // Handle location error through ErrorPopup component
  handleLocationError(browserHasGeolocation: Boolean): void {
    if (browserHasGeolocation) {
      this.setState({
        loader: false,
        msgTitle: "Avviso",
        msgText:
          "Impossibile effettuare la geolocalizzazione. Verificare che il sito disponga dei permessi necessari",
        msg: true,
      });
    } else {
      this.setState({
        loader: false,
        msgTitle: "Avviso",
        msgText: "Il tuo browser non supporta la geolocalizzazione",
        msg: true,
      });
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Rendere redirect component
  renderRedirect(): Object {
    return this.state.redirect === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[4] }}
      />
    ) : (
      ""
    );
  }

  // Go to the next step
  nextStep(): void {
    this.setState({ redirect: true });
  }
}

export default GoogleApiWrapper({
  apiKey: config.maps.apiKey,
})(Position);
