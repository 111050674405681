import React from "react";
import { getDevice } from "../../../lib/responsive-lib";
import "./QwProgress.css";

interface QwProgressProps {
  currentHazard: number;
  lastHazard: number;
  questions: Array<any>;
  questionId: number;
  currentQuestionId: number;
  partialResultsVisible: boolean;
}

interface QwProgressState {
  device: String;
  numOfQuestion: number;
  localCurrentQuestion: number;
}
export default class QwProgress extends React.Component<
  QwProgressProps,
  QwProgressState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      numOfQuestion: 0,
      localCurrentQuestion: 0,
    };
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({ device: getDevice() });
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.currentHazard !== this.props.currentHazard &&
      this.props.currentHazard !== undefined
    ) {
      this.setState({
        numOfQuestion: this.getNumOfQuestions(),
      });
    } else if (
      prevProps.lastHazard !== this.props.lastHazard ||
      JSON.stringify(prevProps.questions) !==
        JSON.stringify(this.props.questions)
    ) {
      if (
        this.props.questions !== undefined &&
        this.props.lastHazard !== undefined
      ) {
        this.setState({
          numOfQuestion: this.getNumOfQuestions(),
          localCurrentQuestion: this.getLocalCurrentQuestion(),
        });
      }
    } else if (prevProps.questionId !== this.props.questionId) {
      if (
        this.props.questions !== undefined &&
        this.props.lastHazard !== undefined
      ) {
        this.setState({
          localCurrentQuestion: this.getLocalCurrentQuestion(),
        });
      }
    } else if (
      prevProps.partialResultsVisible !== this.props.partialResultsVisible
    ) {
      if (
        this.props.questions !== undefined &&
        this.props.lastHazard !== undefined &&
        this.props.currentHazard !== undefined
      ) {
        this.setState({
          numOfQuestion: this.getNumOfQuestions(),
          localCurrentQuestion: this.getLocalCurrentQuestion(),
        });
      }
    }
  }

  // React render
  public render() {
    return (
      <>
        <div
          className={
            "qwprogress__container qwprogress__container-" + this.state.device
          }
        >
          <div
            className={
              "qwprogress__textcontainer qwprogress__textcontainer-" +
              this.state.device
            }
          >
            {Math.round(this.getQuestionPercentage()) + "% COMPLETATO"}
          </div>
          <div
            className={
              "qwprogress__barcontainer qwprogress__barcontainer-" +
              this.state.device
            }
          >
            <div
              className={
                "qwprogress__itemscontainer qwprogress__itemscontainer-" +
                this.state.device
              }
            >
              {Array.from(Array(this.state.numOfQuestion)).map((x, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "qwprogress__itemcontainer qwprogress__itemcontainer-" +
                      this.state.device
                    }
                  >
                    {index < this.state.localCurrentQuestion ? (
                      <img
                        src={
                          require("./../../../assets/misc/selected.svg").default
                        }
                        alt="Icona avanzamento area questionario accesa"
                        className={
                          "qwprogress__icon-on qwprogress__icon-on-" +
                          this.state.device
                        }
                      />
                    ) : (
                      <img
                        src={
                          require("./../../../assets/misc/disabled.svg").default
                        }
                        alt="Icona avanzamento area questionario spenta"
                        className={
                          "qwprogress__icon-off qwprogress__icon-off-" +
                          this.state.device
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }

  // Calculate the percentage of completion
  getQuestionPercentage() {
    return this.state.numOfQuestion !== 0
      ? (this.state.localCurrentQuestion * 100) / this.state.numOfQuestion
      : 0;
  }

  // Get number of question for the current hazard
  getNumOfQuestions() {
    return this.getCurrentQuestionsArray().length;
  }

  // Get the index of the current question in the current hazard questions
  getLocalCurrentQuestion(): number {
    if (
      this.props.questionId === undefined ||
      (this.props.currentHazard !== this.props.lastHazard &&
        this.props.partialResultsVisible === false)
    ) {
      return 0;
    } else {
      let cursor = 1;
      let found = false;
      this.getCurrentQuestionsArray().forEach((question) => {
        if (question.Id === this.props.questionId) found = true;
        if (!found) cursor++;
      });
      return cursor;
    }
  }

  // Get an array with the questions for the current hazard
  getCurrentQuestionsArray() {
    return this.props.questions
      .map((question) => {
        return (this.props.partialResultsVisible &&
          question.Hazard.Id === this.props.lastHazard) ||
          (!this.props.partialResultsVisible &&
            question.Hazard.Id === this.props.currentHazard)
          ? question
          : null;
      })
      .filter((el) => el != null);
  }
}
