import React from 'react';
import { getDevice } from '../../../../lib/responsive-lib';
import './StandardButton.css';

interface StandardButtonProps {
    id?: string;
    text: string;
    active: boolean;
    onClick?: any;
}

interface StandardButtonState {
    device: String;
}
export default class StandardButton extends React.Component<StandardButtonProps, StandardButtonState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            device: getDevice(),
        });
    }

    // React render
    public render() {
        return <>
            <div
                className={"standardbutton__container standardbutton__container-" + this.state.device + " standardbutton__container-" + (this.props.active ? "on" : "off")}
                onClick={this.props.onClick}
            >
                <div className={"standardbutton__text standardbutton__text-" + this.state.device + " standardbutton__text-" + (this.props.active ? "on" : "off")}>
                    {this.props.text}
                </div>
            </div>
        </>
    }
}