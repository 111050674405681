import React from 'react';
import { getDevice } from '../../../lib/responsive-lib';
import './IntroSheet.css';

interface IntroSheetProps {
    startQuestionnaire: any;
    currentQuestion: number;
    questionnaireComplete: Boolean;
}

interface IntroSheetState {
    device: String;
}
export default class IntroSheet extends React.Component<IntroSheetProps, IntroSheetState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            device: getDevice(),
        });
    }

    // React render
    public render() {
        if (this.props.questionnaireComplete===false) {
            return [
                <div
                    key="intro-sheet"
                    className={"introsheet__questionsheet-intro introsheet__questionsheet-intro-" + (this.props.currentQuestion !== 0 ? 'disabled' : 'enabled') + " introsheet__questionsheet-intro-" + this.state.device}
                >
                    <div className="introsheet__questionsheet-header">
                        <div className={"introsheet__question-header-row-1 introsheet__question-header-row-1-" + this.state.device}>
                            Strumento di autovalutazione del Rischio Climatico
                    </div>
                        <div className={"introsheet__question-header-row-2 introsheet__question-header-row-2-" + this.state.device}>
                            Derris, il clima cambia. Riduciamo i rischi.
                    </div>
                    </div>
                    <div className={"introsheet__questionsheet-text introsheet__questionsheet-text-" + this.state.device}>
                        <div className="introsheet__questionsheet-text-title">
                            Rispondendo alle domande per ciascun pericolo, il tool:
                    </div>
                        <ul>
                            <li>analizzerà la vulnerabilità della    tua azienda a questi fenomeni;</li>
                            <li>ti darà un'indicazione del tuo livello rischio per ciascuno di essi;</li>
                            <li>ti fornirà alcuni suggerimenti di azioni per prevenire e gestire questi rischi.</li>
                        </ul>
                    </div>
                    <div className={"introsheet__button-container introsheet__button-container-" + this.state.device}>
                        <div className="introsheet__button-intro" onClick={this.props.startQuestionnaire}>
                            Continua
                    </div>
                    </div>
                </div>
            ]
        } else {
            return [
                <div
                key="intro-sheet"
                className={"introsheet__questionsheet-intro introsheet__questionsheet-intro-" + (this.props.currentQuestion !== 0 ? 'disabled' : 'enabled') + " introsheet__questionsheet-intro-" + this.state.device}
            >
                <div className="introsheet__questionsheet-header">
                    <div className={"introsheet__question-header-row-1 introsheet__question-header-row-1-" + this.state.device}>
                        Strumento di autovalutazione del Rischio Climatico
                </div>
                    <div className={"introsheet__question-header-row-2 introsheet__question-header-row-2-" + this.state.device}>
                        Derris, il clima cambia. Riduciamo i rischi.
                </div>
                </div>
                <div className={"introsheet__questionsheet-text introsheet__questionsheet-text-" + this.state.device}>
                Se hai già compilato il questionario durante un precedente accesso, puoi accedere direttamente alla sezione di tuo interesse (suggerimenti, indice di resilienza o stampa il piano) dal menù in alto a sinistra. Se invece vuoi finalizzare la compilazione del questionario o modificare le tue risposte, clicca su ok per proseguire”
                </div>
                <div className={"introsheet__button-container introsheet__button-container-" + this.state.device}>
                    <div className="introsheet__button-intro" onClick={this.props.startQuestionnaire}>
                        OK
                </div>
                </div>
            </div>
            ]
        }
    }
}