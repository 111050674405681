import React from "react";
import { withCookies } from "react-cookie";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { getDevice } from "./../src/lib/responsive-lib";
import MapBackground from "../src/component/mapbackground/MapBackground";
import Welcome from "./component/steps/welcome/Welcome";
import Position from "./component/steps/position/Position";
import Registration from "./component/steps/registration/Registration";
import Login from "./component/steps/login/Login";
import Start from "./component/steps/start/Start";
import PasswordRecovery from "./component/steps/passwordRecovery/PasswordRecovery";
import SearchAddressResults from "./component/steps/searchaddressresults/SearchAddressResults";
import NewPassword from "./component/steps/newPassword/NewPassword";
import QuestionView from "./component/questionView/QuestionView";
import Privacy from "./component/steps/welcome/privacy/Privacy";
import Disclaimer from "./component/steps/welcome/disclaimer/Disclaimer";
import TipsView from "./component/tipsView/TipsView";
import config from "./../src/config/config";
import LegalModal from "./component/legal-policy/Privacy";
import "./App.css";

interface AppProps {
  cookies?: any;
}

interface AppState {
  device: String;
  mapCenter: any;
  mapZoom: any;
  markerVisible: any;
  mapClickableIcons: any;
  mapFullscreenControl: any;
  mapKeyboardShortcuts: any;
  mapTypeControl: any;
  mapRotateControl: any;
  mapScaleControl: any;
  mapStreetViewControl: any;
  mapZoomControl: any;
  markerDraggable: any;
  infoWindowIsVisible: Boolean;
  markerCurrentPosition: any;
  positionSetted: Boolean;
  panMap: any;
  mapBackgroundEnabled: boolean;
  addressInfo: any;
  show: boolean;
  type: number;
  showFooter: { val: boolean; route: boolean };
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      mapCenter: config.maps.default.center,
      mapZoom: config.maps.default.zoom,
      mapClickableIcons: config.maps.default.options.mapClickableIcons,
      mapFullscreenControl: config.maps.default.options.mapFullscreenControl,
      mapKeyboardShortcuts: config.maps.default.options.mapKeyboardShortcuts,
      mapTypeControl: config.maps.default.options.mapTypeControl,
      mapRotateControl: config.maps.default.options.mapRotateControl,
      mapScaleControl: config.maps.default.options.mapScaleControl,
      mapStreetViewControl: config.maps.default.options.mapStreetViewControl,
      mapZoomControl: config.maps.default.options.mapZoomControl,
      markerVisible: config.marker.default.options.visible,
      markerDraggable: config.marker.default.options.draggable,
      markerCurrentPosition: config.marker.default.center,
      infoWindowIsVisible: config.infoWindow.default.visible,
      positionSetted: false,
      panMap: config.maps.default.panMap,
      mapBackgroundEnabled: false,
      addressInfo: {},
      show: false,
      type: 0,
      showFooter: {
        val: true,
        route: true,
      },
    };

    // Method binding
    this.setMapsCoordinates = this.setMapsCoordinates.bind(this);
    this.setMarkerCoordinates = this.setMarkerCoordinates.bind(this);
    this.enableMarker = this.enableMarker.bind(this);
    this.setMapZoom = this.setMapZoom.bind(this);
    this.disableMarker = this.disableMarker.bind(this);
    this.setInfoWindowVisibility = this.setInfoWindowVisibility.bind(this);
    this.changePanMap = this.changePanMap.bind(this);
    this.setCookiesCoordinates = this.setCookiesCoordinates.bind(this);
    this.setCookiesAddressInfo = this.setCookiesAddressInfo.bind(this);
    this.getCookiesAddressInfo = this.getCookiesAddressInfo.bind(this);
    this.setMapStatus = this.setMapStatus.bind(this);
    this.showModalCookies = this.showModalCookies.bind(this);
    this.showModalPrivacy = this.showModalPrivacy.bind(this);
    this.showModalLegal = this.showModalLegal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggleShowFooter = this.toggleShowFooter.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({ device: getDevice() });
  }

  componentDidUpdate() {
    this.listener();
  }

  listener = () => {
    const url = window.location.pathname.split("/").pop();

    if (
      url !== "welcome" &&
      url !== "position" &&
      url !== "registration" &&
      url !== "start"
    ) {
      if (this.state.showFooter.route) {
        this.setState({
          showFooter: {
            val: false,
            route: false,
          },
        });
      }
    } else {
      if (!this.state.showFooter.route) {
        this.setState({
          showFooter: {
            val: true,
            route: true,
          },
        });
      }
    }
  };

  openPrivacyText() {}

  openCookiesText() {}

  public render() {
    return (
      <>
        <MapBackground
          mapZoom={this.state.mapZoom}
          mapCenter={this.state.mapCenter}
          mapClickableIcons={this.state.mapClickableIcons}
          mapFullscreenControl={this.state.mapFullscreenControl}
          mapKeyboardShortcuts={this.state.mapKeyboardShortcuts}
          mapTypeControl={this.state.mapTypeControl}
          mapRotateControl={this.state.mapRotateControl}
          mapScaleControl={this.state.mapScaleControl}
          mapStreetViewControl={this.state.mapStreetViewControl}
          mapZoomControl={this.state.mapZoomControl}
          markerDraggable={this.state.markerDraggable}
          infoWindowIsVisible={this.state.infoWindowIsVisible}
          markerCurrentPosition={this.state.markerCurrentPosition}
          markerVisible={this.state.markerVisible}
          setCookiesAddressInfo={this.setCookiesAddressInfo}
          panMap={this.state.panMap}
          mapBackgroundEnabled={this.state.mapBackgroundEnabled}
          getCookiesAddressInfo={this.getCookiesAddressInfo}
        />
        <span
          className={
            "app__main-container app__main-container-" + this.state.device
          }>
          <Router>
            <Switch>
              {/* Application Root */}
              <Route
                path="/"
                exact
                render={() => (
                  <Redirect
                    push
                    to={"/" + config.steps[0]}
                  />
                )}
              />
              {/* WELCOME route */}
              <Route
                path={"/" + config.steps[0]}
                render={() => (
                  <Welcome
                    cookies={this.props.cookies}
                    setMapsCoordinates={this.setMapsCoordinates}
                    center={this.state.mapCenter}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                  />
                )}
              />
              {/* POSITION route */}
              <Route
                path={"/" + config.steps[1]}
                render={() => (
                  <Position
                    cookies={this.props.cookies}
                    setMapsCoordinates={this.setMapsCoordinates}
                    setMarkerCoordinates={this.setMarkerCoordinates}
                    center={this.state.mapCenter}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                    setCookiesCoordinates={this.setCookiesCoordinates}
                    setCookiesAddressInfo={this.setCookiesAddressInfo}
                  />
                )}
              />
              {/* REGISTRATION route */}
              <Route
                path={"/" + config.steps[2]}
                render={(props) => (
                  <Registration
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapsCoordinates={this.setMapsCoordinates}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* START route */}
              <Route
                path={"/" + config.steps[3]}
                render={() => (
                  <Start
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                    getCookiesAddressInfo={this.getCookiesAddressInfo}
                    deleteCookiesCoordinates={this.deleteCookiesCoordinates}
                  />
                )}
              />
              {/* RESULTS route */}
              <Route
                path={"/" + config.steps[4]}
                render={() => (
                  <SearchAddressResults
                    cookies={this.props.cookies}
                    setMapsCoordinates={this.setMapsCoordinates}
                    center={this.state.mapCenter}
                    setMapZoom={this.setMapZoom}
                    disableMarker={this.disableMarker}
                    markerCurrentPosition={this.state.markerCurrentPosition}
                    enableMarker={this.enableMarker}
                    changePanMap={this.changePanMap}
                    setMarkerCoordinates={this.setMarkerCoordinates}
                    setMapStatus={this.setMapStatus}
                  />
                )}
              />
              {/* LOGIN route */}
              <Route
                path={"/" + config.steps[5] + "/:params?"}
                render={(props) => (
                  <Login
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* PASSWORD RECOVERY route */}
              <Route
                path={"/" + config.steps[6]}
                render={() => (
                  <PasswordRecovery
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                  />
                )}
              />
              {/* NEW PASSWORD route */}
              <Route
                path={"/" + config.steps[7]}
                render={(props) => (
                  <NewPassword
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapsCoordinates={this.setMapsCoordinates}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* QUESTIONNAIRE route */}
              <Route
                path={"/" + config.steps[8]}
                render={(props) => (
                  <QuestionView
                    cookies={this.props.cookies}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* PRIVACY route */}
              <Route
                path={"/" + config.steps[9]}
                render={(props) => (
                  <Privacy
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* PRIVACY route */}
              <Route
                path={"/" + config.steps[11]}
                render={() => (
                  <Disclaimer
                    cookies={this.props.cookies}
                    disableMarker={this.disableMarker}
                    setMapZoom={this.setMapZoom}
                    setInfoWindowVisibility={this.setInfoWindowVisibility}
                    setMapStatus={this.setMapStatus}
                  />
                )}
              />
              {/* TIPS route */}
              <Route
                path={"/" + config.steps[10]}
                render={(props) => (
                  <TipsView
                    cookies={this.props.cookies}
                    setMapStatus={this.setMapStatus}
                    {...props}
                  />
                )}
              />
              {/* DEFAULT route */}
              <Route
                path="*"
                exact
                render={() => (
                  <Redirect
                    push
                    to={"/" + config.steps[0]}
                  />
                )}
              />
            </Switch>
          </Router>
          <LegalModal
            show={this.state.show}
            type={this.state.type}
            handleClose={this.hideModal}>
            <p>Modal</p>
          </LegalModal>

          <div
            className={`privacy-icon-container ${
              this.state.showFooter.val ? "active" : ""
            }`}>
            <div className="privacy-toolbar">
              <ul>
                <li>
                  <span
                    onClick={this.showModalPrivacy}
                    className="footer-link">
                    Privacy
                  </span>
                </li>
                <li>
                  <span
                    onClick={this.showModalCookies}
                    className="footer-link">
                    Cookies
                  </span>
                </li>
                <li>
                  <span
                    onClick={this.showModalLegal}
                    className="footer-link">
                    Termini e condizioni del servizio
                  </span>
                </li>
              </ul>
            </div>
            <div
              onClick={this.toggleShowFooter}
              className="privacy-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </div>
          </div>
        </span>
      </>
    );
  }

  toggleShowFooter = () => {
    this.setState({
      showFooter: {
        val: !this.state.showFooter.val,
        route: this.state.showFooter.route,
      },
    });
  };

  showModalCookies = () => {
    this.setState({ show: true, type: 0 });
  };
  showModalPrivacy = () => {
    this.setState({ show: true, type: 1 });
  };
  showModalLegal = () => {
    this.setState({ show: true, type: 2 });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  // Enable/Disable Google Maps background
  setMapStatus(status: boolean): void {
    this.setState({ mapBackgroundEnabled: status });
  }

  // Change the panMap value of the map
  changePanMap(offset: any): void {
    this.setState({ panMap: offset });
  }

  // Set the info window visibility
  setInfoWindowVisibility(isVisible: Boolean): void {
    this.setState({ infoWindowIsVisible: isVisible });
  }

  // Set the map coordinates
  setMapsCoordinates(coordinates: Object): void {
    this.setState({ mapCenter: coordinates });
  }

  // Set the marker coordinates
  setMarkerCoordinates(coordinates: Object): void {
    this.setState({ markerCurrentPosition: coordinates });
  }

  // Save coordinates on cookies
  setCookiesCoordinates(coordinates: Object | null): void {
    const { cookies } = this.props;
    cookies.set("derris_current_coordinates", JSON.stringify(coordinates), {
      path: "/",
    });
  }

  // Delete coordinates on cookies
  deleteCookiesCoordinates(): void {
    const { cookies } = this.props;
    cookies.set("derris_current_addrress", "", { path: "/" });
  }

  // Save coordinates on cookies
  setCookiesAddressInfo(coordinates: Object): void {
    const { cookies } = this.props;
    cookies.set("derris_current_addrress", JSON.stringify(coordinates), {
      path: "/",
    });
  }

  // Load coordinates from cookies
  getCookiesAddressInfo(): any {
    const { cookies } = this.props;
    return cookies.get("derris_current_addrress");
  }

  // Enable the active marker
  enableMarker(): void {
    this.setState(
      {
        markerVisible: true,
        markerDraggable: false,
        infoWindowIsVisible: false,
      },
      () => {
        this.setInfoWindowVisibility(true);
      }
    );
  }

  // Disable the active marker
  disableMarker(): void {
    this.setState({
      markerVisible: false,
      markerDraggable: false,
      infoWindowIsVisible: true,
    });
  }

  // Set Google Maps zoom
  setMapZoom(newZoom: Number) {
    this.setState({ mapZoom: newZoom });
  }
}

export default withCookies(App);
