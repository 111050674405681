import React from 'react';
import { getDevice } from '../../../../lib/responsive-lib';
import './CounterButton.css';

interface CounterButtonProps {
    id?: string;
    text: string;
    counter: number;
    active: boolean;
    onClick?: any;
    clear: boolean;
    style?: any;
}

interface CounterButtonState {
    device: String;
}
export default class CounterButton extends React.Component<CounterButtonProps, CounterButtonState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({
            device: getDevice(),
        });
    }

    // React render
    public render() {
        return <>
            <div
                className={"counterbutton__container counterbutton__container-" + this.state.device + " counterbutton__container-" + (this.props.active ? "on" : "off") + " counterbutton__container-" + (this.props.clear ? "clear" : "normal")}
                onClick={this.props.onClick}
                style={this.props.style}
                id={this.props.id}
            >
                <div className={"counterbutton__text counterbutton__text-" + this.state.device + " counterbutton__text-" + (this.props.active ? "on" : "off")}>
                    {this.props.text}
                </div>
                <div className={"counterbutton__counter counterbutton__counter-" + this.state.device + " counterbutton__counter-" + (this.props.active ? "on" : "off")}>
                    <div className={"counterbutton__counter-box counterbutton__counter-box-" + this.state.device + " counterbutton__counter-box-" + (this.props.active ? "on" : "off")}>
                        {this.props.counter}
                    </div>
                </div>
            </div>
        </>
    }
}