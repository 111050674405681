import React from 'react';
import { getDevice } from './../../lib/responsive-lib';
import QwHeader from './../qwHeader/QwHeader';
import QwProgres from './qwProgress/QwProgress';
import {
    checkLogin,
    isNumber,
    convertHazardToAnalyticsStep,
    getHazardFirstQuestionByHazardName,
    getHazardFirstQuestionIndex
} from './../../lib/general-lib';
import { Redirect } from 'react-router-dom';
import { getQuestionnaire, checkQuestionnaire, saveQuestionnaire } from './../../api/questionnaire-api/api';
import ErrorPopup from './../errorpopup/ErrorPopup';
import Loader from './../loader/Loader';
import QuestionsSheets from './questionsSheets/QuestionsSheets';
import IntroSheet from './introSheet/IntroSheet';
import PartialResultsSheet from './partialResultsSheet/PartialResultsSheet';
import Sidebar from './../sidebar/Sidebar';
import { setUtagView, setUtagLink, trimString } from './../../lib/general-lib';
import config from './../../config/config';
import './QuestionView.css';

interface QuestionViewProps {
    cookies?: any;
    setMapStatus: any;
    location: any;
    history?: any;
}
interface QuestionViewState {
    device: String;
    redirectToHome: boolean;
    loader: Boolean;
    error: boolean;
    errorTitle: string;
    errorText: string;
    redirectToStart: boolean;
    questions: Array<any>;
    questionsInitial: Array<any>;
    currentQuestion: number;
    currentQuestionRelative: number;
    questionsSheets: any;
    questionSheetStyle: any;
    sheetStyle: any;
    currentHazard: number;
    lastHazard: number;
    questionId: any;
    currentQuestionId: any;
    questionnaireLoaded: boolean;
    currentProvince: string;
    introChecked: boolean;
    partialResultsVisible: boolean;
    results: any;
    placeId: string;
    hazardDone: Array<any>;
    sidebarStatus: boolean;
    placeInfo: any;
    sidebarLocked: boolean;
    questionnaireDone: boolean;
    redirectToTips: boolean;
    tipsView: string;
    menuOverlayStatus: boolean;
}
export default class QuestionView extends React.Component<QuestionViewProps, QuestionViewState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: '',
            redirectToHome: false,
            loader: false,
            error: false,
            errorTitle: '',
            errorText: '',
            redirectToStart: false,
            questions: [],
            questionsInitial: [],
            currentQuestion: 0,
            currentQuestionRelative: 0,
            questionsSheets: undefined,
            questionSheetStyle: {},
            sheetStyle: {},
            currentHazard: 0,
            lastHazard: 0,
            questionId: undefined,
            currentQuestionId: undefined,
            questionnaireLoaded: false,
            currentProvince: '',
            introChecked: false,
            partialResultsVisible: false,
            results: {},
            placeId: '',
            hazardDone: [],
            sidebarStatus: false,
            placeInfo: {},
            sidebarLocked: false,
            questionnaireDone: false,
            redirectToTips: false,
            tipsView: "",
            menuOverlayStatus: false
        }

        // Method binding
        this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
        this.setQuestionsSheetStyle = this.setQuestionsSheetStyle.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
        this.startQuestionnaire = this.startQuestionnaire.bind(this);
        this.handleMultiAnswer = this.handleMultiAnswer.bind(this);
        this.setMultiAnswer = this.setMultiAnswer.bind(this);
        this.goToNextHazard = this.goToNextHazard.bind(this);
        this.switchToHazard = this.switchToHazard.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.toggleResiliencyView = this.toggleResiliencyView.bind(this);
        this.toggleResiliencyPrintView = this.toggleResiliencyPrintView.bind(this);
        this.toggleMenuOverlayStatus = this.toggleMenuOverlayStatus.bind(this);
        this.toggleMenuOverlay = this.toggleMenuOverlay.bind(this);
    }

    // React componentDidMount
    componentDidMount() {
        this.props.setMapStatus(false);
        if (!checkLogin()) {
            this.setState({ redirectToHome: true });
        } else {
            this.setState({ device: getDevice() }, () => { this.parseUrl() });
        }
    }

    // React componentWillUnmount
    componentWillUnmount() {
        window.removeEventListener("resize", this.setQuestionsSheetStyle);
    }

    // React componentDidUpdate
    componentDidUpdate(prevProps: any, prevState: any) {
        if (JSON.stringify(prevState.questions) !== JSON.stringify(this.state.questions)) this.setQuestionsSheetStyle();
        if (prevState.currentQuestionRelative !== this.state.currentQuestionRelative) this.setQuestionsSheetStyle();
    }

    // React render
    public render() {
        return <>
            {this.renderRedirectToHome()}
            {this.renderRedirectToStart()}
            <Sidebar
                sidebarStatus={this.state.sidebarStatus}
                placeInfo={this.state.placeInfo}
                currentView="QuestionView"
                results={this.state.results}
                placeId={this.state.placeId}
                locked={this.state.sidebarLocked}
                toggleSidebar={this.toggleSidebar}
                toggleResiliencyView={this.toggleResiliencyView}
                toggleResiliencyPrintView={this.toggleResiliencyPrintView}
            />
            <div className={"questionview__container-sidebar-overlay questionview__container-sidebar-overlay-" + (this.state.sidebarStatus && !this.state.sidebarLocked ? "enabled" : "disabled")} onClick={this.toggleSidebar}></div>
            <div className={"questionview__container questionview__container-" + this.state.device}>
                <QwHeader
                    currentHazard={this.state.currentHazard}
                    lastHazard={this.state.lastHazard}
                    questions={this.state.questions}
                    partialResultsVisible={this.state.partialResultsVisible}
                    hazardDone={this.state.hazardDone}
                    switchToHazard={this.switchToHazard}
                    toggleSidebar={this.toggleSidebar}
                    title="QUESTIONARIO"
                    hazardBarStatus={true}
                    cookies={this.props.cookies}
                    sidebarStatus={this.state.sidebarStatus}
                    toggleMenuOverlayStatus={this.toggleMenuOverlayStatus}
                    menuOverlayStatus={this.state.menuOverlayStatus}
                />
                <div className={"questionview__questions-container questionview__questions-container-" + this.state.device}>
                    {this.state.questionnaireLoaded && !this.state.introChecked ?
                        <IntroSheet
                            startQuestionnaire={this.startQuestionnaire}
                            currentQuestion={this.state.currentQuestion}
                            questionnaireComplete={this.checkQuestionnaireCompleteStatus()}
                        /> :
                        <></>}
                    <div className={"questionview__questionssheets questionview__questionssheets-" + this.state.device} style={this.state.questionSheetStyle}>
                        {this.state.questionnaireLoaded && this.state.introChecked ?
                            <QuestionsSheets
                                questions={this.state.questions}
                                currentHazard={this.state.currentHazard}
                                sheetStyle={this.state.sheetStyle}
                                currentQuestion={this.state.currentQuestion}
                                handleMultiAnswer={this.handleMultiAnswer}
                                handleAnswer={this.handleAnswer}
                                setMultiAnswer={this.setMultiAnswer}
                            /> :
                            <></>}
                    </div>
                    {this.state.partialResultsVisible ?
                        <PartialResultsSheet
                            results={this.state.results}
                            placeInfo={this.state.placeInfo}
                            goToNextHazard={this.goToNextHazard}
                            lastHazard={this.state.lastHazard}
                            lastHazardName={this.getHazardNameById(this.state.lastHazard)}
                            questionnaireDone={this.state.questionnaireDone}
                            placeId={this.state.placeId}
                        /> :
                        <></>}
                </div>
                <QwProgres
                    lastHazard={this.state.lastHazard}
                    currentHazard={this.state.currentHazard}
                    questions={this.state.questions}
                    questionId={this.state.questionId}
                    currentQuestionId={this.state.currentQuestionId}
                    partialResultsVisible={this.state.partialResultsVisible}
                />
            </div>
            {this.renderLoader()}
            {this.renderError()}
            {this.renderRedirectToTips()}
        </>;
    }

    // Render Loader component
    renderLoader(): Object {
        return (this.state.loader) ? <Loader /> : <></>;
    }

    // Render the ErrorPopup component
    renderError(): Object {
        return (this.state.error) ? <ErrorPopup
            errorTitle={this.state.errorTitle}
            errorText={this.state.errorText}
            action={this.toggleErrorPopup}
        /> : <></>
    }

    // Rendere redirect component
    renderRedirectToHome(): Object {
        return (this.state.redirectToHome === true) ? <Redirect push to={{ pathname: "/" + config.steps[0] }} /> : '';
    }

    // Rendere redirect component
    renderRedirectToStart(): Object {
        return (this.state.redirectToStart === true) ? <Redirect push to={{ pathname: "/" + config.steps[3] }} /> : '';
    }

    // Render the redirect component
    renderRedirectToTips(): Object {
        switch (this.state.tipsView) {
            case "print":
                return (this.state.redirectToTips === true) ? <Redirect push to={{
                    pathname: "/" + config.steps[10] + "/" + this.state.placeId,
                    state: { resiliencyPrintStatus: true }
                }} /> : '';
            case "index":
                return (this.state.redirectToTips === true) ? <Redirect push to={{
                    pathname: "/" + config.steps[10] + "/" + this.state.placeId,
                    state: { resiliencyIndexStatus: true }
                }} /> : '';
            default:
                return <></>;
        }
    }

    // Redirect to tips (resiliency index)
    toggleResiliencyView(status?: boolean) {
        this.setState({
            redirectToTips: true,
            tipsView: "index"
        });
    }

    // Redirect to tips (resiliency print)
    toggleResiliencyPrintView(status?: boolean) {
        this.setState({
            redirectToTips: true,
            tipsView: "print"
        });
    }

    // Chekc if questtionaire is completed
    checkQuestionnaireCompleteStatus(): boolean {
        return this.state.questions.find((currentQuestion: any) => currentQuestion.Answer === undefined || currentQuestion.Answer === null) === undefined;
    }

    // Parse the current url
    parseUrl() {
        const urlPieces = this.urlDecodeArray(window.location.pathname.split("/").filter(item => item !== ''));
        if (
            urlPieces[0] === config.steps[8] &&
            urlPieces[1] !== undefined &&
            isNumber(urlPieces[1])
        ) {
            window.addEventListener("resize", this.setQuestionsSheetStyle);
            switch (urlPieces[2] === undefined) {
                case false:
                    this.setState({
                        device: getDevice(),
                        loader: true,
                        placeId: urlPieces[1]
                    }, () => {
                        this.setQuestionnarie(urlPieces[1], urlPieces[2]);
                    });
                    break;
                case true:
                default:
                    this.setState({
                        device: getDevice(),
                        loader: true,
                        placeId: urlPieces[1]
                    }, () => {
                        this.setQuestionnarie(urlPieces[1], null);
                    });
                    break;
            }
        } else {
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare il questionario"
            });
        }
    }

    // Return the url decode of every string in the array
    urlDecodeArray(urlArray: Array<string>): Array<string> {
        return urlArray.map((urlPart: string) => decodeURI(urlPart));
    }

    // Toggle sidebar status
    toggleSidebar(): void {
        if (this.state.sidebarStatus) {
            this.setState({ sidebarStatus: false });
        } else {
            this.setState({
                sidebarStatus: true,
                menuOverlayStatus: false
            });
        }
    }

    // Set the questionnaire to be looped better
    parseQuestionnaire(questions: any): Array<any> {
        let localQuestions: Array<any> = [];
        questions.Questions.forEach((question: any) => {
            let currentMultipleAnswer: Array<any> = [];
            if (question.MultipleAnswers !== null) {
                currentMultipleAnswer = question.MultipleAnswers.map((currentAnswer: any) => {
                    return {
                        Id: currentAnswer.Id,
                        Name: currentAnswer.Name,
                        checked: false
                    }
                });
                question.ShowMultipleAnswers = false;
            }
            question.MultipleAnswers = currentMultipleAnswer;
            localQuestions[question.Order - 1] = question;
        });
        return localQuestions;
    }

    // Init componet to manage the questionnaire
    initQuestionnaire(hazard: string | null): Promise<any> {
        return new Promise((resolve) => {
            if (hazard === null || !this.checkHazardName(this.state.questions, hazard) || !this.checkHazardDone(hazard)) {
                this.props.history.push("/questionnaire/" + this.state.placeId + "/" + this.state.questions[0].Hazard.Name.toLowerCase());
                this.setState({
                    currentHazard: this.state.questions[0].Hazard.Id,
                    lastHazard: -99,
                    questionId: undefined,
                    currentQuestionId: this.state.questions[0].Id,
                    questionnaireLoaded: true
                }, () => {
                    setUtagView({
                        page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.state.questions[0].Hazard.Name.toLowerCase(),
                        first_level: "questionnaire",
                        second_level: this.state.questions[0].Hazard.Name.toLowerCase(),
                        step: convertHazardToAnalyticsStep(this.state.questions[0].Hazard.Name.toLowerCase())
                    }, true);
                    this.getQuestionReview(this.state.questionsInitial).then((result: any) => { resolve(true) })
                });
            } else {
                this.setState({
                    currentHazard: getHazardFirstQuestionByHazardName(this.state.questions, hazard).Hazard.Id,
                    lastHazard: -99,
                    questionId: undefined,
                    currentQuestion: getHazardFirstQuestionIndex(this.state.questions, hazard),
                    currentQuestionId: getHazardFirstQuestionByHazardName(this.state.questions, hazard).Id,
                    questionnaireLoaded: true,
                    introChecked: true
                }, () => {
                    setUtagView({
                        page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + hazard,
                        first_level: "questionnaire",
                        second_level: hazard,
                        step: convertHazardToAnalyticsStep(hazard)
                    }, true);
                    this.getQuestionReview(this.state.questionsInitial).then((result: any) => { resolve(true) })
                });
            }
        })
    }

    // Handle click on question answer
    handleAnswer(e: React.MouseEvent): void {
        const answerData: Array<any> = e.currentTarget.id.split("-");
        let questionsCopy = [...this.state.questions];
        questionsCopy[answerData[1]].answerSaved = answerData[0];
        this.getQuestionReview(this.state.questionsInitial).then((newCurrentQuestionIndex: any) => {
            switch (answerData[3]) {
                case 'single':
                    this.handleHazardEnd(answerData[3]).then(result => {
                        this.setState({
                            currentQuestion: newCurrentQuestionIndex + 1,
                            currentQuestionId: this.state.questions[newCurrentQuestionIndex + 1] !== undefined ? +this.state.questions[newCurrentQuestionIndex + 1].Id : +this.state.questions[newCurrentQuestionIndex].Id,
                            currentHazard: this.state.questions[newCurrentQuestionIndex + 1] !== undefined ? +this.state.questions[newCurrentQuestionIndex + 1].Hazard.Id : +answerData[2],
                            lastHazard: +answerData[2],
                        }, () => {
                            if (this.state.currentHazard !== this.state.lastHazard) {
                                this.setState({ currentQuestionRelative: 0 })
                            } else {
                                this.setState((prevState: any) => ({ currentQuestionRelative: prevState.currentQuestionRelative + 1 }))
                            }
                            setUtagLink({
                                page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                first_level: "questionnaire",
                                second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                event_name: "card_interaction",
                                ga_eventCategory: "questionnaire",
                                ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - " + this.state.questions[+this.state.currentQuestion - 1].Text,
                                ga_eventLabel: answerData[0]
                            }, true);
                            if (result === 'LAST') {
                                setUtagLink({
                                    page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    first_level: "questionnaire",
                                    second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    event_name: "card_interaction",
                                    ga_eventCategory: "questionnaire",
                                    ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - Risultato quesionario"
                                }, true);
                                setUtagLink({
                                    page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    first_level: "questionnaire",
                                    second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    event_name: "card_interaction",
                                    ga_eventCategory: "questionnaire",
                                    ga_eventaction: "Fine questionario",
                                }, true);
                            }
                            if (result === 'LAST_QUESTION') {
                                setUtagLink({
                                    page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    first_level: "questionnaire",
                                    second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    event_name: "card_interaction",
                                    ga_eventCategory: "questionnaire",
                                    ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - Risultato quesionario"
                                }, true);
                            }
                        });
                    });
                    break;
                case 'multiple':
                    this.handleHazardEnd(answerData[3]).then(result => {
                        let currentAnswerSaved = '';
                        if (this.state.questions[newCurrentQuestionIndex].answerSaved.toLowerCase() === "yes") {
                            currentAnswerSaved = "Y";
                        } else if (this.state.questions[newCurrentQuestionIndex].answerSaved.toLowerCase() === "no") {
                            currentAnswerSaved = "N";
                        }
                        if (this.state.questions[newCurrentQuestionIndex].ShowMultipleAnswersWhen.toLowerCase() === currentAnswerSaved.toLowerCase()) {
                            const questionCopy = [...this.state.questions];
                            questionCopy[newCurrentQuestionIndex].ShowMultipleAnswers = true;
                            this.setState({ questions: questionCopy });
                        } else {
                            this.setState({
                                currentQuestion: newCurrentQuestionIndex + 1,
                                currentQuestionId: this.state.questions[newCurrentQuestionIndex + 1] !== undefined ? +this.state.questions[newCurrentQuestionIndex + 1].Id : +this.state.questions[newCurrentQuestionIndex].Id,
                                currentHazard: this.state.questions[newCurrentQuestionIndex + 1] !== undefined ? +this.state.questions[newCurrentQuestionIndex + 1].Hazard.Id : +answerData[2],
                                lastHazard: +answerData[2]
                            }, () => {
                                this.props.history.push("/questionnaire/" + this.state.placeId + "/" + this.state.questions[newCurrentQuestionIndex].Hazard.Name.toLowerCase());
                                if (this.state.currentHazard !== this.state.lastHazard) {
                                    this.setState({ currentQuestionRelative: 0 })
                                } else {
                                    this.setState((prevState: any) => ({ currentQuestionRelative: prevState.currentQuestionRelative + 1 }))
                                }
                                setUtagLink({
                                    page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    first_level: "questionnaire",
                                    second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                    event_name: "card_interaction",
                                    ga_eventCategory: "questionnaire",
                                    ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - " + this.state.questions[+this.state.currentQuestion - 1].Text,
                                    ga_eventLabel: answerData[0]
                                }, true);
                            });
                        }
                        if (result === 'LAST') {
                            setUtagLink({
                                page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                first_level: "questionnaire",
                                second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                event_name: "card_interaction",
                                ga_eventCategory: "questionnaire",
                                ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - Risultato quesionario"
                            }, true);
                            setUtagLink({
                                page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                first_level: "questionnaire",
                                second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                event_name: "card_interaction",
                                ga_eventCategory: "questionnaire",
                                ga_eventaction: "Fine questionario",
                            }, true);
                        }
                        if (result === 'LAST_QUESTION') {
                            setUtagLink({
                                page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                first_level: "questionnaire",
                                second_level: this.getHazardNameById(this.state.lastHazard).toLowerCase(),
                                event_name: "card_interaction",
                                ga_eventCategory: "questionnaire",
                                ga_eventaction: this.getHazardNameById(this.state.lastHazard) + " - Risultato quesionario"
                            }, true);
                        }
                    });
                    break;
                default:
                    console.error("Error while handling response.");
                    break;
            }
        })
    }

    // Review questions by viewing rules
    getQuestionReview(questions: any): Promise<any> {
        return new Promise((resolve) => {
            this.setState({ questions: this.hideQuestions(questions) }, () => {
                const newCurrentQuestionIndex = this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId);
                this.setState({
                    currentQuestion: newCurrentQuestionIndex,
                    questionId: +this.state.questions[newCurrentQuestionIndex].Id
                });
                resolve(newCurrentQuestionIndex);
            })
        });
    }

    // Get question index by question id
    getQuestionIndexById(questions: any, id: number): number {
        return questions.findIndex((item: any) => item.Id === id)
    }

    // Return a questionnaire with the invalid question hidden
    hideQuestions(questions: any): any {
        return questions.map((question: any) =>
            (question.ShowWhenAnsweredTo === null || (question.ShowWhenAnsweredTo !== null && this.checkQuestionAnswer(question.ShowWhenAnsweredTo, question.ShowWhenAnswered)))
                ? question : null).filter((item: any) => item !== null);
    }

    // Check the question answer for needed requirements
    checkQuestionAnswer(idOfTheAnswerToCheck: number, answerToCheck: string): boolean {
        return this.state.questions.find((currentQuestion: any) => {
            let currentAnswerSaved = ''
            if (currentQuestion.Id === idOfTheAnswerToCheck) {
                switch (true) {
                    case (currentQuestion.answerSaved !== undefined):
                        currentAnswerSaved = currentQuestion.answerSaved.toLowerCase() === "yes" ? "Y" : "N";
                        return currentAnswerSaved === answerToCheck;
                    case (currentQuestion.Answer !== undefined && currentQuestion.Answer !== null && currentQuestion.Answer.MainValue !== undefined):
                        return currentQuestion.Answer.MainValue === answerToCheck;
                    default:
                        return false;
                }
            } else return false;
        }) !== undefined;
    }

    // Handle multi answer click
    handleMultiAnswer(e: React.MouseEvent) {
        const answerData: Array<any> = e.currentTarget.id.split("-");
        const newQuestions = [...this.state.questions];
        newQuestions[answerData[1]].MultipleAnswers[answerData[2]].checked = answerData[0] === 'on' ? false : true;
        this.setState({ questions: newQuestions });
    }

    // Handle the end of an hazard
    handleHazardEnd(typeOfQuestion: string): Promise<any> {
        return new Promise((resolve) => {
            switch (true) {
                case (this.isLastQuestionOfHazard()):
                    this.setState({ loader: true }, () => {
                        const { cookies } = this.props;
                        saveQuestionnaire(this.state.placeId, cookies.get('derris_token'), this.getResponsesArray()).then(result => {
                            if (result.status === 200) {
                                result.json().then((res: any) => {
                                    this.setState({
                                        results: JSON.parse(res).Results,
                                        questionnaireLoaded: false,
                                        partialResultsVisible: true,
                                        hazardDone: this.getHazardDone(JSON.parse(res)),
                                        loader: false
                                    }, () => { resolve("LAST_QUESTION") });
                                });
                            } else if (result.status === 400) {
                                result.json().then((res: any) => {
                                    console.error("Error while saving questionnaire. " + res.Description);
                                    this.setState({
                                        error: true,
                                        errorTitle: "Errore",
                                        errorText: "Impossibile salvare il questionario. " + res.Description
                                    });
                                });
                            }
                        }).catch(error => {
                            console.error("Error while saving questionnaire. " + error);
                            this.setState({
                                error: true,
                                errorTitle: "Errore",
                                errorText: "Impossibile salvare il questionario. " + error
                            });
                        });
                    });
                    break;
                case (this.isLastQuestionOfQuestionnaire()):
                    this.setState({ loader: true }, () => {
                        const { cookies } = this.props;
                        saveQuestionnaire(this.state.placeId, cookies.get('derris_token'), this.getResponsesArray()).then(result => {
                            if (result.status === 200) {
                                result.json().then((res: any) => {
                                    this.setState({
                                        results: JSON.parse(res).Results,
                                        hazardDone: this.getHazardDone(JSON.parse(res)),
                                        questionnaireLoaded: false,
                                        partialResultsVisible: true,
                                        questionnaireDone: true,
                                        loader: false
                                    }, () => { resolve("LAST") });
                                });
                            } else if (result.status === 400) {
                                result.json().then((res: any) => {
                                    console.error("Error while saving questionnaire. " + res.Description);
                                    this.setState({
                                        error: true,
                                        errorTitle: "Errore",
                                        errorText: "Impossibile salvare il questionario. " + res.Description
                                    });
                                });
                            }
                        }).catch(error => {
                            console.error("Error while saving questionnaire. " + error);
                            this.setState({
                                error: true,
                                errorTitle: "Errore",
                                errorText: "Impossibile salvare il questionario. " + error
                            });
                        });
                    });
                    break;
                default:
                    resolve("NORMAL");
                    break;
            }
        });
    }

    // Set multi answer
    setMultiAnswer(e: React.MouseEvent) {
        const answerData: Array<any> = e.currentTarget.id.split("-");
        this.setState({
            currentQuestion: this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId) + 1,
            questionId: +this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId)].Id,
            currentQuestionId: this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId) + 1].Id !== undefined ? +this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId) + 1].Id : +this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId)].Id,
            currentHazard: this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId) + 1] !== undefined ? +this.state.questions[this.getQuestionIndexById(this.state.questions, this.state.currentQuestionId) + 1].Hazard.Id : +answerData[2],
            lastHazard: +answerData[2]
        }, () => {
            if (this.state.currentHazard !== this.state.lastHazard) {
                this.setState({ currentQuestionRelative: 0 })
            } else {
                this.setState((prevState: any) => ({ currentQuestionRelative: prevState.currentQuestionRelative + 1 }))
            }
            setUtagLink({
                page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.getHazardNameById(this.state.currentHazard).toLowerCase(),
                first_level: "questionnaire",
                second_level: this.getHazardNameById(this.state.currentHazard).toLowerCase(),
                event_name: "card_interaction",
                ga_eventCategory: "questionnaire",
                ga_eventaction: this.getHazardNameById(this.state.currentHazard) + " - " + this.state.questions[+this.state.currentQuestion - 1].Text,
                ga_eventLabel: this.state.questions[answerData[1]].answerSaved + (this.getMultipleResponsesTextArray(this.state.questions[answerData[1]].MultipleAnswers).length > 0 ? " - MULTI ANSWERS: " + this.getMultipleResponsesTextArray(this.state.questions[answerData[1]].MultipleAnswers).join(", ") : "")
            }, true);
        });
    }

    // Switch the application to an Hazard (Experimental)
    switchToHazard(hazardId: number, hazardName: string) {
        this.setState({
            currentQuestion: this.getHazardIdFirstQuestionByHazardName(this.state.questions, hazardName),
            currentHazard: hazardId,
            lastHazard: -99,
            currentQuestionRelative: 0,
            questionId: +this.state.questions[+this.getHazardIdFirstQuestionByHazardName(this.state.questions, hazardName)].Id,
            currentQuestionId: +this.state.questions[+this.getHazardIdFirstQuestionByHazardName(this.state.questions, hazardName)].Id,
            questionnaireLoaded: true,
            partialResultsVisible: false,
            introChecked: true
        }, () => {
            this.setQuestionsSheetStyle();
            this.props.history.push("/questionnaire/" + this.state.placeId + "/" + hazardName.toLowerCase());
        });
    }

    // Shift the question sheets
    setQuestionsSheetStyle(): void {
        if (this.state.introChecked) {
            const sheetWidth = this.state.device === 'mobile' ? (this.getWidth() - 40) : 320;
            this.setState({
                sheetStyle: { flex: "0 0 " + sheetWidth + "px" },
                questionSheetStyle: { transform: "translateX(" + ((this.getWidth() / 2) - (sheetWidth / 2) - 20 - (this.state.currentQuestionRelative * (sheetWidth + 40))) + "px)" }
            });
        } else {
            this.setState({ questionSheetStyle: {} });
        }
    }

    // Get the width of the visible area
    getWidth(): number {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    // Go to next hazard
    goToNextHazard() {
        this.setState({ loader: true }, () => {
            setTimeout(() => {
                this.setState({
                    loader: false,
                    questionnaireLoaded: true,
                    partialResultsVisible: false
                })
                this.props.history.push("/questionnaire/" + this.state.placeId + "/" + this.state.questions[this.state.currentQuestion].Hazard.Name.toLowerCase());
                setUtagView({
                    page_url: config.analytics.baseurl + "/questionnaire/" + this.state.placeId + "/" + this.state.questions[this.state.currentQuestion].Hazard.Name.toLowerCase(),
                    first_level: "questionnaire",
                    second_level: this.state.questions[this.state.currentQuestion].Hazard.Name.toLowerCase(),
                    step: convertHazardToAnalyticsStep(this.state.questions[this.state.currentQuestion].Hazard.Name.toLowerCase())
                }, true);
            }, 700);
        });
    }

    // Check if corrent question is the last of the current hazard
    isLastQuestionOfHazard(): boolean {
        return this.state.questions[+this.state.currentQuestion + 1] !== undefined &&
            this.state.questions[+this.state.currentQuestion + 1].Hazard.Id !== this.state.currentHazard ?
            true : false;
    }

    // Check if corrent question is the last of the questionnaire
    isLastQuestionOfQuestionnaire(): boolean {
        return this.state.questions[+this.state.currentQuestion + 1] === undefined ? true : false;
    }

    // Start questionnaire
    startQuestionnaire() {
        this.setState({ introChecked: true }, () => { this.setQuestionsSheetStyle() });
    }

    // Toggle ErrorPopup component
    toggleErrorPopup(): void {
        if (this.state.error === false) {
            this.setState({ error: true });
        } else {
            this.setState({
                error: false,
                redirectToHome: true
            });
        }
    }

    // Start a new questionnaire
    setQuestionnarie(placeId: string, hazard: string | null) {
        const { cookies } = this.props;
        checkQuestionnaire(placeId, cookies.get('derris_token')).then(result => {
            if (result.status === 200) {
                this.localGetQuestionnaire(placeId, cookies.get('derris_token'), false, hazard);
            } else if (result.status === 404) {
                this.localGetQuestionnaire(placeId, cookies.get('derris_token'), true, hazard);
            } else {
                result.json().then((res: any) => {
                    console.error("Error while init questionnaire. " + res.Description);
                    this.setState({
                        error: true,
                        errorTitle: "Errore",
                        errorText: "Impossibile inizializzare il questionario. " + trimString(res.Description)
                    });
                });
            }
        }).catch(error => {
            console.error("Error while init questionnaire. " + error);
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare il questionario. " + trimString(error.toString())
            });
        });
    }

    // Get a new questionnaire
    localGetQuestionnaire(placeId: string, token: string, create: boolean, hazard: string | null): void {
        getQuestionnaire(placeId, token, create).then(result => {
            if (result.status === 200) {
                result.json().then((res: any) => {
                    const { cookies } = this.props;
                    cookies.set('derris_current_province', JSON.parse(res).RelatedTo.Province, { path: '/' });
                    this.setState({
                        results: JSON.parse(res).Results !== undefined ? JSON.parse(res).Results : {},
                        hazardDone: this.getHazardDone(JSON.parse(res)),
                        currentProvince: JSON.parse(res).RelatedTo.Province,
                        placeInfo: JSON.parse(res).RelatedTo
                    }, () => {
                        this.setState({
                            questions: this.parseQuestionnaire(JSON.parse(res)),
                            questionsInitial: this.parseQuestionnaire(JSON.parse(res))
                        }, () => {
                            this.initQuestionnaire(hazard).then((result: any) => {
                                this.setState({ loader: false });
                            });
                        });
                    })
                });
            } else {
                result.json().then((res: any) => {
                    console.error("Error while init questionnaire. " + res.Description);
                    this.setState({
                        error: true,
                        errorTitle: "Errore",
                        errorText: "Impossibile inizializzare il questionario. " + trimString(res.Description)
                    });
                });
            }
        }).catch(error => {
            console.error("Error while init questionnaire. " + error);
            this.setState({
                error: true,
                errorTitle: "Errore",
                errorText: "Impossibile inizializzare il questionario. " + trimString(error.toString())
            });
        });
    }

    // Get hazard name by id 
    getHazardNameById(hazardId: number): string {
        return this.state.questions.find((question: any) =>
            question.Hazard.Id === hazardId).Hazard.Name;
    }

    // Return the id of the first question with an hazard searching by string
    getHazardIdFirstQuestionByHazardName(questions: any, hazard: string): any {
        return questions.findIndex((question: any) =>
            question.Hazard.Name.toLowerCase() === hazard.toLowerCase());
    }

    // Check if a string contains a valid hazard name
    checkHazardName(questions: any, hazard: string): boolean {
        return (questions.find((question: any) =>
            question.Hazard.Name.toLowerCase() === hazard.toLowerCase()) !== undefined);
    }

    // Check if an Hazard is done
    checkHazardDone(hazard: string) {
        return (this.state.hazardDone.find((currentHazard: any) =>
            currentHazard.name.toLowerCase() === hazard.toLowerCase()) !== undefined);
    }

    // Sort the Hazard by the property "Order"
    sortQuestionnaire(questions: any): Array<any> {
        let questionsSorted: Array<Object> = new Array(questions.length);
        questions.forEach((hazard: any) => {
            questionsSorted[hazard[0].Hazard.Order - 1] = hazard
        });
        return questionsSorted;
    }

    // Build array with a list of hazard done
    getHazardDone(questionnaire: any): Array<any> {
        return (
            questionnaire.Results === undefined ||
            questionnaire.Results.SuggestionsByPeril === undefined ||
            questionnaire.Results.SuggestionsByPeril === null ||
            questionnaire.Results.SuggestionsByPeril.length === 0
        ) ? [] : questionnaire.Results.SuggestionsByPeril.map((suggestion: any) => (
            { id: suggestion.Id, name: suggestion.Name.toLowerCase() }
        ));
    }

    // Build responses array
    getResponsesArray(): Array<any> {
        return this.state.questions.map((question: any) => {
            switch (true) {
                case (question.answerSaved !== undefined && question.MultipleAnswers.length > 0):
                    return {
                        QuestionId: question.Id,
                        MainValue: question.answerSaved === "yes" ? "Y" : "N",
                        SecondaryValue: this.getMultipleResponsesArray(question.MultipleAnswers)
                    };
                case (question.answerSaved !== undefined):
                    return {
                        QuestionId: question.Id,
                        MainValue: question.answerSaved === "yes" ? "Y" : "N"
                    };
                default:
                    return null;
            }
        }).filter(item => item !== null);
    }

    // Build multiple responses array
    getMultipleResponsesArray(currentResponses: Array<any>): Array<any> {
        return currentResponses.map((response: any) =>
            response.checked === true ? response.Id.toString() : null
        ).filter(item => item !== null);
    }

    // Build multiple responses text array
    getMultipleResponsesTextArray(currentResponses: Array<any>): Array<any> {
        return currentResponses.map((response: any) =>
            response.checked === true ? response.Name : null
        ).filter(item => item !== null);
    }

    // Toggle overlay
    toggleMenuOverlay(): void {
        this.toggleMenuOverlayStatus(false);
    }

    // Toggle overlay status
    toggleMenuOverlayStatus(status: boolean): void {
        if (this.state.menuOverlayStatus) {
            this.setState({ menuOverlayStatus: status });
        } else {
            this.setState({ menuOverlayStatus: status });
        }
    }
}