import { useEffect, useState } from "react";
import "./Privacy.css";

const LegalModal = ({ handleClose, show, children, type }: any) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const [state, setState] = useState(type);

  useEffect(() => {
    setState(type);
  }, [type]);

  function cookies() {
    return (
      <>
        <span id="cookies-start"></span>
        <div className="privacy__msg-container">
          In questa pagina si descrivono le modalità di gestione del Sito Web
          con riferimento all'utilizzo dei Cookie e ad altri strumenti di
          tracciamento, nonchè al relativo trattamento dei dati personali degli
          Utenti che lo consultano. <br />
          <br />
          Consulta la{" "}
          <a
            href="https://www.derris.eu/informativa-privacy/"
            target="_blank"
            rel="noreferrer">
            {" "}
            <b>Privacy Policy</b>
          </a>{" "}
          del nostro Sito Web, per conoscere il Titolare del trattamento dei
          dati personali, le finalità, modalità e basi giuridiche del
          trattamento, le categorie di dati personali coinvolti, le eventuali
          categorie di destinatari delle comunicazioni dei dati personali, i
          termini di conservazione, nonchè i diritti degli Utenti in qualità di
          soggetti interessati.
          <br />
          <br />
          <b>Cosa sono i Cookie e gli altri strumenti di tracciamento</b>
          <br />
          <br />
          I Cookie sono piccole stringhe di testo - le quali possono includere
          dati personali (ad es. indirizzo IP, nome Utente, indirizzo e-mail) ed
          informazioni tecniche (ad es. impostazioni sulla lingua, tipo di
          dispositivo) - che i siti visitati inviano al terminale dell'Utente
          (Personal Computer o dispositivo mobile come ad es. tablet o
          smartphone), dove vengono memorizzati (ad es. all'interno della
          memoria del browser), per poi essere ritrasmessi agli stessi siti alla
          visita successiva. I Cookie sono utilizzati per svolgere funzioni,
          quali ad esempio le autenticazioni informatiche, il monitoraggio di
          sessioni e la memorizzazione di informazioni sui siti (senza
          l'utilizzo dei Cookie alcune operazioni risulterebbero molto complesse
          o impossibili da eseguire), anche con riferimento all'individuazione
          delle preferenze in base all'esperienza di navigazione. Nel corso
          della navigazione su un Sito Web, l'Utente può ricevere sul suo
          terminale anche Cookie che sono gestiti ed inviati da siti o da web
          server diversi (c.d. "terze parti"), sui quali possono risiedere
          alcuni elementi (quali, ad esempio, immagini, mappe, suoni, specifici
          link a pagine di altri domini) presenti sul Sito Web che lo stesso sta
          visitando.
          <br />
          <br />
          Esistono anche altri strumenti di tracciamento (ad es. i c.d.
          “Fingerprinting”, “Pixel Tag”, “Plug-in”) che pur utilizzando una
          tecnologia differente, consentono di effettuare trattamenti analoghi a
          quelli svolti per il tramite dei Cookie e raggiungere i medesimi
          risultati. Tali strumenti, a differenza dei Cookie, non archiviano
          informazioni all'interno del dispositivo dell'Utente e non sono
          rimovibili direttamente dall'Utente medesimo tramite le impostazioni
          del browser; è quindi necessario che l'Utente si avvalga della facoltà
          di revocare l'autorizzazione al loro utilizzo tramite le funzionalità
          messe a disposizione sul Sito Web dal Titolare per rivedere le proprie
          scelte.
          <br />
          <br />
          <b>Possibili classificazioni degli altri strumenti di tracciamento</b>
          <br />
          <br />
          In linea generale è possibile classificare tali strumenti per:
          <br />
          <br />
          <b>- Tipologia dello strumento </b>
          <ul>
            <li> Cookie (identificatori attivi) </li>
            <li> Altri strumenti di tracciamento (identificatori passivi)</li>
          </ul>
          <br />
          <b>- Provenienza</b>
          <ul>
            <li>
              Prima parte (o “Publisher”, ovvero il gestore del Sito Web che
              l'Utente sta visitando)
            </li>
            <li>
              Terza parte (gestore di un sito web diverso che installa i Cookie
              per il tramite del Sito Web della Prima Parte)
            </li>
          </ul>
          <br />
          <b>- Durata </b>
          <ul>
            <li>
              Sessione (temporanei, sono eliminati alla chiusura del browser o,
              nei casi previsti, all'esecuzione del comando di logout)
            </li>
            <li>
              Permanenti (rimangono attivi fino alla loro data di scadenza o
              alla loro cancellazione da parte dell'Utente)
            </li>
          </ul>
          <br />
          <b>- Finalità perseguite </b>
          <ul>
            <li>Finalità tecniche (relative al funzionamento del Sito Web)</li>
            <li>
              Finalità non tecniche (quale il profilo della navigazione Utente)
            </li>
          </ul>
          <span className="bold">I Cookie utilizzati nel nostro Sito Web </span>
          <br />
          <br />
          <span className="bold">COOKIE TECNICI</span>
          <br />
          <br />
          I Cookie Tecnici permettono un agevole utilizzo del Sito Web ed un più
          facile reperimento delle informazioni, semplificando la connessione e
          la trasmissione di dati tra l'Utente e il Sito Web. Utilizziamo nel
          nostro Sito Web Cookie Tecnici di navigazione, i quali sono
          strettamente necessari in quanto garantiscono la normale navigazione
          ed il corretto funzionamento del Sito Web (ad es. l'autenticazione per
          l'accesso ad aree riservate ; per distribuire il traffico di dati in
          ingresso sul sito sui diversi server, al fine di ottimizzare i tempi
          di risposta), permettendo di usufruire dei servizi offerti (ad es. il
          calcolo di un preventivo ). Senza l'uso di tali Cookie alcuni servizi
          del Sito Web potrebbero non essere accessibili, visualizzati
          correttamente o funzionare in maniera non ottimale.
          <br />
          <br />
          Alla categoria dei Cookie Tecnici appartengono Cookie di sessione e
          persistenti.
          <br />
          <br />
          Per l'installazione dei Cookie Tecnici non è richiesto il preventivo
          consenso dell'Utente.
          <br />
          <br />
          Ti informiamo inoltre che, in ogni momento, puoi bloccare i Cookie
          attraverso le impostazioni del browser; tuttavia, se imposterai il tuo
          dispositivo in modo da rifiutare questi Cookie, alcuni servizi del
          Sito Web potrebbero non essere visualizzati correttamente o funzionare
          in maniera non ottimale, in particolare, le operazioni che consentono
          di identificare l'Utente e mantenerne l'identificazione nell'ambito
          della sessione potrebbero essere più complesse da svolgere e meno
          sicure in assenza di Cookie Tecnici.
          <br />
          <br />
          <span className="bold">COOKIE ANALYTICS</span>
          <br />
          <br />
          Utilizziamo i <b>COOKIE ANALYTICS</b> - cioè quei Cookie che sono
          installati, tra i possibili utilizzi, al fine di valutare l'efficacia
          di un servizio, la misurazione del traffico (determinazione del numero
          di visitatori eventualmente ripartiti per area geografica o in base ad
          altre caratteristiche) del Sito Web o delle sue aree maggiormente
          visitate (a scopi statistici), di migliorarne i contenuti o
          facilitarne l'utilizzo da parte dell'Utente, di svolgere analisi
          anonime “cross-device” al fine di offrire all'Utente un'esperienza
          coerente sui diversi dispositivi utilizzati , di svolgere analisi per
          facilitare l'utilizzo del Sito Web da parte dell'Utente, anche in
          un'ottica di user experience.
          <br />
          <br />
          Tali Cookie, considerando che nel loro impiego non si perviene alla
          diretta individuazione dell'interessato (stante che le informazioni
          raccolte da tali cookie sono utilizzate in modo aggregato e quindi
          anonimo), sono equiparabili alla tipologia di Cookie Tecnici e quindi
          non necessitano della preventiva acquisizione del consenso.
          <br />
          <br />
          Ti informiamo inoltre che tali cookie sono gestiti da Terze Parti e
          che si tratta di Cookie persistenti, in quanto rimangono attivi fino
          alla loro data di o alla loro cancellazione da parte dell'Utente.
          <br />
          <br />
          <span className="bold">
            TRASFERIMENTO DEI DATI PERSONALI IN PAESI NON APPARTENENTI
            ALL'UNIONE EUROPEA
          </span>
          <br />
          <br />
          Alcune delle Terze Parti che forniscono servizi per il tramite dei
          Cookie utilizzati nel nostro Sito Web, i quali sono stati
          preventivamente autorizzati, o ulteriori soggetti terzi coinvolti
          dalle citate Terze Parti nella fornitura dei suddetti servizi,
          potrebbero eventualmente ricevere ed elaborare i tuoi dati personali
          in Paesi Terzi (Paesi non appartenenti all'Unione Europea o allo
          Spazio Economico Europeo).
          <br />
          <br />
          Qualora previsto, in ragione della finalità del trattamento
          perseguita, il trasferimento dei dati personali in Paesi Terzi avverrà
          in base a quanto previsto dalla vigente normativa applicabile in
          materia, ed in presenza di idonea misura di garanzia (ad es., fra le
          altre, in ragione di una decisione di adeguatezza adottata dalla
          Commissione Europea, ovvero previa stipula delle c.d. “Clausole
          Contrattuali Standard”, tra l'importatore e l'esportatore dei dati).
          <br />
          Si informa altresì che I dati personali raccolti tramite l'utilizzo di
          Cookie non saranno diffusi.
          <br />
          <br />
          <span className="bold">BASE GIURIDICA DEL TRATTAMENTO DEI DATI</span>
          <br />
          <br />
          La base giuridica su cui si fonda il trattamento dei dati raccolti a
          cura del nostro Sito Web tramite l'utilizzo dei Cookie con funzione
          tecnica, è rintracciabile nel legittimo interesse della Società. Si
          informa altresì che I dati personali raccolti tramite l'utilizzo di
          Cookie non saranno diffusi.
          <br />
          <br />
          <span className="bold">DESTINATARI DELLE COMUNICAZIONI</span>
          <br />
          <br />
          Le informazioni memorizzate nei Cookie sono utilizzate esclusivamente
          dalla nostra Società Unipol Gruppo S.p.A.
          <br />
          <br />
          <span className="bold">
            ELENCO DEI COOKIE UTILIZZATI DAL NOSTRO SITO WEB
          </span>
          <br />
          <br />
          <div style={{ overflowX: "auto" }}>
            <table>
              <tr>
                <td>Tipologia di appartenenza</td>
                <td>Finalità specifica</td>
                <td>Provenienza</td>
                <td>Denominazione</td>
                <td>Durata</td>
              </tr>
              <tr>
                <td>Tecnico</td>
                <td>
                  Tecnico Il cookie contiene una serie di informazioni
                  (timestamp di inizio e di scadenza sessione, identificatore
                  univoco anonimo, informazioni sul numero di pagine visitate
                  nella sessione corrente, informazioni sul numero di sessioni
                  effettuate dall'utente corrente) utilizzate dalla piattaforma
                  Tealium IQ.
                </td>
                <td>Prima Parte</td>
                <td>utag_main</td>
                <td>1 anno</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <a href="https://tealium.com/products/tealium-iq-tag-management-system/tealium-iq-and-data-privacy/">
                    https://tealium.com/products/tealium-iq-tag-management-system/tealium-iq-and-data-privacy/
                  </a>
                </td>
              </tr>

              <tr>
                <td>Analitycs</td>
                <td>
                  Il cookie _gid è un cookie adibito all'identificazione della
                  sessione.
                </td>
                <td>Terza parte</td>
                <td>_gid</td>
                <td>24 ore</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <a href="https://marketingplatform.google.com/about/analytics/terms/it/">
                    https://marketingplatform.google.com/about/analytics/terms/it/
                  </a>
                  <a href="https://policies.google.com/privacy?hl=it">
                    https://policies.google.com/privacy?hl=it
                  </a>
                </td>
              </tr>

              <tr>
                <td>Analitycs</td>
                <td>
                  Analitycs Il cookie _ga è un cookie che permette di numerare
                  le visite del sito e quindi conoscere l'efficacia dello
                  stesso.
                </td>
                <td>Terza parte</td>
                <td>_ga</td>
                <td>2 anni</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <a href="https://marketingplatform.google.com/about/analytics/terms/it/">
                    https://marketingplatform.google.com/about/analytics/terms/it/
                  </a>
                  <a href="https://policies.google.com/privacy?hl=it">
                    https://policies.google.com/privacy?hl=it
                  </a>
                </td>
              </tr>
              <tr>
                <td>Analitycs</td>
                <td>
                  Il cookie _gat_gtag_# è un cookie utilizzato per limitare le
                  richieste.
                </td>
                <td>Terza parte</td>
                <td>_gat_gtag_#</td>
                <td>1 minuto</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <a href="https://marketingplatform.google.com/about/analytics/terms/it/">
                    https://marketingplatform.google.com/about/analytics/terms/it/
                  </a>
                  <a href="https://policies.google.com/privacy?hl=it">
                    https://policies.google.com/privacy?hl=it
                  </a>
                </td>
              </tr>

              <tr>
                <td>Tecnico</td>
                <td>
                  Il cookie derris_privacy_check viene utilizzato per
                  determinare se è stato selezionato il consenso privacy prima
                  di accedere all'applicazione
                </td>
                <td>Prima parte</td>
                <td>derris_privacy_check</td>
                <td>Sessione</td>
              </tr>
            </table>
          </div>
          <br />
          <br />
          <span className="bold">I COOKIE E LE IMPOSTAZIONI DEL BROWSER</span>
          <br />
          <br />
          In questa sezione trovi le informazioni per disattivare i Cookie sul
          tuo browser.
          <br />
          <br />
          Ti ricordiamo che disattivando i Cookie alcune parti del Sito Web
          potrebbero non funzionare correttamente. Se il tuo browser non si
          trova nell'elenco sotto riportato, ti preghiamo di consultare le
          istruzioni riportate sul tuo browser in merito alla gestione dei
          Cookie.
          <br />
          <br />
          <span className="bold">Internet Explorer versione 6 o superiore</span>
          <ol>
            <li>Seleziona "Strumenti" nella barra del tuo browser</li>
            <li>Seleziona "Opzioni Internet"</li>
            <li>Seleziona la voce "Privacy" e poi clicca su "Avanzate"</li>
            <li>Seleziona "Sostituisci gestione automatica cookie"</li>
            <li>
              Disattiva i "Cookie dei Siti Web visualizzati" selezionando la
              voce "Blocca"
            </li>
            <li>
              Disattiva i "Cookie di terze parti" selezionando la voce "Blocca"
            </li>
            <li>
              Disattiva i "Cookie di sessione" deselezionando la voce "Accetta
              sempre i cookie della sessione"
            </li>
            <li>Clicca su "OK"</li>
          </ol>
          <span className="bold">Firefox versione 9 o superiore</span>
          <ol>
            <li>Seleziona "Strumenti" nella barra del tuo browser</li>
            <li>Seleziona "Opzioni"</li>
            <li>Selezione la voce "Privacy"</li>
            <li>
              Nell'area "Cronologia" scegli dal menù a tendina l'opzione
              "utilizza impostazioni personalizzate"
            </li>
            <li>
              Disattiva i cookies deselezionando la voce "Accetta i cookie dai
              siti"
            </li>
            <li>Clicca su "OK"</li>
          </ol>
          <span className="bold">Google Chrome versione 24 o superiore</span>
          <ol>
            <li>Seleziona "Menù Chrome" nella barra del tuo browser</li>
            <li>Selezione "impostazioni"</li>
            <li>Seleziona "Mostra impostazione avanzate"</li>
            <li>Nella sezione "Privacy" clicca su "Impostazione contenuti"</li>
            <li>
              Disattiva tutti i cookies selezionando "Impedisci ai siti di
              impostare dati" Blocca cookie di terze parti e dati dei siti"
            </li>
            <li>Clicca su "OK"</li>
          </ol>
        </div>
      </>
    );
  }

  function policy() {
    return (
      <>
        <div className="privacy__msg-container">
          <span id="privacy-policy-index"></span>
          <p>
            <a href="#privacy-policy-premessa">Premessa</a>
          </p>
          <p>
            <a href="#privacy-policy-definition">Definizioni</a>
          </p>
          <p>
            <a href="#privacy-policy-informativa">
              Informativa sul trattamento dei dati personali dell'Utente
            </a>
          </p>
          <p>
            <a href="#privacy-policy-cookies">Cookies</a>
          </p>
          <p>
            <a href="#privacy-policy-diritti">
              Diritti dell'Utente Interessato
            </a>
          </p>
          <p>
            <a href="#privacy-policy-esercitare">
              Come esercitare i diritti e/o richiedere informazioni sul
              trattamento
            </a>
          </p>
          <span
            id="privacy-policy-premessa"
            className="bold">
            PREMESSA
          </span>
          <hr />
          <br />
          <br />
          Gentile Utente, la presente Privacy Policy ti viene fornita ai sensi
          dell'art. 13 del Regolamento 2016/679/UE - relativo alla protezione
          delle persone fisiche con riguardo al trattamento dei dati personali,
          nonché alla libera circolazione di tali dati (di seguito, anche, “il
          Regolamento” o “GDPR”). All'interno della presente Privacy Policy
          troverai le informazioni relative al trattamento dei tuoi dati
          personali, conseguenti alla navigazione all'interno degli spazi web ed
          alla fruizione dei servizi messi a tua disposizione per il tramite del
          sito internet. Ti verranno fornite informative specifiche e/o
          integrative sul trattamento dei tuoi dati personali in ogni occasione
          in cui li raccoglieremo, nella tua interazione con il sito o in virtù
          di rapporti contrattuali instaurati con la nostra Società; puoi
          consultarle tutte in ogni momento cliccando sui link presenti
          nell'apposita sezione “Informative” in fondo a questa pagina.
          Attenzione: la presente Privacy Policy non riguarda i servizi web
          forniti da terze parti, eventualmente da te fruiti o consultati e
          raggiunti tramite collegamento ipertestuale (“link”). Al riguardo, ti
          invitiamo a consultare le informative privacy e le privacy policy
          fornite dalle suddette terze parti nelle apposite sedi.
          <br />
          <br />
          <span
            id="privacy-policy-definition"
            className="bold">
            DEFINIZIONI
          </span>
          <hr />
          <p>
            <span className="bold">Normativa Privacy: </span>Il GDPR, il Codice
            Privacy, i Provvedimenti del Garante e in generale tutta la
            normativa in materia di protezione delle persone fisiche con
            riguardo al trattamento di Dati Personali.
          </p>
          <p>
            <span className="bold">GDPR o Regolamento: </span>Regolamento
            dell'Unione Europea 2016/679 del 27 aprile 2016 relativo alla
            protezione delle persone fisiche con riguardo al trattamento dei
            dati personali (Regolamento Generale sulla Protezione dei Dati)
          </p>
          <p>
            <span className="bold">Dato Personale: </span>Qualsiasi informazione
            riguardante una persona fisica identificata o identificabile. Sono
            da intendere tali, oltre i dati forniti dall'Utente per il tramite
            di eventuali moduli o form all'interno delle singole aree dei
            Servizi Web, anche i dati relativi alla sua navigazione
          </p>
          <p>
            <span className="bold">Interessato o soggetto interessato: </span>La
            persona fisica identificata o identificabile cui si riferiscono i
            Dati Personali.
          </p>
          <p>
            <span className="bold">Dati di navigazione: </span>I sistemi
            informatici e le procedure software preposte al funzionamento dei
            Servizi Web acquisiscono, nel corso del loro normale funzionamento,
            alcuni dati la cui trasmissione è implicita nell'uso dei protocolli
            di comunicazione di Internet. Si tratta di informazioni che non sono
            raccolte per essere associate a interessati identificati, ma che per
            loro stessa natura potrebbero permettere, attraverso elaborazioni ed
            associazioni con dati detenuti da terzi, di identificare gli utenti.
            Tuttavia, se la sessione di navigazione avviene previo accesso
            all'Area Riservata (c.d. log in), i dati raccolti sono associati
            all'account personale dell'Utente.
          </p>
          <span>I dati di navigazione comprendono: </span>
          <p>
            <ul>
              <li>
                gli indirizzi IP o i nomi a dominio dei computer utilizzati
                dagli utenti che si connettono al sito;
              </li>
              <li>
                gli indirizzi in notazione URI (
                <em>Uniform Resource Identifier</em>) delle risorse richieste;
              </li>
              <li>l'orario della richiesta;</li>
              <li>
                il metodo utilizzato nel sottoporre la richiesta al server;
              </li>
              <li>la dimensione del file ottenuto in risposta;</li>
              <li>
                il codice numerico indicante lo stato della risposta data dal
                server (buon fine, errore, ecc.);
              </li>
              <li>
                altri parametri relativi al sistema operativo e all'ambiente
                informatico dell'Utente.
              </li>
            </ul>
          </p>
          <p>
            <span className="bold">Dati forniti dall'Utente: </span>
            Sono i dati che l'Utente trasmette volontariamente e consapevolmente
            tramite l'invio di comunicazioni (ad es., attraverso la posta
            elettronica, agli indirizzi presenti all'interno del dominio web) o
            attraverso la compilazione di appositi form, qualora presenti
            all'interno degli spazi forniti dai Servizi.
          </p>
          <p>
            I Dati forniti dall'Utente sono solo quelli strettamente necessari
            alle finalità di volta in volta perseguite dai Servizi (per
            indicazioni puntuali riguardanti le categorie di dati di volta in
            volta raccolti, si rinvia alle singole informative privacy di
            riferimento). In via esemplificativa, possono essere tali i dati:
          </p>
          <ul>
            <li>anagrafici;</li>
            <li>
              riguardanti gli estremi di contatto (ad es., indirizzo di posta
              elettronica);
            </li>
            <li>riferibili alla posizione contrattuale dell'Utente-Cliente;</li>
            <li>
              di geolocalizzazione (qualora l'Utente abbia espresso un consenso
              alla raccolta dei dati relativi alla sua ubicazione);
            </li>
            <li>
              riguardanti la fruizione dei singoli Servizi messi a disposizione
              dell'Utente;
            </li>
            <li>
              riguardanti fatti e vicende esposte dall'Utente all'interno dei
              propri messaggi
              <em>
                (al riguardo, e per una sua maggior tutela, si invita l'Utente a
                non fornire informazioni non strettamente pertinenti con
                l'oggetto della richiesta e la natura dei Servizi forniti dalla
                Società)
              </em>
              .
            </li>
          </ul>
          <p>
            <span className="bold">
              Titolare del trattamento o il Titolare:
            </span>
            Il soggetto che decide in ordine a finalità e modalità del
            trattamento dei Dati Personali. In riferimento ai Servizi Web, è la
            Società del Gruppo Unipol a cui si riferisce questo sito e della
            quale trovi i riferimenti in calce ad ogni pagina.
          </p>
          <p>
            <span className="bold">Servizi o Servizi Web: </span>I servizi
            erogati tramite la rete internet, fruiti tramite il sito web e/o
            eventuali APP.
          </p>
          <p>
            <span className="bold">Utente: </span>Il soggetto interessato
            (persona fisica) che naviga, consulta, accede o fruisce dei Servizi
            Web.
          </p>
          <p>
            <span className="bold">DPO: </span>Il Data Protection Officer o
            Responsabile per la protezione dei dati. L'Utente interessato può
            richiedere chiarimenti in merito al trattamento dei Dati Personali o
            esercitare i propri diritti rivolgendosi al DPO, nei modi e nelle
            forme indicate nella sezione “Come esercitare i diritti e/o
            richiedere informazioni sul trattamento”.
          </p>
          <p>
            <span className="bold">Garante Privacy: </span>Il Garante per la
            protezione dei dati personali, ovvero l'Autorità di Controllo
            nazionale italiana in materia di protezione dei dati personali.
            Consulta il sito web del{" "}
            <a
              href="https://www.garanteprivacy.it/"
              target="_blank"
              rel="noreferrer">
              Garante Privacy.
            </a>
          </p>
          <p>
            <span className="bold">Cookies: </span> I cookie sono informazioni
            registrate sul tuo dispositivo (ad es., all'interno della memoria
            del tuo browser) quando visiti un sito web o utilizzi
            un'applicazione web
          </p>
          <p>
            Ogni cookie può contenere diversi dati, come, ad esempio, il nome
            del server da cui proviene, un identificatore numerico, ecc.
            <br />
            Consulta la{" "}
            <span
              className="link"
              onClick={() => {
                setState(0);
              }}>
              <a href="#cookies-start">Cookie Policy</a>
            </span>{" "}
            per maggiori informazioni.
          </p>
          <span
            id="privacy-policy-informativa"
            className="bold">
            INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DELL'UTENTE
          </span>
          <hr />
          <p>
            Ti forniamo di seguito le informazioni utili riguardanti i
            trattamenti dei Dati Personali svolti per il tramite dei Servizi
            Web. In particolare, vogliamo informarti:
          </p>
          <ul>
            <li>
              degli estremi di identificazione e di contatto del Titolare del
              trattamento;
            </li>
            <li>
              degli estremi di contatto del Responsabile per la protezione dei
              dati (DPO);
            </li>
            <li>
              delle categorie di Dati Personali trattati attraverso i Servizi
              Web;
            </li>
            <li>
              delle finalità per cui tali Dati Personali sono di volta in volta
              trattati;
            </li>
            <li>
              dei presupposti che legittimano il trattamento dei suddetti dati
              (cosiddette basi giuridiche);
            </li>
            <li>
              della durata della loro conservazione, sempre strettamente
              necessario al perseguimento delle finalità dichiarate;
            </li>
            <li>
              delle categorie di destinatari della comunicazione dei dati.
            </li>
          </ul>
          <table>
            <tr>
              <td>
                <span className="bold">Titolare del trattamento</span>
              </td>
              <td>
                <span className="bold">Sede legale</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Unipol Gruppo S.p.A.</span>
              </td>
              <td>
                <span>Via Stalingrado 45 - 40128 Bologna (BO)</span>
              </td>
            </tr>
          </table>
          <p className="bold">
            Categorie di Dati Personali, finalità e basi giuridiche del
            trattamento e termini di conservazione
          </p>
          <table>
            <tr>
              <td>
                <span className="bold">Categorie di Dati Personali</span>
              </td>
              <td>
                <span className="bold">Finalità del trattamento</span>
              </td>
              <td>
                <span className="bold">Basi giuridiche</span>
              </td>
              <td>
                <span className="bold">Termini di conservazione dei dati</span>
              </td>
            </tr>
            <tr>
              <td rowSpan={3}>Dati di navigazione</td>
              <td>Consentire la navigazione web e l'erogazione dei Servizi</td>
              <td>
                Necessità di dare esecuzione ad un contratto di cui
                l'interessato è parte o per erogare un servizio a richiesta
                dello stesso
              </td>
              <td>Per la durata della navigazione all'interno dei servizi</td>
            </tr>
            <tr>
              <td>
                Ricavare informazioni statistiche anonime sull'uso dei Servizi
                Web, al solo fine di controllarne il corretto funzionamento
              </td>
              <td>Legittimo interesse della Società</td>
              <td>
                I dati raccolti sono aggregati e resi non più riconducibili alla
                singola utenza che ha effettuato la navigazione
              </td>
            </tr>
            <tr>
              <td>
                Per garantire la sicurezza ed il corretto funzionamento dei
                Servizi Web, nonché per l'accertamento di responsabilità, in
                caso di ipotetici reati, ed al fine di tutelare conseguentemente
                i nostri diritti
              </td>
              <td>Legittimo interesse della Società</td>
              <td>
                14 gg e successivamente per il tempo strettamente necessario
                all'eventuale svolgimento delle indagini, alla definizione degli
                eventuali contenziosi e, in generale, alla tutela dei nostri
                diritti
              </td>
            </tr>
            <tr>
              <td>Dati forniti dall'Utente: erogazione dei Servizi Web </td>
              <td>
                Registrazione Area Riservata e funzionalità connesse
                all'erogazione dei relativi Servizi Web
              </td>
              <td>
                Necessità di dare esecuzione ad un contratto di cui
                l'Interessato è parte
              </td>
              <td>
                Per il tempo di attivazione dell'account. Nel caso di Servizi
                connessi ad una posizione contrattuale dell'Utente, i Dati
                Personali potranno essere conservati per tempi ulteriori per
                esigenze amministrativo-contabili e sulla base di quanto
                previsto dalle normative di volta in volta applicabili (in
                genere, 10 anni)
              </td>
            </tr>
          </table>
          <p>
            Il conferimento dei tuoi Dati Personali è libero e facoltativo. Ti
            ricordiamo, tuttavia, che, per il perseguimento di talune finalità
            (per fornirti gli opportuni riscontri richiesti, per l'iscrizione
            all'Area Riservata o per l'erogazione di singoli Servizi) è
            indispensabile; qualora non conferiti, in tali casi, potrebbe non
            essere possibile procedere col perseguimento delle suddette
            finalità.
          </p>
          <br />
          <p>
            Ti invitiamo, comunque, a consultare le singole informative sul
            trattamento dei dati per maggiori dettagli.
          </p>
          <br />
          <br />
          <span className="bold">
            Modalità del trattamento e destinatari della comunicazione dei dati
          </span>
          <p>
            I dati di cui sopra non saranno soggetti a diffusione e potranno
            essere conosciuti da collaboratori della nostra Società
            specificatamente autorizzati a trattarli. Potranno inoltre essere
            acquisiti e/o trattati da altre società del Gruppo Unipol.
            Operazioni di trattamento potranno essere svolte da soggetti esterni
            cui affidiamo lo svolgimento di attività per nostro conto, e con cui
            stipuliamo appositi accordi finalizzati a disciplinare il
            trattamento dei dati. I dati potranno, infine, essere comunicati
            dietro espressa richiesta ad autorità pubbliche o forze dell'ordine.
            Il trattamento dei Dati Personali avviene sempre previa adozione di
            misure di sicurezza idonee a garantire la riservatezza, la
            disponibilità e l'integrità dei dati stessi.
          </p>
          <br />
          <br />
          <span
            id="privacy-policy-cookies"
            className="bold">
            COOKIES
          </span>
          <hr />
          <p>
            I Servizi Web possono utilizzare cookie tecnici, analitici e di
            profilazione, sia di prima che di terze parti. I cookie sono
            indispensabili al miglioramento dei Servizi e per fornire prodotti
            sempre in linea con le preferenze degli Utenti. L'eventuale utilizzo
            di cookie di profilazione e/o di terze parti sarà sempre subordinato
            al rilascio del tuo preventivo consenso. Per saperne di più,{" "}
            <span
              className="link"
              onClick={() => {
                setState(0);
              }}>
              <a href="#cookies-start">clicca qui.</a>
            </span>
          </p>
          <br />
          <br />
          <span
            id="privacy-policy-diritti"
            className="bold">
            DIRITTI DELL'UTENTE (IN QUALITÀ DI INTERESSATO)
          </span>
          <hr />
          <p>
            La Normativa Privacy (artt. 15-22 del Regolamento) garantisce
            all'Utente, in qualità di Interessato, il diritto di accedere ai
            dati che lo riguardano, nonché di ottenerne la rettifica e/o
            l'integrazione, la cancellazione o la portabilità. La Normativa
            Privacy attribuisce altresì all'Utente il diritto di richiedere la
            limitazione del trattamento dei dati e di opporsi al trattamento,
            oltre la possibilità di revocare il consenso eventualmente prestato
            (la revoca non pregiudica la liceità del trattamento svolto sino a
            quel momento).
          </p>
          <br />
          <br />
          <br />
          <table>
            <tr>
              <td>
                <span className="bold">Diritti</span>
              </td>
              <td>
                <span className="bold">In cosa consiste?</span>
              </td>
              <td>
                <span className="bold">Presupposti per l'esercizio</span>
              </td>
            </tr>
            <tr>
              <td>Accesso ai dati</td>
              <td>
                <p>L'Utente può richiedere al Titolare del trattamento:</p>
                <ul>
                  <li>
                    la conferma che stia trattando dati che lo riguardano;
                  </li>
                  <li>copia dei dati che lo riguardano;</li>
                  <li>
                    informazioni riguardanti il trattamento dei dati (es., basi
                    giuridiche, termini di conservazione, categorie di
                    destinatari dei dati, ecc.)
                  </li>
                </ul>
              </td>
              <td>L'Utente può presentare sempre tale richiesta</td>
            </tr>
            <tr>
              <td>Rettifica o integrazione dei dati</td>
              <td>
                <p>L'Utente può richiedere al Titolare del trattamento di:</p>
                <ul>
                  <li>rettificare</li>
                  <li>aggiornare</li>
                  <li>modificare </li>
                </ul>
                <p>i Dati Personali trattati</p>
              </td>
              <td>Qualora i dati trattati risultino inesatti o incompleti</td>
            </tr>
            <tr>
              <td>Limitazione del trattamento dei Dati Personali</td>
              <td>
                L'Utente può richiedere al Titolare che questi non svolga, ad
                accezione della sola conservazione, alcuna operazione di
                trattamento sui suoi Dati Personali, se non col consenso
                dell'Utente o per tutelare i propri diritti
              </td>
              <td>
                <ul>
                  <li>
                    l'Utente contesta l'esattezza dei Dati Personali, per il
                    periodo necessario al titolare del trattamento per
                    verificare l'esattezza di tali Dati Personali;
                  </li>
                  <li>
                    il trattamento è illecito e l'interessato si oppone alla
                    cancellazione dei Dati Personali e chiede invece che ne sia
                    limitato l'utilizzo;
                  </li>
                  <li>
                    benché il titolare del trattamento non ne abbia più bisogno
                    ai fini del trattamento, i Dati Personali sono necessari
                    all'interessato per l'accertamento, l'esercizio o la difesa
                    di un diritto in sede giudiziaria;
                  </li>
                  <li>
                    l'Utente si è opposto al trattamento, in attesa della
                    verifica in merito all'eventuale prevalenza dei motivi
                    legittimi del titolare del trattamento rispetto a quelli
                    dell'interessato
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Opposizione al trattamento dei Dati Personali</td>
              <td>
                L'Utente può opporsi a trattamenti basati sul legittimo
                interesse (compreso l'invio di comunicazione promozionali) o su
                di un interesse pubblico
              </td>
              <td>
                Occorre sussistano motivi connessi alla situazione particolare
                dell'Utente, tranne se l'opposizione è al trattamento per
                finalità di marketing diretto
              </td>
            </tr>
            <tr>
              <td>Opposizione ad un processo decisionale automatizzato</td>
              <td>
                L'Utente può opporsi a processi decisionali automatizzati. Nel
                caso tale processo sia necessario alla conclusione di un
                contratto, si basi su di un consenso esplicito, sia autorizzato
                da legge o regolamento dello Stato o dell'Unione Europea,
                l'Utente ha il diritto di ottenere l'intervento umano da parte
                del titolare del trattamento, di esprimere la propria opinione e
                di contestare la decisione
              </td>
              <td>
                Sussista una decisione basata unicamente sul trattamento
                automatizzato, compresa la profilazione, che produca effetti
                giuridici che lo riguardano o che incida in modo analogo
                significativamente sulla persona dell'Utente
              </td>
            </tr>
            <tr>
              <td>Portabilità dei Dati Personali</td>
              <td>
                L'Utente ha il diritto di ricevere in un formato strutturato, di
                uso comune e leggibile da dispositivo automatico i Dati
                personali che lo riguardano
              </td>
              <td>
                <p>Purché sussistano tutti i seguenti presupposti:</p>
                <ul>
                  <li>i dati siano stati forniti dall'Utente;</li>
                  <li>
                    il trattamento si basi sul consenso o su un contratto;
                  </li>
                  <li>il trattamento sia effettuato con mezzi automatizzati</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Revoca del consenso</td>
              <td>
                L'Utente può revocare il consenso prestato. La revoca non
                pregiudica la liceità del trattamento svolto sino a quel momento
              </td>
              <td>Sempre</td>
            </tr>
          </table>
          <br />
          <br />
          <span
            id="privacy-policy-esercitare"
            className="bold">
            COME ESERCITARE I DIRITTI E/O RICHIEDERE INFORMAZIONI SUL
            TRATTAMENTO
          </span>
          <hr />
          <p>
            Il “Responsabile per la protezione dei dati” è a disposizione per
            ogni eventuale dubbio o chiarimento, per l'esercizio dei diritti
            degli Interessati e per fornire l'elenco aggiornato delle categorie
            dei destinatari dei dati.
          </p>
          <br />
          <table>
            <tr>
              <td>Responsabile per la protezione dei dati o DPO</td>
              <td>privacy@unipol.it</td>
            </tr>
          </table>
          <br />
          <br />
          <p>
            Resta ferma la tua facoltà di rivolgerti al{" "}
            <a
              href="https://www.garanteprivacy.it/"
              target="_blank"
              rel="noreferrer">
              Garante Privacy.
            </a>
            , anche tramite un reclamo, ove ritenuto necessario per la tutela
            dei tuoi Dati Personali e dei tuoi diritti in materia.
          </p>
          <br />
          <br />
          <span>
            <a href="#privacy-policy-index">Torna all'indice</a>
          </span>
        </div>
      </>
    );
  }

  function legal() {
    return (
      <>
        <div className="privacy__msg-container">
          Il presente strumento di autovalutazione (“Tool”) ed il relativo piano
          di azione che viene generato come report finale (“Deliverable”) sono
          elaborati, nell’ambito del “Progetto Derris”, per scopi meramente
          informativi al fine di dare la possibilità alle aziende di piccole e
          medie dimensioni (i “Destinatari”) di contribuire ad accrescere la
          propria consapevolezza sulla loro esposizione ai rischi derivanti da
          eventi climatici estremi come, ad esempio, alluvioni, pioggia,
          grandine, fulmini, vento, frane, ondate di caldo o gelo (i “Rischi”),
          fornendo degli spunti per l’implementazione di soluzioni che riducano
          o prevengano i relativi danni.
          <br />
          <br />
          La compilazione del Tool ed il rilascio del relativo Deliverable non
          potranno essere intesi e/o interpretati come valutazione e/o
          assessment, raccomandazione e/o dichiarazione di ragionevolezza, né
          potranno essere utilizzati da chiunque, ivi inclusi, advisor, esperti
          o consulenti incaricati dai Destinatari come base per eventuali
          analisi ed hanno scopo meramente divulgativo né si sostituiscono a
          quanto richiesto ai Destinatari da disposizioni di legge e/o
          regolamento e/o per ordine di Autorità.
          <br />
          <br />
          I contenuti del Deliverable sono elaborati sulla base di informazioni
          e dati ad oggi disponibili, raccolti ed elaborati mediante
          compilazione del Tool da parte dei Destinatari stessi e potrebbero
          subire pertanto scostamenti - anche rilevanti – o non rispondere alle
          reali circostanze di fatto tenuto anche conto di rischi ed eventi
          meteo-climatici [condizioni economiche e fattori di mercato] e/o, in
          generale, di fattori esogeni non noti o non prevedibili.
          <br />
          <br />
          Il Tool e il Deliverable non hanno alcuna pretesa di esaustività in
          relazione a tutte le informazioni che potrebbero essere necessarie per
          effettuare una valutazione sul livello di esposizione ai Rischi, sul
          grado di vulnerabilità dei Destinatari e sui suggerimenti per limitare
          eventuali danni in caso di catastrofe. Le informazioni ivi contenute
          non sostituiscono le valutazioni e le analisi che devono essere
          condotte autonomamente dai Destinatari. La restituzione del
          Deliverable non comporta l’assunzione di alcun obbligo di fornire
          informazioni aggiuntive in relazione ai potenziali Rischi o di
          aggiornare e/o integrare il Deliverable o di correggere eventuali
          inesattezze nello stesso contenute che potrebbero divenire manifeste.
          <br />
          <br />
          Con la restituzione del Deliverable non viene inoltre rilasciata
          alcuna dichiarazione e/o garanzia, espressa o implicita, in relazione
          all’accuratezza, alla completezza, alla correttezza o all’equità delle
          informazioni e dei dati nonché delle opinioni, delle stime e delle
          proiezioni eventualmente contenute nel Deliverable stesso. Di
          conseguenza, non viene assunta alcuna responsabilità, diretta e/o
          indiretta, espressa o implicita, contrattuale, statutaria o di sorta
          in merito al Tool e/o al Deliverable o in relazione a qualsivoglia
          errore e/o omissione ivi incluso – a mero titolo esemplificativo e non
          esaustivo - con riferimento (i) al livello di pericolosità attribuito
          a seguito della geolocalizzazione dei Destinatari ed (ii) all’indice
          di rischio emerso dalla compilazione del Tool sulla base del livello
          di pericolosità attribuito a seguito della geolocalizzazione fornita
          dai Destinatari stessi.
          <br />
          <br />
          La compilazione del Tool e l’elaborazione del Deliverable non ha
          alcuna finalità pubblicitaria e/o promozionale e non costituisce
          pertanto un invito all’investimento, né potrà costituire un’offerta al
          pubblico o contrattuale o una promozione, né prevede l’assunzione di
          impegni di alcuna natura da parte dei Destinatari e/o [dei Partners
          del “Progetto Derris”] in relazione alla sottoscrizione di prodotti
          assicurativi per la prevenzione dei Rischi.
          <br />
          <br />
          I Destinatari dichiarano che la compilazione del Tool e la ricezione
          del Deliverable non comportano violazione delle / né contravvengono
          alle disposizioni di legge e/o regolamento e/o contrattuali
          applicabili nella giurisdizione di provenienza.
          <br />
          <br />
          La titolarità dei diritti di proprietà industriale e/o intellettuale
          afferenti al Tool ed al Deliverable è riservata. I Destinatari, così
          come eventuali soggetti terzi che abbiano accesso al sito e/o
          interagiscano con il Tool (i"Soggetti Terzi""), si impegnano ad
          utilizzare il Tool ed il Deliverable esclusivamente nell’ambito del e
          per le finalità connesse al “Progetto Derris”, a non depositare
          brevetti o registrare marchi o modelli o altrimenti esercitare altri
          titoli di privativa industriale connessi o relativi ad idee,
          invenzioni o anche indirettamente ricollegabili al Tool ed al
          Deliverable e/o agli eventuali ulteriori risultati ottenuti da o
          nell’ambito del “Progetto Derris”. Nel caso in cui simili titoli
          vengano depositati e/o registrati e/o altrimenti esercitati, i
          Destinatari e/o i Soggetti Terzi si impegnano a trasferirne
          gratuitamente la proprietà esclusiva ed ogni diritto di sfruttamento
          ai beneficiari del “Progetto Derris”, essendo peraltro beninteso che
          graveranno sui Destinatari e/o sui Soggetti Terzi anche le eventuali
          spese per la procedura volta al conseguimento dei titoli di cui sopra.
          I Destinatari e/o i Soggetti Terzi si impegnano altresì ad astenersi
          dal copiare, duplicare, riprodurre o registrare, in qualsiasi forma e
          con qualsiasi mezzo, il Tool ed il Deliverable e/o i contenuti degli
          stessi. La menzione del Tool da parte dei Destinatari e/o dei Soggetti
          Terzi in ambiti diversi dal “Progetto Derris” dovrà sempre precisare
          che il Tool è stato elaborato nell’ambito del medesimo “Progetto
          Derris”.
          <br />
          <br />
        </div>
      </>
    );
  }

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="privacy__container">
          <div className="privacy__title privacy__title-">
            {state === 0
              ? "Informativa cookies"
              : state === 1
              ? "Privacy Policy"
              : "Area Legale"}
          </div>
          <div
            className={"privacy__close-container privacy__close-container-"}
            onClick={handleClose}>
            <img
              src={
                require("./../../assets/system/icon-system-close-black.svg")
                  .default
              }
              alt="Close"
              className="privacy__close-icon"
            />
          </div>
          {state === 0 ? cookies() : state === 1 ? policy() : legal()}
        </div>
      </section>
    </div>
  );
};

export default LegalModal;
