import config from './../../config/config';
import { getAuthResponse } from './../proxy/api'

/**
 * createNewPlace
 * 
 * (Call webservices to create a new place)
 * 
 * @param {String} token Token for bearer auth
 * @returns Promise calling places service
 */
export const createNewPlace = (street: any, streetnumber: any, town: any, province: any, zipcode: any, lat: string, lng: string, type: number, token: string, perils: any): Promise<any> => {
    if (
        street !== undefined && street.short_name !== undefined &&
        streetnumber !== undefined && streetnumber.short_name !== undefined &&
        town !== undefined && town.short_name !== undefined
    ) {
        return getAuthResponse(
            "POST",
            config.webservices.places.endpoint,
            JSON.stringify({
                street: street.short_name,
                streetnumber: streetnumber.short_name,
                town: town.short_name,
                province: province !== undefined && province.short_name !== undefined ? province.short_name : null,
                zipcode: zipcode !== undefined && zipcode.short_name !== undefined ? zipcode.short_name : null,
                longitude: lng ? lng : null,
                latitude: lat ? lat : null,
                type: type,
                perils: perils
            }),
            token
        );
    } else {
        return new Promise((resolve) => { resolve(JSON.parse('{"error": "invalid parameters"}')) });
    }
}


/**
 * getPlaces
 * 
 * (Call webservices to get places)
 * 
 * @param {String} token Token for bearer auth
 * @param {String} street Street to search
 * @param {String} town Town to search
 * @returns Promise calling palces service
 */
export const getPlaces = (street: string, town: string, token: string): Promise<any> => {
    return getAuthResponse(
        "GET",
        config.webservices.places.endpoint + "?street=" + street + "&town=" + town,
        null,
        token
    );
}


/**
 * deletePlaces
 * 
 * (Call webservices to delete one or more places)
 * 
 * @param {String} token Token for bearer auth
 * @param {String} placeId Id of the place to delete
 * @returns Promise calling palces service
 */
export const deletePlaces = (placeId: string, token: string): Promise<any> => {
    return getAuthResponse(
        "DELETE",
        config.webservices.places.endpoint + "/" + placeId,
        null,
        token
    );
}