import React from "react";
import { Redirect } from "react-router-dom";
import { getDevice } from "../../../lib/responsive-lib";
import config from "./../../../config/config";
import "./PartialResultsSheet.css";

interface PartialResultsSheetProps {
  results: any;
  goToNextHazard: any;
  placeInfo: any;
  lastHazard: number | undefined;
  questionnaireDone: boolean;
  placeId: string;
  lastHazardName: string;
}

interface PartialResultsSheetState {
  device: String;
  redirectToTips: boolean;
}
export default class PartialResultsSheet extends React.Component<
  PartialResultsSheetProps,
  PartialResultsSheetState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      redirectToTips: false,
    };

    // Method binding
    this.redirectToTips = this.redirectToTips.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToTips()}
        <div
          className={
            "partialresultssheet__questionsheet-partial-results partialresultssheet__questionsheet-partial-results-" +
            this.state.device
          }
        >
          <div className="partialresultssheet__questionsheet-header">
            <div
              className={
                "partialresultssheet__question-header-row-1 partialresultssheet__question-header-row-1-" +
                this.state.device
              }
            >
              {
                this.getSingleSuggestion(this.props.results.SuggestionsByPeril)
                  .Name
              }
            </div>
            <div
              className={
                "partialresultssheet__question-header-row-2 partialresultssheet__question-header-row-2-" +
                this.state.device
              }
            >
              Risultato questionario
            </div>
          </div>
          {!this.props.questionnaireDone ? (
            <div
              className={
                "partialresultssheet__questionsheet-text partialresultssheet__questionsheet-text-" +
                this.state.device
              }
            >
              Abbiamo{" "}
              <span className="bold">
                {this.props.results.NTotUnspecifiedSuggestions} suggerimenti per
                te.
              </span>
              <br />
              Potrai analizzare i suggerimenti in dettaglio dopo aver completato
              tutto il questionario.
              <br />
              {this.getHazardText(this.props.lastHazardName)}
            </div>
          ) : (
            <div
              className={
                "partialresultssheet__questionsheet-text partialresultssheet__questionsheet-text-" +
                this.state.device
              }
            >
              Abbiamo{" "}
              <span className="bold">
                {this.props.results.NTotUnspecifiedSuggestions} suggerimenti per
                te.
              </span>
              <br />
              Il questionario è terminato, ora potrai analizzare i suggerimenti
              in dettaglio.
            </div>
          )}
          {this.checkHazardValues() ? (
            <>
              <div
                className={
                  "partialresultssheet__questionsheet-pie-row partialresultssheet__questionsheet-pie-row-" +
                  this.state.device
                }
              >
                <div
                  className={
                    "partialresultssheet__questionsheet-pie-container partialresultssheet__questionsheet-pie-container-" +
                    this.state.device
                  }
                >
                  <div className="partialresultssheet__questionsheet-pie-img">
                    <div
                      className={
                        "partialresultssheet__questionsheet-pie-hold partialresultssheet__questionsheet-pie-hold-" +
                        this.state.device
                      }
                    >
                      <div
                        className="partialresultssheet__questionsheet-pie"
                        style={this.getPieStyle(
                          this.convertHazardToDegree(
                            this.getSingleSuggestion(
                              this.props.placeInfo.Perils
                            ).Level
                          )
                        )}
                      ></div>
                    </div>
                  </div>
                  <div className="partialresultssheet__questionsheet-grade">
                    {this.convertHazardToText(
                      this.getSingleSuggestion(this.props.placeInfo.Perils)
                        .Level
                    )}
                  </div>
                  <div className="partialresultssheet__questionsheet-title">
                    Pericolo nella tua area
                  </div>
                </div>
                <div
                  className={
                    "partialresultssheet__questionsheet-pie-container partialresultssheet__questionsheet-pie-container-" +
                    this.state.device
                  }
                >
                  <div className="partialresultssheet__questionsheet-pie-img">
                    <div
                      className={
                        "partialresultssheet__questionsheet-pie-hold partialresultssheet__questionsheet-pie-hold-" +
                        this.state.device
                      }
                    >
                      <div
                        className="partialresultssheet__questionsheet-pie"
                        style={this.getPieStyle(
                          this.convertPercentageToDegree(
                            this.getSingleSuggestion(
                              this.props.results.SuggestionsByPeril
                            ).Score
                          )
                        )}
                      ></div>
                    </div>
                  </div>
                  <div className="partialresultssheet__questionsheet-grade">
                    {this.convertRiskToText(
                      this.getSingleSuggestion(
                        this.props.results.SuggestionsByPeril
                      ).Score
                    )}
                  </div>
                  <div className="partialresultssheet__questionsheet-title">
                    Rischio del tuo edificio
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            className={
              "partialresultssheet__questionsheet-continue-button-container partialresultssheet__questionsheet-continue-button-container-" +
              this.state.device
            }
          >
            {!this.props.questionnaireDone ? (
              <div
                className="partialresultssheet__questionsheet-continue-button"
                onClick={this.props.goToNextHazard}
              >
                Vai al pericolo seguente
              </div>
            ) : (
              <div
                className="partialresultssheet__questionsheet-gototips-button"
                onClick={this.redirectToTips}
              >
                Vedi suggerimenti
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  // Check if an hazard have values
  checkHazardValues() {
    return (
      this.props.placeInfo.Perils.find(
        (peril: any) => peril.Id === this.props.lastHazard
      ) !== undefined
    );
  }

  // Find suggestion by hazard id
  getSingleSuggestion(suggestions: Array<any>): any {
    return suggestions.find(
      (suggestion) => suggestion.Id === this.props.lastHazard
    );
  }

  // Get style for pie rendering
  getPieStyle = (degree: Number) => ({
    transform: "rotate(" + degree + "deg)",
  });

  // Convert risk by letter to degree
  convertHazardToDegree(risk: String): Number {
    switch (risk) {
      case "L":
        return 40;
      case "M":
        return 90;
      case "MH":
        return 120;
      case "H":
        return 140;
      default:
        return 0;
    }
  }

  // Convert risk by letter to text
  convertHazardToText(risk: String): string {
    switch (risk) {
      case "L":
        return "Basso";
      case "M":
        return "Medio";
      case "MH":
        return "Medio Alto";
      case "H":
        return "Alto";
      default:
        return "";
    }
  }

  // Convert risk by letter to text
  convertRiskToText(risk: number): string {
    switch (true) {
      case risk < 33.33:
        return "Basso";
      case risk >= 33.33 && risk <= 66.66:
        return "Medio";
      case risk > 66.66:
        return "Alto";
      default:
        return "";
    }
  }

  // Convert risk by letter to degree
  convertRiskToDegree(risk: String): Number {
    switch (risk) {
      case "L":
        return 40;
      case "M":
        return 90;
      case "MH":
        return 120;
      case "H":
        return 140;
      default:
        return 0;
    }
  }

  // Convert percentage to degree
  convertPercentageToDegree(percentage: number): number {
    switch (true) {
      case percentage < 33.33:
        return 40;
      case percentage >= 33.33 && percentage <= 66.66:
        return 90;
      case percentage > 66.66:
        return 140;
      default:
        return 0;
    }
  }

  // Perform redirect to tips view
  redirectToTips() {
    this.setState({ redirectToTips: true });
  }

  // Rendere redirect component
  renderRedirectToTips(): Object {
    return this.state.redirectToTips === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[10] + "/" + this.props.placeId }}
      />
    ) : (
      ""
    );
  }

  // Return the text of an Hazard
  getHazardText(hazardName: string): string {
    switch (hazardName.toLowerCase()) {
      case "alluvione":
        return "Qui di seguito troverai il livello di pericolosità delle alluvioni nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "grandine":
        return "Qui di seguito troverai il livello di pericolosità della grandine nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "temperatura":
        return "Qui di seguito troverai il livello di pericolosità della temperatura nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "pioggia":
        return "Qui di seguito troverai il livello di pericolosità della pioggia nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "vento":
        return "Qui di seguito troverai il livello di pericolosità del vento nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "fulminazione":
        return "Qui di seguito troverai il livello di pericolosità della fulminazione nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "frana":
      case "frane":
        return "Qui di seguito troverai il livello di pericolosità delle frane nella tua area (dato in precedenza), assieme al livello di rischio del tuo edificio elaborato sulla base delle risposte al questionario.";
      case "sostenibilità":
      case "sostenibilita":
      case "sostenibilità'":
      case "resilienza":
      default:
        return "";
    }
  }
}
