import React from "react";
import { getDevice } from "../../../../lib/responsive-lib";
import DatePicker from "react-datepicker";
import { saveSuggestions } from "./../../../../api/suggestions-api/api";
import Loader from "./../../../loader/Loader";
import ErrorPopup from "./../../../errorpopup/ErrorPopup";
import "react-datepicker/dist/react-datepicker.css";
import "./HazardTip.css";

interface HazardTipProps {
  suggestion: any;
  handleChoice: any;
  suggestionType: string;
  placeId: string;
  cookies: any;
  reloadSuggestions: any;
}

interface HazardTipState {
  device: String;
  loader: boolean;
  error: boolean;
  errorTitle: string;
  errorText: string;
  questionNotApplicableStatus: boolean;
  questionAlreadyAppliedStatus: boolean;
  questionApplyStatus: boolean;
  helpMessageStatus: boolean;
  editMode: boolean;
  person: string;
  endDate: any;
  budget: string;
  note: string;
  priority: boolean;
  msg: boolean;
  msgTitle: string;
  msgText: string;
}

export default class HazardTip extends React.Component<
  HazardTipProps,
  HazardTipState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      loader: false,
      error: false,
      errorTitle: "",
      errorText: "",
      questionNotApplicableStatus: false,
      questionAlreadyAppliedStatus: false,
      questionApplyStatus: false,
      helpMessageStatus: false,
      editMode: false,
      person: "",
      endDate: undefined,
      budget: "",
      note: "",
      priority: false,
      msg: false,
      msgTitle: "",
      msgText: "",
    };

    // Method binding
    this.openHelpMessage = this.openHelpMessage.bind(this);
    this.closeHelpMessage = this.closeHelpMessage.bind(this);
    this.handleChoiceClick = this.handleChoiceClick.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.oneEndDataChange = this.oneEndDataChange.bind(this);
    this.onPersonChange = this.onPersonChange.bind(this);
    this.onBudgetChange = this.onBudgetChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
    this.togglePriority = this.togglePriority.bind(this);

    this.radioButtonText[1] = "Non applicabile / Non so";
    this.radioButtonText[2] = "Già in essere";
    this.radioButtonText[3] = "Metti nel piano";
  }

  // Class variables
  private radioButtonText: Array<string> = [];

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
      questionNotApplicableStatus:
        this.props.suggestion.State !== null &&
        this.props.suggestion.State.Id === 1
          ? true
          : false,
      questionAlreadyAppliedStatus:
        this.props.suggestion.State !== null &&
        this.props.suggestion.State.Id === 2
          ? true
          : false,
      questionApplyStatus:
        this.props.suggestion.State !== null &&
        this.props.suggestion.State.Id === 3
          ? true
          : false,
      person:
        this.props.suggestion.TrustedTo !== undefined &&
        this.props.suggestion.TrustedTo !== null
          ? this.props.suggestion.TrustedTo
          : "",
      note:
        this.props.suggestion.Note !== undefined &&
        this.props.suggestion.Note !== null
          ? this.props.suggestion.Note
          : "",
      endDate: this.stringToDate(this.props.suggestion.EstimatedDate),
      budget:
        this.props.suggestion.EstimatedBudget !== undefined &&
        this.props.suggestion.EstimatedBudget !== null
          ? this.props.suggestion.EstimatedBudget
          : "",
      priority:
        this.props.suggestion.IsPriority !== undefined &&
        this.props.suggestion.IsPriority !== false
          ? true
          : false,
    });
  }

  // React render
  public render() {
    return (
      <>
        <div
          className={
            "hazardtip__container hazardtip__container-" + this.state.device
          }
        >
          {this.props.suggestionType === "DA_APPLICARE"
            ? this.getPriorityIcon()
            : ""}
          <div className="hazardtip__header">
            <div className="hazardtip__header-col-1">
              {this.getCostIcon(this.props.suggestion.Cost)}
            </div>
            <div className="hazardtip__header-col-2">
              <div className="hazardtip__header-col-2-row-1">
                {this.props.suggestion.Category.Name}
              </div>
              <div className="hazardtip__header-col-2-row-2">
                {this.convertCostToTest(this.props.suggestion.Cost)}
              </div>
            </div>
          </div>
          <div className="hazardtip__text">{this.props.suggestion.Text}</div>
          {this.props.suggestionType === "OTHER"
            ? this.getRadioButtonFooter()
            : this.getEditFooter()}
        </div>
        {this.renderLoader()}
        {this.renderError()}
      </>
    );
  }

  // Open the box with the help messaga
  openHelpMessage(): void {
    this.setState({ helpMessageStatus: true });
  }

  // Close the box with the help messaga
  closeHelpMessage(): void {
    this.setState({ helpMessageStatus: false });
  }

  // Get edit footer code
  getEditFooter(): Object {
    return (
      <>
        <div
          className={
            "hazardtip__footer-edit hazardtip__footer-edit-" +
            (this.state.editMode ? "open" : "close")
          }
        >
          {this.state.editMode ? this.getEditMode() : this.getEditButton()}
        </div>
      </>
    );
  }

  getEditMode(): Object {
    return (
      <>
        <div
          className={
            "hazardtip__footer-close-icon-container hazardtip__footer-close-icon-container-" +
            this.state.device
          }
          onClick={this.toggleEditMode}
        >
          <img
            src={
              require("./../../../../assets/system/icon-system-close-black.svg")
                .default
            }
            alt="Chiudi modalità modifica suggerimento"
            className={
              "hazardtip__footer-close-edit-icon hazardtip__footer-close-edit-icon-" +
              this.state.device
            }
          />
        </div>
        <div
          className={
            "hazardtip__edit-form hazardtip__edit-form-" + this.state.device
          }
        >
          <div
            className={
              "hazardtip__edit-form-field " +
              (this.props.suggestionType !== "DA_APPLICARE"
                ? "compact-mode"
                : "")
            }
          >
            <div className="hazardtip__edit-form-label-box">Responsabile</div>
            <div className="hazardtip__edit-form-input-box">
              <input
                id="hazardtip__edit-form-input-person"
                type="text"
                className="hazardtip__edit-form-input"
                onChange={this.onPersonChange}
                value={this.state.person}
              />
            </div>
          </div>
          <div
            className={
              "hazardtip__edit-form-field " +
              (this.props.suggestionType !== "DA_APPLICARE"
                ? "compact-mode"
                : "")
            }
          >
            <div className="hazardtip__edit-form-label-box">
              Data di conclusione prevista
            </div>
            <div className="hazardtip__edit-form-date-box">
              <DatePicker
                selected={this.state.endDate}
                onChange={this.oneEndDataChange}
                dateFormat="dd/MM/yyyy"
                className="hazardtip__edit-form-date"
                value={this.state.endDate}
              />
            </div>
          </div>
          <div
            className={
              "hazardtip__edit-form-field " +
              (this.props.suggestionType !== "DA_APPLICARE"
                ? "compact-mode"
                : "")
            }
          >
            <div className="hazardtip__edit-form-label-box">
              Budget previsto (euro)
            </div>
            <div className="hazardtip__edit-form-input-box">
              <input
                id="hazardtip__edit-form-input-budget"
                type="text"
                className="hazardtip__edit-form-input"
                onChange={this.onBudgetChange}
                value={this.state.budget}
              />
            </div>
          </div>
          <div className="hazardtip__edit-form-field">
            <div className="hazardtip__edit-form-label-box">Note</div>
            <div className="hazardtip__edit-form-input-box">
              <input
                id="hazardtip__edit-form-input-note"
                type="text"
                className="hazardtip__edit-form-input"
                onChange={this.onNoteChange}
                value={this.state.note}
              />
            </div>
          </div>
        </div>
        <div
          className={
            "hazardtip__edit-radio-button-container hazardtip__edit-radio-button-container-" +
            (this.props.suggestionType !== "DA_APPLICARE" ? "compact-mode" : "")
          }
        >
          {this.getRadioButton()}
        </div>
        {this.props.suggestion.Help !== undefined &&
        this.props.suggestion.Help !== null ? (
          <>
            <div
              onMouseOver={this.openHelpMessage}
              onMouseLeave={this.closeHelpMessage}
              className={
                "hazardtip__footer-help-box hazardtip__footer-help-box-" +
                this.state.device
              }
            >
              <img
                src={
                  require("./../../../../assets/system/icon-system-more-info.svg")
                    .default
                }
                alt="Ulteriori informazioni sul suggerimento"
                className={
                  "hazardtip__footer-help-icon hazardtip__footer-help-icon-" +
                  this.state.device
                }
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {this.state.helpMessageStatus ? (
          <>
            <div className="hazardtip__footer-help-message-box">
              {this.props.suggestion.Help !== undefined &&
              this.props.suggestion.Help !== null
                ? this.props.suggestion.Help
                : ""}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  // Get edit button code
  getEditButton(): Object {
    return (
      <>
        <div
          className={
            "hazardtip__footer-row hazardtip__footer-row-1 hazardtip__footer-row-" +
            (this.state.editMode ? "open" : "close")
          }
        >
          <div
            onClick={this.toggleEditMode}
            className="hazardtip__footer-row-text hazardtip__footer-row-text-1"
          >
            <img
              src={
                require("./../../../../assets/system/icon-system-pen.svg")
                  .default
              }
              alt="Modifica scelte sul suggerimento"
              className={
                "hazardtip__footer-edit-icon hazardtip__footer-edit-icon-" +
                this.state.device
              }
            />
            Aggiungi Informazioni
          </div>
        </div>
        {this.props.suggestion.Help !== undefined &&
        this.props.suggestion.Help !== null &&
        !this.state.editMode ? (
          <>
            <div
              onMouseOver={this.openHelpMessage}
              onMouseLeave={this.closeHelpMessage}
              className={
                "hazardtip__footer-help-box hazardtip__footer-help-box-" +
                this.state.device
              }
            >
              <img
                src={
                  require("./../../../../assets/system/icon-system-more-info.svg")
                    .default
                }
                alt="Ulteriori informazioni sul suggerimento"
                className={
                  "hazardtip__footer-help-icon hazardtip__footer-help-icon-" +
                  this.state.device
                }
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {this.state.helpMessageStatus ? (
          <>
            <div className="hazardtip__footer-help-message-box">
              {this.props.suggestion.Help !== undefined &&
              this.props.suggestion.Help !== null
                ? this.props.suggestion.Help
                : ""}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  getPriorityIcon() {
    return (
      <>
        <div
          className={
            "hazardtip__priority-container hazardtip__priority-container-" +
            this.state.device
          }
          onClick={this.togglePriority}
        >
          {this.state.priority ? (
            <>
              <img
                src={
                  require("./../../../../assets/product/icon-product-star-active.svg")
                    .default
                }
                alt="Togli la priorità"
                className={
                  "hazardtip__priority-icon hazardtip__priority-icon-on hazardtip__priority-icon-on-" +
                  this.state.device
                }
              />
            </>
          ) : (
            <>
              <img
                src={
                  require("./../../../../assets/product/icon-product-star-inactive.svg")
                    .default
                }
                alt="Imposta la priorità"
                className={
                  "hazardtip__priority-icon hazardtip__priority-icon-off hazardtip__priority-icon-off-" +
                  this.state.device
                }
              />
            </>
          )}
        </div>
      </>
    );
  }

  // Return radio button footer code
  getRadioButtonFooter(): Object {
    return (
      <>
        <div
          className={
            "hazardtip__footer-radio hazardtip__footer-radio-" +
            (this.state.editMode ? "open" : "close")
          }
        >
          {this.getRadioButton()}
          {this.props.suggestion.Help !== undefined &&
          this.props.suggestion.Help !== null ? (
            <>
              <div
                onMouseOver={this.openHelpMessage}
                onMouseLeave={this.closeHelpMessage}
                className={
                  "hazardtip__footer-help-box hazardtip__footer-help-box-" +
                  this.state.device
                }
              >
                <img
                  src={
                    require("./../../../../assets/system/icon-system-more-info.svg")
                      .default
                  }
                  alt="Ulteriori informazioni sul suggerimento"
                  className={
                    "hazardtip__footer-help-icon hazardtip__footer-help-icon-" +
                    this.state.device
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {this.state.helpMessageStatus ? (
            <>
              <div className="hazardtip__footer-help-message-box">
                {this.props.suggestion.Help !== undefined &&
                this.props.suggestion.Help !== null
                  ? this.props.suggestion.Help
                  : ""}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
  // Return radio button code
  getRadioButton(): Object {
    return (
      <>
        <div
          id="1"
          onClick={this.handleChoiceClick}
          className="hazardtip__footer-row hazardtip__footer-row-1"
        >
          <div className="hazardtip__footer-circle-container">
            {this.state.questionNotApplicableStatus ? (
              <img
                src={require("./../../../../assets/system/circle_full.png")}
                alt={"Risposta " + this.radioButtonText[1] + " non selezionata"}
                className={
                  "hazardtip__footer-circle-full hazardtip__footer-circle-full-" +
                  this.state.device
                }
              />
            ) : (
              <img
                src={require("./../../../../assets/system/circle_empty.png")}
                alt={"Risposta " + this.radioButtonText[1] + " selezionata"}
                className={
                  "hazardtip__footer-circle-empty hazardtip__footer-circle-empty-" +
                  this.state.device
                }
              />
            )}
          </div>
          <div className="hazardtip__footer-question-container">
            {this.radioButtonText[1]}
          </div>
        </div>
        <div
          id="2"
          onClick={this.handleChoiceClick}
          className="hazardtip__footer-row hazardtip__footer-row-2"
        >
          <div className="hazardtip__footer-circle-container">
            {this.state.questionAlreadyAppliedStatus ? (
              <img
                src={require("./../../../../assets/system/circle_full.png")}
                alt={"Risposta " + this.radioButtonText[2] + " non selezionata"}
                className={
                  "hazardtip__footer-circle-full hazardtip__footer-circle-full-" +
                  this.state.device
                }
              />
            ) : (
              <img
                src={require("./../../../../assets/system/circle_empty.png")}
                alt={"Risposta " + this.radioButtonText[2] + " selezionata"}
                className={
                  "hazardtip__footer-circle-empty hazardtip__footer-circle-empty-" +
                  this.state.device
                }
              />
            )}
          </div>
          <div className="hazardtip__footer-question-container">
            {this.radioButtonText[2]}
          </div>
        </div>
        <div
          id="3"
          onClick={this.handleChoiceClick}
          className="hazardtip__footer-row hazardtip__footer-row-3"
        >
          <div className="hazardtip__footer-circle-container">
            {this.state.questionApplyStatus ? (
              <img
                src={require("./../../../../assets/system/circle_full.png")}
                alt={"Risposta " + this.radioButtonText[3] + " non selezionata"}
                className={
                  "hazardtip__footer-circle-full hazardtip__footer-circle-full-" +
                  this.state.device
                }
              />
            ) : (
              <img
                src={require("./../../../../assets/system/circle_empty.png")}
                alt={"Risposta " + this.radioButtonText[3] + " selezionata"}
                className={
                  "hazardtip__footer-circle-empty hazardtip__footer-circle-empty-" +
                  this.state.device
                }
              />
            )}
          </div>
          <div className="hazardtip__footer-question-container">
            {this.radioButtonText[3]}
          </div>
        </div>
      </>
    );
  }

  // Build and return the cost icon
  getCostIcon(cost: number): Object {
    switch (cost) {
      case 0:
        return (
          <>
            <div
              className={
                "hazardtip__cost-icon-container hazardtip__low-cost-icon-container hazardtip__low-cost-icon-container-" +
                this.state.device +
                " " +
                this.getIconColor()
              }
            >
              <img
                src={
                  require("./../../../../assets/product/icon-product-costo-basso.svg")
                    .default
                }
                alt="Simbolo costo basso"
                className={
                  "hazardtip__cost-icon hazardtip__low-cost-icon hazardtip__low-cost-icon-" +
                  this.state.device
                }
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div
              className={
                "hazardtip__cost-icon-container hazardtip__high-cost-icon-container hazardtip__high-cost-icon-container-" +
                this.state.device +
                " " +
                this.getIconColor()
              }
            >
              <img
                src={
                  require("./../../../../assets/product/icon-product-costo-alto.svg")
                    .default
                }
                alt="Simbolo costo alto"
                className={
                  "hazardtip__cost-icon hazardtip__high-cost-icon hazardtip__high-cost-icon-" +
                  this.state.device
                }
              />
            </div>
          </>
        );
      default:
        return <></>;
    }
  }

  // Return the color for the main icon
  getIconColor() {
    switch (this.props.suggestion.Category.Id) {
      case 1:
        return "green";
      case 2:
        return "blue";
      case 3:
        return "red";
      default:
        return "grey";
    }
  }

  // Handle click on tips radio buttons
  handleChoiceClick(e: React.MouseEvent) {
    this.props.handleChoice(
      this.props.suggestion.Hazard.Id,
      this.props.suggestion.Id,
      e.currentTarget.id,
      this.radioButtonText[Number(e.currentTarget.id)],
      this.props.suggestion.Text
    );
  }

  // Handle person changes
  onPersonChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ person: e.currentTarget.value });
  }

  // Handle end date changes
  oneEndDataChange(date: Date, event: React.SyntheticEvent<any, Event>): void {
    event.preventDefault();
    this.setState({ endDate: date });
  }

  // Handle budget changes
  onBudgetChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ budget: e.currentTarget.value });
  }

  // Handle note changes
  onNoteChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ note: e.currentTarget.value });
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.msg === false) {
      this.setState({ msg: true });
    } else {
      this.setState({ msg: false });
    }
  }

  // Toggle priority value
  togglePriority(e: React.MouseEvent) {
    if (this.state.priority === false) {
      this.setState({ priority: true }, () => {
        this.localSaveSuggestions({
          State: {
            Id: this.props.suggestion.State.Id,
          },
          Id: this.props.suggestion.Id,
          IsPriority: this.state.priority,
        });
      });
    } else {
      this.setState({ priority: false }, () => {
        this.localSaveSuggestions({
          State: {
            Id: this.props.suggestion.State.Id,
          },
          Id: this.props.suggestion.Id,
          IsPriority: this.state.priority,
        });
      });
    }
  }

  // Open/close edit mode
  toggleEditMode() {
    if (this.state.editMode) {
      switch (this.props.suggestionType) {
        case "DA_APPLICARE":
          if (
            this.state.person !== "" ||
            this.state.endDate !== undefined ||
            this.state.budget !== "" ||
            this.state.note !== ""
          ) {
            this.localSaveSuggestions({
              State: {
                Id: this.props.suggestion.State.Id,
              },
              Id: this.props.suggestion.Id,
              TrustedTo:
                this.state.person !== "" ? this.state.person : undefined,
              EstimatedDate:
                this.state.endDate !== undefined
                  ? this.state.endDate.getDate() +
                    "/" +
                    (+this.state.endDate.getMonth() + 1) +
                    "/" +
                    this.state.endDate.getFullYear()
                  : undefined,
              EstimatedBudget:
                this.state.budget !== "" ? this.state.budget : undefined,
              Note: this.state.note !== "" ? this.state.note : undefined,
            });
          } else {
            this.setState({ editMode: false });
          }
          break;
        case "GIA_IN_ESSERE":
        case "NON_APPLICABILI":
          if (this.state.note !== "") {
            this.localSaveSuggestions({
              State: {
                Id: this.props.suggestion.State.Id,
              },
              Id: this.props.suggestion.Id,
              Note: this.state.note !== "" ? this.state.note : undefined,
            });
          } else {
            this.setState({ editMode: false });
          }
          break;
        default:
          this.setState({ editMode: false });
          break;
      }
    } else {
      this.setState({ editMode: true });
    }
  }

  localSaveSuggestions(suggestion: any): void {
    this.setState({ loader: true }, () => {
      const { cookies } = this.props;
      saveSuggestions(this.props.placeId, cookies.get("derris_token"), [
        suggestion,
      ])
        .then((result) => {
          if (result.status === 200) {
            this.setState({
              loader: false,
              editMode: false,
            });
            this.props.reloadSuggestions();
          } else {
            this.setState({
              msg: true,
              msgTitle: "Avviso",
              msgText: "Problema con il salvataggio dei cambiamenti",
              loader: false,
              editMode: false,
            });
            console.error(
              "Error while saving current suggestion. Status: " + result.status
            );
            this.props.reloadSuggestions();
          }
        })
        .catch((error) => {
          this.setState({
            msg: true,
            msgTitle: "Avviso",
            msgText: "Problema con il salvataggio dei cambiamenti",
            loader: false,
            editMode: false,
          });
          console.error("Error while saving cyurrent suggestion. " + error);
          this.props.reloadSuggestions();
        });
    });
  }

  // Convert cost value
  convertCostToTest(cost: number): string {
    switch (cost) {
      case 0:
        return "Costo basso";
      case 1:
        return "Costo medio";
      case 2:
        return "Costo alto";
      default:
        return "Valore " + cost;
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Render the ErrorPopup component
  renderError(): Object {
    if (this.state.error) {
      return (
        <ErrorPopup
          errorTitle={this.state.errorTitle}
          errorText={this.state.errorText}
        />
      );
    } else if (this.state.msg) {
      return (
        <ErrorPopup
          errorTitle={this.state.msgTitle}
          errorText={this.state.msgText}
          action={this.toggleErrorPopup}
        />
      );
    } else {
      return <></>;
    }
  }

  // Conver date in forma "g/m/aaaa" in JavaScript Date format
  stringToDate(dateString: string): any {
    if (dateString !== undefined && dateString !== null && dateString !== "") {
      const datePieces = dateString.split("/");
      return new Date(
        Number(datePieces[2]),
        Number(("0" + datePieces[2]).slice(-2)),
        Number(datePieces[0])
      );
    } else {
      return undefined;
    }
  }
}
