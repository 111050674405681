import config from './../../config/config';

/**
 * getResponse
 * 
 * (Call proxy webservices to perform a remote call)
 * 
 * @param {String} verb Verb for call
 * @param {String} url Url to call
 * @param {String} jsonBody Body of call
 * @returns Promise calling auth service
 */
export const getResponse = (verb: "GET" | "POST", url: string, jsonBody: (string | null)): Promise<any> => {
    return fetch(
        new Request(config.webservices.proxy.endpoint, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                verb: verb,
                url: url,
                jsonBody: jsonBody
            })
        })
    );
}


/**
 * getResponse
 * 
 * (Call proxy webservices that needed Bearer token to perform a remote call)
 * 
 * @param {String} verb Verb for call
 * @param {String} url Url to call
 * @param {String} jsonBody Body of call
 * @param {String} token Bearer token for auth
 * @returns Promise calling auth service
 */
export const getAuthResponse = (verb: "GET" | "POST" | "HEAD" | "DELETE" | "PUT", url: string, jsonBody: (string | null), token: string): Promise<any> => {
    return fetch(
        new Request(config.webservices.proxy.endpoint, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                verb: verb,
                url: url,
                AuthToken: token,
                jsonBody: jsonBody
            })
        })
    );
}