import React from "react";
import { getDevice } from "./../../lib/responsive-lib";
import { Redirect } from "react-router-dom";
import config from "../../config/config";
import "./ErrorPopup.css";

interface ErrorPopupProps {
  errorTitle: String;
  errorText: String;
  action?: any;
}

interface ErrorPopupState {
  device: String;
  redirectToHome: boolean;
}
export default class ErrorPopup extends React.Component<
  ErrorPopupProps,
  ErrorPopupState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      redirectToHome: false,
    };

    // Method binding
    this.redirectToHome = this.redirectToHome.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({ device: getDevice() });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToPrivecyCheck()}
        <div className="errorpopup__container">
          <div className="errorpopup__background"></div>
          <div
            className={
              "errorpopup__popup errorpopup__popup-" + this.state.device
            }
          >
            <div className="errorpopup__title-container">
              {this.props.errorTitle}
            </div>
            <div
              className={
                "errorpopup__text-container errorpopup__text-container-" +
                this.state.device
              }
            >
              {this.props.errorText}
            </div>
            <div className="errorpopup__button-container">
              <div
                className="errorpopup__button-ok"
                onClick={
                  this.props.action !== undefined
                    ? this.props.action
                    : this.redirectToHome
                }
              >
                OK
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  redirectToHome() {
    this.setState({ redirectToHome: true });
  }

  // Rendere redirect component
  renderRedirectToPrivecyCheck(): Object {
    return this.state.redirectToHome === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[0] }} />
    ) : (
      ""
    );
  }
}
