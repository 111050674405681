import React from "react";
import { getDevice } from "./../../../lib/responsive-lib";
import StandardButton from "./standardButton/StandardButton";
import CounterdButton from "./counterButton/CounterButton";
import { Redirect } from "react-router-dom";
import config from "./../../../config/config";
import "./MenuItems.css";

interface MenuItemsProps {
  currentView: string;
  results: any;
  placeId: string;
  changeSuggestionType: any;
  suggestionType: string | undefined;
  toggleSidebar: any;
  toggleResiliencyView: any;
  toggleResiliencyPrintView: any;
}

interface MenuItemsState {
  device: String;
  redirectToPlace: boolean;
  redirectToQuestionnaire: boolean;
  redirectToTips: boolean;
  selectedTips: string | undefined;
}
export default class MenuItems extends React.Component<
  MenuItemsProps,
  MenuItemsState
> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      redirectToPlace: false,
      redirectToQuestionnaire: false,
      redirectToTips: false,
      selectedTips: this.props.suggestionType,
    };

    // Method binding
    this.redirectToPlace = this.redirectToPlace.bind(this);
    this.redirectToQuestionnarie = this.redirectToQuestionnarie.bind(this);
    this.localChangeSuggestionType = this.localChangeSuggestionType.bind(this);
    this.localGetResiliencyView = this.localGetResiliencyView.bind(this);
    this.localGetResiliencyViewPrint =
      this.localGetResiliencyViewPrint.bind(this);
  }

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToPlace()}
        {this.renderRedirectToQuestionnarie()}
        {this.renderRedirectToTips()}
        <div
          className={
            "menuitems__container menuitems__container-" + this.state.device
          }
        >
          <ul
            className={
              "menuitems__container-buttons-list menuitems__container-buttons-list-" +
              this.state.device
            }
          >
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <StandardButton
                text="LE TUE SEDI"
                active={false}
                onClick={this.redirectToPlace}
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <StandardButton
                text="QUESTIONARIO"
                active={this.props.currentView === "QuestionView"}
                onClick={
                  this.props.currentView !== "QuestionView"
                    ? this.redirectToQuestionnarie
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <CounterdButton
                id="OTHER"
                text="SUGGERIMENTI"
                active={
                  this.props.currentView === "TipsView" &&
                  this.props.suggestionType === "OTHER"
                }
                counter={
                  this.props.results !== undefined &&
                  this.props.results.NTotUnspecifiedSuggestions !== undefined
                    ? this.props.results.NTotUnspecifiedSuggestions
                    : 0
                }
                clear={false}
                style={
                  this.props.results === undefined ||
                  this.props.results.NTotUnspecifiedSuggestions === undefined ||
                  this.props.results.NTotUnspecifiedSuggestions === 0
                    ? { cursor: "unset" }
                    : {}
                }
                onClick={
                  this.props.results !== undefined &&
                  this.props.results.NTotUnspecifiedSuggestions !== undefined &&
                  this.props.results.NTotUnspecifiedSuggestions > 0
                    ? this.localChangeSuggestionType
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <CounterdButton
                id="DA_APPLICARE"
                text="DA APPLICARE"
                active={
                  this.props.currentView === "TipsView" &&
                  this.props.suggestionType === "DA_APPLICARE"
                }
                counter={
                  this.props.results !== undefined &&
                  this.props.results.NPlannedSuggestions !== undefined
                    ? this.props.results.NPlannedSuggestions
                    : 0
                }
                clear={true}
                style={
                  this.props.results === undefined ||
                  this.props.results.NPlannedSuggestions === undefined ||
                  this.props.results.NPlannedSuggestions === 0
                    ? { cursor: "unset" }
                    : {}
                }
                onClick={
                  this.props.results !== undefined &&
                  this.props.results.NPlannedSuggestions !== undefined &&
                  this.props.results.NPlannedSuggestions > 0
                    ? this.localChangeSuggestionType
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <CounterdButton
                id="GIA_IN_ESSERE"
                text="GI&Agrave; IN ESSERE"
                active={
                  this.props.currentView === "TipsView" &&
                  this.props.suggestionType === "GIA_IN_ESSERE"
                }
                counter={
                  this.props.results !== undefined &&
                  this.props.results.NDoneSuggestions !== undefined
                    ? this.props.results.NDoneSuggestions
                    : 0
                }
                clear={true}
                style={
                  this.props.results === undefined ||
                  this.props.results.NDoneSuggestions === undefined ||
                  this.props.results.NDoneSuggestions === 0
                    ? { cursor: "unset" }
                    : {}
                }
                onClick={
                  this.props.results !== undefined &&
                  this.props.results.NDoneSuggestions !== undefined &&
                  this.props.results.NDoneSuggestions > 0
                    ? this.localChangeSuggestionType
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <CounterdButton
                id="NON_APPLICABILI"
                text="NON APPLICABILI"
                active={
                  this.props.currentView === "TipsView" &&
                  this.props.suggestionType === "NON_APPLICABILI"
                }
                counter={
                  this.props.results !== undefined &&
                  this.props.results.NNASuggestions !== undefined
                    ? this.props.results.NNASuggestions
                    : 0
                }
                clear={true}
                style={
                  this.props.results === undefined ||
                  this.props.results.NNASuggestions === undefined ||
                  this.props.results.NNASuggestions === 0
                    ? { cursor: "unset" }
                    : {}
                }
                onClick={
                  this.props.results !== undefined &&
                  this.props.results.NNASuggestions !== undefined &&
                  this.props.results.NNASuggestions > 0
                    ? this.localChangeSuggestionType
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <StandardButton
                id="INDICE_DI_RESILIENZA"
                text="INDICE DI RESILIENZA"
                active={this.props.currentView === "resiliencyIndex"}
                onClick={
                  this.props.results !== undefined
                    ? this.localGetResiliencyView
                    : undefined
                }
              />
            </li>
            <li
              className={
                "menuitems__container-button menuitems__container-button-" +
                this.state.device
              }
            >
              <StandardButton
                id="STAMPA_PIANO"
                text="STAMPA PIANO"
                active={this.props.currentView === "resiliencyIndexPrint"}
                onClick={
                  this.props.results !== undefined
                    ? this.localGetResiliencyViewPrint
                    : undefined
                }
              />
            </li>
          </ul>
        </div>
      </>
    );
  }

  // Perform redirect to place view
  redirectToPlace(): void {
    this.setState({ redirectToPlace: true });
  }

  // Rendere redirect component
  renderRedirectToPlace(): Object {
    return this.state.redirectToPlace === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[3] }} />
    ) : (
      ""
    );
  }

  // Perform redirect to questionnaire view
  redirectToQuestionnarie(): void {
    this.setState({ redirectToQuestionnaire: true });
  }

  // Rendere redirect component
  renderRedirectToQuestionnarie(): Object {
    return this.state.redirectToQuestionnaire === true ? (
      <Redirect
        push
        to={{ pathname: "/" + config.steps[8] + "/" + this.props.placeId }}
      />
    ) : (
      ""
    );
  }

  // Redirct to the current view
  localChangeSuggestionType(e: React.MouseEvent) {
    switch (this.props.currentView) {
      case "QuestionView":
        this.setState({
          redirectToTips: true,
          selectedTips: e.currentTarget.id,
        });
        break;
      case "resiliencyIndexPrint":
      case "resiliencyIndex":
      case "TipsView":
        this.props.toggleResiliencyView(false);
        this.props.toggleResiliencyPrintView(false);
        this.props.changeSuggestionType(e.currentTarget.id);
        this.props.toggleSidebar();
        break;
      default:
        console.error("Error: wrong sidebar menu staus");
        break;
    }
  }

  // Open resiliency view
  localGetResiliencyView(e: React.MouseEvent) {
    this.props.toggleSidebar();
    this.props.toggleResiliencyView(true);
  }

  // Open resiliency view
  localGetResiliencyViewPrint(e: React.MouseEvent) {
    this.props.toggleSidebar();
    this.props.toggleResiliencyPrintView(true);
  }

  // Rendere redirect component
  renderRedirectToTips(): Object {
    return this.state.redirectToTips === true ? (
      <Redirect
        push
        to={{
          pathname: "/" + config.steps[10] + "/" + this.props.placeId,
          state: { tips: this.state.selectedTips },
        }}
      />
    ) : (
      ""
    );
  }
}
