import React from "react";
import { Redirect } from "react-router-dom";
import "./StepButton.css";
import config from "../../../config/config";

export default class StepButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      redirectObj: false,
    };

    // Method binding
    this.getRedirectValue = this.getRedirectValue.bind(this);
  }

  public render() {
    switch (this.props.step) {
      case "single":
        return (
          <>
            {this.renderRedirect()}
            <div
              className={
                "stepbutton__container stepbutton__container-single-step-" +
                this.props.button
              }
            >
              {this.props.button !== "03" ? (
                <img
                  src={
                    require("./../../../assets/stepbuttons/empty_OFF.svg")
                      .default
                  }
                  alt="Step post element"
                  className="stepbutton__single-step-post-element"
                />
              ) : (
                ""
              )}
              {this.getCurrentButton()}
              {this.props.button !== "00" ? (
                <img
                  src={
                    require("./../../../assets/stepbuttons/empty_ON.svg")
                      .default
                  }
                  alt="Step pre element"
                  className="stepbutton__single-step-pre-element"
                />
              ) : (
                ""
              )}
            </div>
          </>
        );
      case "multi":
      default:
        return (
          <>
            {this.renderRedirect()}
            <div
              className={
                "stepbutton__container stepbutton__container-" +
                this.props.button
              }
            >
              {this.getCurrentButton()}
              {this.getNextButton()}
            </div>
          </>
        );
    }
  }

  getCurrentButton(): Object {
    return (
      <img
        src={require("./../../../assets/stepbuttons/" +
          this.props.button +
          "_" +
          this.props.type +
          ".svg")}
        alt="Step element"
        className={
          "stepbutton__step-element stepbutton__step-element-" +
          this.props.type +
          " " +
          (this.props.button === "03" && this.props.animate === "ON"
            ? "hide-after-load"
            : "") +
          " " +
          (this.props.type === "ON" && this.props.redirect === true
            ? "cursor-pointer"
            : "")
        }
        onClick={this.getRedirectValue}
      />
    );
  }

  getNextButton(): any {
    switch (this.props.animate) {
      case "ON":
        return (
          <img
            src={require("./../../../assets/stepbuttons/" +
              this.props.button +
              "_ON.svg")}
            alt="Step element"
            className="stepbutton__next-step-element"
          />
        );
      case "OFF":
      default:
        return "";
    }
  }

  getRedirectValue(e: React.MouseEvent): void {
    if (this.props.type === "ON" && this.props.redirect === true) {
      this.setState({
        redirectObj: (
          <Redirect
            to={{
              pathname: "/" + config.steps[parseInt(this.props.button, 10)],
            }}
          />
        ),
      });
    }
  }

  renderRedirect(): Object {
    return this.state.redirectObj !== false ? this.state.redirectObj : "";
  }
}
