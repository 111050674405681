import config from './../../config/config';
import { getAuthResponse } from './../proxy/api'


/**
 * saveSuggestions
 * 
 * (Call webservices to save suggestions)
 * 
 * @param {String} siteId Place Id
 * @param {String} token Token for auth
 * @param {String} suggestions Array with suggestions to save
 * @returns Promise calling suggestion service
 */
export const saveSuggestions = (siteId: string, token: string, suggestions: any): Promise<any> => {
    return getAuthResponse(
        "PUT",
        config.webservices.places.endpoint + "/" + siteId + "/suggestion",
        JSON.stringify(suggestions),
        token
    );
}

/**
 * getSuggestions
 * 
 * (Call webservices to get suggestions)
 * 
 * @param {String} siteId Place Id
 * @param {String} token token for auth
 * @returns Promise calling suggestions service
 */
export const getSuggestions = (siteId: string, token: string): Promise<any> => {
    return getAuthResponse(
        "GET",
        config.webservices.places.endpoint + "/" + siteId + "/suggestion",
        null,
        token
    );
}

/**
 * getResiliencyIndex
 * 
 * (Call webservices to get resiliency index)
 * 
 * @param {String} siteId Place Id
 * @param {String} token token for auth
 * @returns Promise calling suggestions service
 */
export const getResiliencyIndex = (siteId: string, token: string): Promise<any> => {
    return getAuthResponse(
        "GET",
        config.webservices.places.endpoint + "/" + siteId + "/questionnaire/resilience",
        null,
        token
    );
}