import React from "react";
import { Redirect } from "react-router-dom";
import config from "./../../../config/config";
import { checkLogin } from "../../../lib/general-lib";
import { getDevice } from "../../../lib/responsive-lib";
import UserMenu from "../../userMenu/UserMenu";
import "./MBHeader.css";

interface MBHeaderProps {
  loginEnabled: Boolean;
  closeEnabled: Boolean;
  device: String;
  cookies?: any;
  parent: String;
  previous?: String;
}

interface MBHeaderState {
  redirectToLogin: Boolean;
  redirectToPreviousPage: Boolean;
  userMenuStatus: boolean;
  device: String;
}

export default class MBHeader extends React.Component<
  MBHeaderProps,
  MBHeaderState
> {
  constructor(props: any) {
    super(props);

    // Init state
    this.state = {
      redirectToLogin: false,
      redirectToPreviousPage: false,
      userMenuStatus: false,
      device: "",
    };

    // Method binding
    this.setRedirectToLogin = this.setRedirectToLogin.bind(this);
    this.setRedirectToPreviousPage = this.setRedirectToPreviousPage.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);

    // Component strings
    this.componentStrings.set(
      "heading",
      "Strumento di autovalutazione del rischio climatico"
    );
    this.componentStrings.set(
      "subheading",
      "Derris, il clima cambia. Riduciamo i rischi."
    );
  }

  // Class variables
  componentStrings = new Map();

  // React componentDidMount
  componentDidMount() {
    this.setState({
      device: getDevice(),
    });
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirectToLogin()}
        {this.renderRedirectToPreviousPage()}
        <div className="mbheader__container">
          {this.renderCloseButton()}
          {this.renderMainButton()}
          <div
            className={
              "mbheader__logo-container mbheader__logo-container-" +
              this.props.device
            }
          >
            <img
              src={require("./../../../assets/logos/derris.png")}
              alt="Derris Logo"
              className={"mbheader__logo-" + this.props.device}
            />
          </div>
          <div
            className={
              "font-generic mbheader__heading-container mbheader__heading-container-" +
              this.props.device
            }
          >
            {this.componentStrings.get("heading")}
          </div>
          <div
            className={
              "font-generic mbheader__subheading-container mbheader__subheading-container-" +
              this.props.device
            }
          >
            {this.componentStrings.get("subheading")}
          </div>
        </div>
      </>
    );
  }

  // Redirect to login
  setRedirectToLogin(e: React.MouseEvent): void {
    const { cookies } = this.props;
    cookies.set("derris_origin_page", this.props.parent, { path: "/" });
    this.setState({ redirectToLogin: true });
  }

  // Redirect to previous page
  setRedirectToPreviousPage(e: React.MouseEvent): void {
    this.setState({ redirectToPreviousPage: true });
  }

  // Rendere redirect to  previous page
  renderRedirectToPreviousPage(): any {
    if (this.state.redirectToPreviousPage === true) {
      if (this.props.previous !== undefined && this.props.previous !== "") {
        return <Redirect push to={{ pathname: "/" + this.props.previous }} />;
      } else {
        return <Redirect push to={{ pathname: "/" + config.steps[0] }} />;
      }
    }
  }

  // Rendere redirect to  login component
  renderRedirectToLogin(): Object {
    return this.state.redirectToLogin === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[5] }} />
    ) : (
      ""
    );
  }

  renderMainButton(): Object {
    if (this.props.loginEnabled && checkLogin()) {
      return (
        <>
          <div
            className={
              "mbheader__login-container mbheader__login-container-" +
              this.state.device
            }
            onClick={this.toggleUserMenu}
          >
            <div className="mbheader__login-icon-container">
              <img
                src={
                  require("./../../../assets/product/icon-product-user-no-log.svg")
                    .default
                }
                alt="Menù utente"
                className={
                  "mbheader__login-icon mbheader__login-icon-" +
                  this.state.device
                }
              />
            </div>
            <div className="mbheader__login-text">MEN&Ugrave;</div>
          </div>
          <div
            className={
              "mbheader__login-menu-container mbheader__login-menu-container-" +
              this.state.device
            }
          >
            {this.state.userMenuStatus ? (
              <UserMenu cookies={this.props.cookies} />
            ) : (
              <></>
            )}
          </div>
        </>
      );
    } else if (this.props.loginEnabled && !checkLogin()) {
      return (
        <>
          <div
            className={
              "mbheader__login-container mbheader__login-container-" +
              this.props.device
            }
            onClick={this.setRedirectToLogin}
          >
            <div className="mbheader__login-icon-container">
              <img
                src={
                  require("./../../../assets/product/icon-product-user-no-log.svg")
                    .default
                }
                alt="Login"
                className="mbheader__login-icon"
              />
            </div>
            <div className="mbheader__login-text">LOGIN</div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  renderCloseButton(): Object {
    if (this.props.closeEnabled) {
      return (
        <>
          <div
            className={
              "mbheader__close-container mbheader__close-container-" +
              this.props.device
            }
            onClick={this.setRedirectToPreviousPage}
          >
            <div className="mbheader__close-icon-container">
              <img
                src={
                  require("./../../../assets/system/icon-system-close.svg")
                    .default
                }
                alt="Close"
                className="mbheader__close-icon"
              />
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  // Toggle user menu
  toggleUserMenu(): void {
    if (this.state.userMenuStatus) {
      this.setState({ userMenuStatus: false });
    } else {
      this.setState({ userMenuStatus: true });
    }
  }
}
