import React from 'react';
import StepButton from './stepbuttons/StepButton';
import { getDevice } from './../../lib/responsive-lib';
import './ProgressBar.css';

interface ProgressBarProps {
    step: any
}
interface ProgressBarState {
    device: String;
}
export default class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {

    constructor(props: any) {
        super(props);

        this.state = {
            device: ''
        }
    }

    componentDidMount() {
        this.setState({ device: getDevice() });
    }

    public render() {
        return <>
            <div className="progressbar__container">
                {this.renderBar()}
            </div>
        </>;
    }

    renderBar() {
        switch (this.state.device) {
            case 'mobile':
                return <>
                    <div className="progressbar__bar-container-single-step">
                        <StepButton
                            button={this.props.step}
                            type={'ON'}
                            step={'single'}
                        />
                    </div>
                </>
            default:
                return <>
                    <div className="progressbar__bar-container-multi-step">
                        <StepButton
                            button={'03'}
                            type={this.props.step > 3 ? 'ON' : 'OFF'}
                            animate={this.props.step === '03' ? 'ON' : 'OFF'}
                            step={'multi'}
                            redirect={false}
                        />
                        <StepButton
                            button={'02'}
                            type={this.props.step > 2 ? 'ON' : 'OFF'}
                            animate={this.props.step === '02' ? 'ON' : 'OFF'}
                            step={'multi'}
                            redirect={this.props.step > 2 ? true : false}
                        />
                        <StepButton
                            button={'01'}
                            type={this.props.step > 1 ? 'ON' : 'OFF'}
                            animate={this.props.step === '01' ? 'ON' : 'OFF'}
                            step={'multi'}
                            redirect={this.props.step > 1 ? true : false}
                        />
                        <StepButton
                            button={'00'}
                            type={this.props.step >= 0 ? 'ON' : 'OFF'}
                            animate={'OFF'}
                            step={'multi'}
                            redirect={this.props.step > 0 ? true : false}
                        />
                    </div>
                </>
        }
    }
}