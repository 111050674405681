import config from './../../config/config';
import { getResponse } from './../proxy/api'

/**
 * getStandardAuth
 * 
 * (Call webservices to perform a standard auth)
 * 
 * @param {String} username Current username
 * @param {String} password Current password
 * @param {String} rememberme Flag to remember login
 * @returns Promise calling auth service
 */
export const getStandardAuth = (username: string, password: string): Promise<any> => {
    return getResponse(
        "POST",
        config.webservices.auth.local.endpoint,
        JSON.stringify({
            username: username,
            password: password
        })
    );
}


/**
 * getAvailablesLogins
 * 
 * (Call webservices to get available external auth method)
 * 
 * @param {String} relUrl Relative url to return the response
 * @returns Promise calling auth service
 */
export const getAvailablesLogins = (relUrl: string): Promise<any> => {
    return getResponse(
        "GET",
        config.webservices.auth.remote.endpoint + "?returnUrl=%2F" + relUrl + "&generateState=true",
        null
    );
}


/**
 * getLocalAccessToken
 * 
 * (Call webservices to get a local access token)
 * 
 * @param {String} provider Facebook | Google
 * @param {String} externalAccessToken Access token provided by rest service
 * @returns Promise calling auth service
 */
export const getLocalAccessToken = (provider: String, externalAccessToken: String, ): Promise<any> => {
    return getResponse(
        "GET",
        config.webservices.auth.remoteToken.endpoint + "?provider=" + provider + "&externalAccessToken=" + externalAccessToken,
        null
    );
}


/**
 * recoverPassword
 * 
 * (Call webservices to perform a password recovery)
 * 
 * @param {String} email Email of the user
 * @returns Promise calling auth service
 */
export const recoverPassword = (email: string): Promise<any> => {
    return getResponse(
        "POST",
        config.webservices.passwordRecovery.endpoint,
        JSON.stringify({
            email: email
        })
    );
}


/**
 * changePassword
 * 
 * (Call webservices to perform a password change)
 * 
 * @param {String} newPassword New password to set
 * @param {String} confirmPassword New password to set (repeat)
 * @param {String} email Email of the user
 * @param {String} resetpasswordtoken Token needed to perform the operation
 * @returns Promise calling auth service
 */
export const changePassword = (newPassword: string, confirmPassword: string, email: string, resetpasswordtoken: string): Promise<any> => {
    return getResponse(
        "POST",
        config.webservices.changePassword.endpoint,
        JSON.stringify({
            newPassword: newPassword,
            confirmPassword: confirmPassword,
            email: email,
            resetpasswordtoken: resetpasswordtoken
        })
    );
}