import { isMobileOnly, isTablet } from 'react-device-detect';

export const getDevice = (): String => {
    switch (true) {
        case (isMobileOnly && isTablet === false):
            return 'mobile';
        case (isMobileOnly && isTablet === false && (getWidthType() === 'medium' || getWidthType() === 'big')):
            return 'tablet-' + getOrientation();
        case (isTablet):
            return 'tablet-' + getOrientation();
        default:
            return 'generic';
    }
}

const getOrientation = (): String => {
    if (window.innerWidth >= window.innerHeight) {
        return 'landscape';
    } else {
        return 'portrait';
    }
}

const getWidthType = (): String => {
    switch (true) {
        case (window.innerWidth < 800):
            return 'small';
        case (window.innerWidth >= 800 && window.innerWidth < 1280):
            return 'medium';
        case (window.innerWidth >= 1280):
            return 'big';
        default:
            return 'unknown'
    }
}