import React, { createRef } from "react";
import config from "./../../../config/config";
import { getDevice } from "./../../../lib/responsive-lib";
import {
  getStandardAuth,
  getAvailablesLogins,
  getLocalAccessToken,
} from "./../../../api/auth-api/api";
import { getExternalRegistration } from "./../../../api/registration-api/api";
import MainBox from "./../../mainbox/MainBox";
import { Redirect } from "react-router-dom";
import MBHeader from "./../../mainbox/mbheader/MBHeader";
import Loader from "./../../loader/Loader";
import ErrorPopup from "./../../errorpopup/ErrorPopup";
import {
  setUtagView,
  setUtagLink,
  trimString,
  capitalizeString,
} from "./../../../lib/general-lib";
import "./Login.css";

interface LoginProps {
  cookies?: any;
  disableMarker: any;
  setMapZoom: any;
  setInfoWindowVisibility: any;
  location: any;
  setMapStatus: any;
}

interface LoginState {
  device: String;
  buttonEnabled: Boolean;
  email: string;
  password: string;
  redirect: Boolean;
  availablesLogins: Array<any>;
  loader: Boolean;
  redirectToRecovery: Boolean;
  previous: String;
  error: boolean;
  errorTitle: string;
  errorText: string;
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      buttonEnabled: false,
      email: "",
      password: "",
      redirect: false,
      availablesLogins: [],
      loader: false,
      redirectToRecovery: false,
      previous: "",
      error: false,
      errorTitle: "",
      errorText: "",
    };

    // Method binding
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.checkField = this.checkField.bind(this);
    this.performLogin = this.performLogin.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.getexternalLoginUrl = this.getexternalLoginUrl.bind(this);
    this.redirectToRecovery = this.redirectToRecovery.bind(this);
    this.toggleErrorPopup = this.toggleErrorPopup.bind(this);
  }

  // Class variables
  private emailInputRef = createRef<HTMLInputElement>();
  private passwordInputRef = createRef<HTMLInputElement>();

  // React componentDidMount
  componentDidMount() {
    if (window.location.hash !== undefined && window.location.hash !== "") {
      this.handleUrlParams(window.location.hash.substring(1).split("&"));
    } else {
      this.mountComponentOp();
    }
  }

  // React componentDidUpdate
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (
      prevState.email !== this.state.email ||
      prevState.password !== this.state.password
    ) {
      this.checkField();
    }
    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      window.location.hash !== undefined &&
      window.location.hash !== ""
    ) {
      this.handleUrlParams(window.location.hash.substring(1).split("&"));
    }
  }

  // React render
  public render() {
    return (
      <>
        {this.renderRedirect()}
        {this.renderRedirectToRecovery()}
        <div className="login__container">
          <MainBox>
            <div className="login__header">
              <MBHeader
                device={this.state.device}
                loginEnabled={false}
                closeEnabled={true}
                cookies={this.props.cookies}
                parent={config.steps[5]}
                previous={this.state.previous}
              />
            </div>
            <div className="login__content">
              <div className="login__title">Effetua il Login</div>
              <div
                className={
                  "login__login-form login__login-form-" + this.state.device
                }
              >
                <div className="login__form-field">
                  <div className="login__label-box">Indirizzo email</div>
                  <div className="login__input-box">
                    <input
                      id="login__input-firstname"
                      type="text"
                      className="login__input"
                      onChange={this.onEmailChange}
                      ref={this.emailInputRef}
                    />
                  </div>
                </div>
                <div className="login__form-field">
                  <div className="login__label-box">Password</div>
                  <div className="login__input-box">
                    <input
                      id="login__input-lastname"
                      type="password"
                      className="login__input"
                      onChange={this.onPasswordChange}
                      ref={this.passwordInputRef}
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  "login__login-password-recovery login__login-password-recovery-" +
                  this.state.device
                }
                onClick={this.redirectToRecovery}
              >
                Password dimenticata?
              </div>

              <div className="login__button-access-container">
                <div
                  className={
                    "login__button-access " +
                    (this.state.buttonEnabled ? "enabled" : "disabled")
                  }
                  onClick={() => {
                    this.setState({ loader: true }, () => {
                      this.performLogin();
                    });
                  }}
                >
                  Accedi
                </div>
              </div>
            </div>
            {this.getExternalLoginComponent(true, false)}
          </MainBox>
        </div>
        {this.renderLoader()}
        {this.renderError()}
      </>
    );
  }

  // Render external login component
  getExternalLoginComponent(google: boolean, facebook: boolean): any {
    return (
      <>
        <div className={"login__external login__external-" + this.state.device}>
          <div
            className={
              "login__external-title login__external-title-" + this.state.device
            }
          >
            Oppure
          </div>
          {google ? (
            <>
              <div
                className={
                  "login__external-google " +
                  (!facebook ? "login__external-google-nofb " : "") +
                  "login__external-google-" +
                  this.state.device
                }
              >
                <div
                  onClick={this.getexternalLoginUrl}
                  id="google"
                  className={
                    "login__external-google-button login__external-google-button-" +
                    this.state.device
                  }
                >
                  <div className="login__google-logo-container">
                    <img
                      src={require("./../../../assets/logos/gg.png")}
                      alt="Login con Google"
                      className="login__gg-logo"
                    />
                  </div>
                  <div className="login__gg-text">Accedi con Google</div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {facebook ? (
            <>
              <div
                className={
                  "login__external-facebook " +
                  (!google ? "login__external-facebook-nogg " : "") +
                  "login__external-facebook-" +
                  this.state.device
                }
              >
                <div
                  onClick={this.getexternalLoginUrl}
                  id="facebook"
                  className={
                    "login__external-facebook-button login__external-facebook-button-" +
                    this.state.device
                  }
                >
                  <div className="login__fb-logo-container">
                    <img
                      src={require("./../../../assets/logos/fb.png")}
                      alt="Login con Facebook"
                      className="login__fb-logo"
                    />
                  </div>
                  <div className="login__fb-text">Accedi con Facebook</div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  // Render the ErrorPopup component
  renderError(): Object {
    return this.state.error ? (
      <ErrorPopup
        errorTitle={this.state.errorTitle}
        errorText={this.state.errorText}
        action={this.toggleErrorPopup}
      />
    ) : (
      <></>
    );
  }

  // Toggle ErrorPopup component
  toggleErrorPopup(): void {
    if (this.state.error === false) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  }

  // Redirect to recovery component
  redirectToRecovery() {
    this.setState({ redirectToRecovery: true });
  }

  // Render the redirect component
  renderRedirectToRecovery() {
    return this.state.redirectToRecovery === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[6] }} />
    ) : (
      ""
    );
  }

  // Perform all operation to do after component starts
  mountComponentOp(): void {
    if (getDevice() !== "mobile") {
      this.props.setMapStatus(true);
    }
    const { cookies } = this.props;
    this.getLoginMethods();
    this.props.disableMarker();
    this.props.setInfoWindowVisibility(false);
    this.props.setMapZoom(config.maps.default.zoom);
    this.setState({
      device: getDevice(),
      previous: cookies.get("derris_origin_page"),
    });
    this.checkField();
    if (this.emailInputRef.current !== null) {
      this.emailInputRef.current.focus();
    }
    if (this.passwordInputRef.current !== null) {
      this.passwordInputRef.current.focus();
    }
    // Analytics
    setUtagView(
      {
        page_url: config.analytics.baseurl + "/login",
        first_level: "login",
      },
      false
    );
  }

  // Manage the url params
  handleUrlParams(paramsArray: Array<string>): void {
    switch (true) {
      // New user: must be registered
      case this.getParam("haslocalaccount", paramsArray) ===
        "haslocalaccount=False":
        this.setState({ loader: true }, () => {
          getExternalRegistration(
            this.getValueByKey(
              this.getParam("external_user_firstname", paramsArray)
            ),
            this.getValueByKey(
              this.getParam("external_user_lastname", paramsArray)
            ),
            this.getValueByKey(
              this.getParam("external_user_name", paramsArray)
            ),
            this.getValueByKey(this.getParam("provider", paramsArray)),
            this.getValueByKey(
              this.getParam("external_access_token", paramsArray)
            )
          )
            .then((result) => {
              if (result.status === 200) {
                result.json().then((res: any) => {
                  const { cookies } = this.props;
                  cookies.set("derris_user", JSON.parse(res).userName, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  cookies.set("derris_token", JSON.parse(res).access_token, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  this.setLoginForAnalytics("registrazione", true);
                  this.setState({ loader: false }, () => {
                    this.nextStep();
                  });
                });
              } else if (result.status === 400) {
                result.json().then((res: any) => {
                  this.setLoginForAnalytics("registrazione", false);
                  this.setState({
                    errorTitle: "Errore",
                    errorText: trimString(res.Description),
                    error: true,
                    loader: false,
                  });
                  console.error(
                    "Error new external account - " +
                      trimString(res.Description)
                  );
                });
              } else {
                this.setLoginForAnalytics("registrazione", false);
                this.setState({
                  errorTitle: "Errore",
                  errorText: trimString(
                    "Error new external account - Status: " + result.status
                  ),
                  error: true,
                  loader: false,
                });
                console.error(
                  "Error new external account - Status: " + result.status
                );
              }
            })
            .catch((error) => {
              this.setLoginForAnalytics("registrazione", false);
              this.setState({
                errorTitle: "Errore",
                errorText: trimString(
                  "Error new external account - " + error.toString()
                ),
                error: true,
                loader: false,
              });
              console.error(
                "Error new external account - " + trimString(error.toString())
              );
            });
        });
        break;
      // User already registered: getting local account
      case this.getParam("haslocalaccount", paramsArray) ===
        "haslocalaccount=True":
        this.setState({ loader: true }, () => {
          getLocalAccessToken(
            this.getValueByKey(this.getParam("provider", paramsArray)),
            this.getValueByKey(
              this.getParam("external_access_token", paramsArray)
            )
          )
            .then((atResult) => {
              if (atResult.status === 200) {
                atResult.json().then((res: any) => {
                  const { cookies } = this.props;
                  cookies.set("derris_user", JSON.parse(res).userName, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  cookies.set("derris_token", JSON.parse(res).access_token, {
                    path: "/",
                    maxAge: JSON.parse(res).expires_in,
                  });
                  this.setLoginForAnalytics("login", true);
                  this.setState({ loader: false }, () => {
                    this.nextStep();
                  });
                });
              } else if (atResult.status === 400) {
                atResult.json().then((res: any) => {
                  this.setLoginForAnalytics("login", false);
                  this.setState({
                    errorTitle: "Errore",
                    errorText: res.Description.split("-")[1],
                    error: true,
                    loader: false,
                  });
                });
              } else {
                this.setLoginForAnalytics("login", false);
                this.setState({ loader: false });
                console.error("Local token error - Status: " + atResult.status);
              }
            })
            .catch((error) => {
              this.setLoginForAnalytics("login", false);
              this.setState({ loader: false });
              console.error(
                "Local token error - " + trimString(error.toString())
              );
            });
        });
        break;
      // Invalid url params: initializing component
      default:
        this.mountComponentOp();
        break;
    }
  }

  // Get single param in params array
  getParam(parmas: string, paramsArray: Array<string>): string | undefined {
    return paramsArray.find(
      (param) => this.getKeyAndValueFromString(param)[0] === parmas
    );
  }

  // Get value from string key-value
  getValueByKey(keyValue: string | undefined): string {
    return keyValue !== undefined
      ? this.getKeyAndValueFromString(keyValue)[1]
      : "";
  }

  // Return key and value from a single url param
  getKeyAndValueFromString(string: string): Array<string> {
    const results = string.match(/([^=]+)=(.+)/i);
    if (results !== null) {
      return [results[1], results[2]];
    } else {
      return ["", ""];
    }
  }

  // Perform login by external account
  getexternalLoginUrl(e: React.MouseEvent) {
    if (this.state.availablesLogins.length > 0) {
      window.location.replace(
        config.main.baseurl +
          this.state.availablesLogins.find((item) =>
            item.Name.match(new RegExp(e.currentTarget.id, "i"))
          ).Url
      );
    } else {
      this.setState({
        errorTitle: "Errore",
        errorText:
          "Si è verificato un problema nell'effettuare il login con " +
          capitalizeString(e.currentTarget.id),
        error: true,
      });
    }
  }

  // Render Loader component
  renderLoader(): Object {
    return this.state.loader ? <Loader /> : <></>;
  }

  // Get the login methods availables
  getLoginMethods() {
    this.setState({ loader: true }, () => {
      getAvailablesLogins(config.steps[5] + "/")
        .then((result) => {
          if (result.status === 200) {
            result.json().then((res: any) => {
              this.setState({
                availablesLogins: JSON.parse(res),
                loader: false,
              });
            });
          } else {
            this.setState({ loader: false });
            console.error(
              "Error while loading login method - Status: " +
                trimString(result.status)
            );
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          console.error(
            "Error while loading login method - " + trimString(error.toString())
          );
        });
    });
  }

  // Handle email changes
  onEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ email: e.currentTarget.value });
  }

  // Handle password changes
  onPasswordChange(e: React.FormEvent<HTMLInputElement>): void {
    e.preventDefault();
    this.setState({ password: e.currentTarget.value });
  }

  // Check if input field are empty and enable/disable login button
  checkField() {
    if (this.state.email !== "" && this.state.password !== "") {
      this.setState({ buttonEnabled: true });
    } else {
      this.setState({ buttonEnabled: false });
    }
  }

  // Perform the standard login
  performLogin() {
    getStandardAuth(this.state.email, this.state.password)
      .then((result) => {
        if (result.status === 200) {
          result.json().then((res: any) => {
            const { cookies } = this.props;
            cookies.set("derris_user", JSON.parse(res).userName, {
              path: "/",
              maxAge: JSON.parse(res).expires_in,
            });
            cookies.set("derris_token", JSON.parse(res).access_token, {
              path: "/",
              maxAge: JSON.parse(res).expires_in,
            });
            this.setLoginForAnalytics("login", true);
            this.setState({ loader: false }, () => {
              this.nextStep();
            });
          });
        } else if (result.status === 400) {
          this.setLoginForAnalytics("login", false);
          result.json().then((res: any) => {
            if (/Imported/i.test(res.Description)) {
              this.setState({
                loader: false,
                errorTitle: "Errore",
                errorText:
                  'ERRORE – E’ necessario re-impostare la password. Ti invitiamo a cliccare su "Password dimenticata" per procedere con il reset della password',
                error: true,
              });
            } else {
              this.setState({
                loader: false,
                errorTitle: "Errore",
                errorText:
                  "Impossibile effettuare il login. " +
                  trimString(res.Description),
                error: true,
              });
            }
          });
          console.error("Login error - Status: " + result.status);
        } else {
          this.setLoginForAnalytics("login", false);
          this.setState({ loader: false });
          this.setState({
            loader: false,
            errorTitle: "Errore",
            errorText:
              "Impossibile effettuare il login - Status: " +
              trimString(result.status),
            error: true,
          });
          console.error("Login error - Status: " + result.status);
        }
      })
      .catch((error) => {
        this.setLoginForAnalytics("login", false);
        this.setState({
          loader: false,
          errorTitle: "Errore",
          errorText: "Errore di login - " + trimString(error.toString()),
          error: true,
        });
        console.error("Login error - " + trimString(error.toString()));
      });
  }

  // Set data about login for analytics system
  setLoginForAnalytics(action: string, result: boolean): void {
    setUtagLink(
      {
        page_url: config.analytics.baseurl + "/login",
        first_level: "login",
        event_name: "login",
        ga_eventCategory: "riconoscimento-utente",
        ga_eventaction: action,
        ga_eventLabel: result ? "success" : "fail",
      },
      false
    );
  }

  // Go to the next step
  nextStep(): void {
    this.setState({ redirect: true });
  }

  // Rendere redirect component
  renderRedirect(): Object {
    return this.state.redirect === true ? (
      <Redirect push to={{ pathname: "/" + config.steps[3] }} />
    ) : (
      ""
    );
  }
}
