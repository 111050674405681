import React from 'react';
import config from './../../../config/config';

interface MyMapProps {
    mapCenter: any;
    mapZoom: number;
    markerCurrentPosition: any;
    markerVisible: any;
    mapClickableIcons: any;
    mapFullscreenControl: any;
    mapKeyboardShortcuts: any;
    mapTypeControl: any;
    mapRotateControl: any;
    mapScaleControl: any;
    mapStreetViewControl: any;
    mapZoomControl: any;
    markerDraggable: any;
    infoWindowIsVisible: Boolean;
    panMap: any;
    setCookiesAddressInfo: any;
    getCookiesAddressInfo: any;
}

interface MyMapState {
    addressText: any;
    infoWindowText: string;
}

export default class MyMap extends React.Component<MyMapProps, MyMapState> {

    constructor(props: any) {
        super(props);

        // Init state
        this.state = {
            addressText: '',
            infoWindowText: ''
        }

        // Method binding
        this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
        this.openAndSetMarker = this.openAndSetMarker.bind(this);
        this.updateAddressInfo = this.updateAddressInfo.bind(this);
    }

    /*global google*/

    // New Map
    map = new google.maps.Map(document.getElementById('map'), {
        center: this.props.mapCenter,
        zoom: this.props.mapZoom,
        clickableIcons: this.props.mapClickableIcons,
        fullscreenControl: this.props.mapFullscreenControl,
        keyboardShortcuts: this.props.mapKeyboardShortcuts,
        mapTypeControl: this.props.mapTypeControl,
        rotateControl: this.props.mapRotateControl,
        scaleControl: this.props.mapScaleControl,
        streetViewControl: this.props.mapStreetViewControl,
        zoomControl: this.props.mapZoomControl,
    });

    // New InfoWindow
    infowindow = new google.maps.InfoWindow({
        content: ''
    });

    // New Marker
    marker = new google.maps.Marker({
        position: {
            lat: this.props.markerCurrentPosition.lat,
            lng: this.props.markerCurrentPosition.lng
        },
        map: this.map,
        visible: this.props.markerVisible,
        draggable: this.props.markerDraggable
    });

    // New Geocoder
    geocoder = new google.maps.Geocoder();

    // React componentDidMount
    componentDidMount() {
        this.marker.addListener('drag', () => this.toggleInfoWindow(false));
        this.marker.addListener('dragend', this.openAndSetMarker);
        this.updateAddressInfo(this.props.markerCurrentPosition);
    }

    // React componentDidUpdate
    componentDidUpdate(prevProps: any, prevState: any) {
        if (JSON.stringify(prevProps.mapCenter) !== JSON.stringify(this.props.mapCenter)) {
            this.map.setCenter(this.props.mapCenter);
        }
        if (prevProps.mapZoom !== this.props.mapZoom) {
            this.map.setZoom(this.props.mapZoom);
        }
        if (prevProps.markerVisible !== this.props.markerVisible) {
            this.marker.setVisible(this.props.markerVisible);
        }
        if (JSON.stringify(prevProps.markerCurrentPosition) !== JSON.stringify(this.props.markerCurrentPosition)) {
            this.marker.setPosition(this.props.markerCurrentPosition);
            this.updateAddressInfo(this.props.markerCurrentPosition);
        }
        if (prevProps.markerDraggable !== this.props.markerDraggable) {
            this.marker.setDraggable(this.props.markerDraggable);
        }
        if (prevProps.infoWindowIsVisible !== this.props.infoWindowIsVisible) {
            this.toggleInfoWindow(this.props.infoWindowIsVisible);
        }
        if (JSON.stringify(prevProps.panMap) !== JSON.stringify(this.props.panMap)) {
            this.setPanMap();
        }
        if (prevState.infoWindowText !== this.state.infoWindowText) {
            this.infowindow.setContent(this.state.infoWindowText);
        }
    }

    // React render
    public render() {
        return <></>;
    }

    // Change the center offset of the map
    setPanMap() {
        this.map.panBy(this.props.panMap.x, this.props.panMap.y);
    }

    // Add the info window to the marker
    toggleInfoWindow(isVisible: Boolean): void {
        if (isVisible) {
            this.infowindow.open(this.map, this.marker);
        } else {
            this.infowindow.close();
        }
    }

    // Manages the marker status
    openAndSetMarker = () => {
        this.infowindow.open(this.map, this.marker);
    }

    // Build the text for Google InfoWindow
    updateAddressInfo = (currentPosition: any): void => {
        const infoWindowStyle = {
            rowAddress: "font-weight: 400",
            rowMessage: "font-style: italic;",
            rowMistake: ""
        }
        if (this.props.getCookiesAddressInfo() === undefined) {
            this.revGeocode(currentPosition).then(results => {
                this.setState({
                    infoWindowText: "<div>" +
                        "<div style='" + infoWindowStyle.rowAddress + "'>Indirizzo: " + results.formatted_address + "</div>" +
                        "<br>" +
                        "<div style='" + infoWindowStyle.rowMistake + "'><a href='/" + config.steps[1] + "'>Hai sbagliato? Effetua una nuova ricerca!</a></div>" +
                        "</div>"
                });
            })
        } else {
            this.setState({
                infoWindowText: "<div>" +
                    "<div style='" + infoWindowStyle.rowAddress + "'>Indirizzo: " +
                    (this.getAddressItem(this.props.getCookiesAddressInfo(), 'route') !== undefined && this.getAddressItem(this.props.getCookiesAddressInfo(), 'route').short_name !== undefined ? this.getAddressItem(this.props.getCookiesAddressInfo(), 'route').short_name + ", " : "") +
                    (this.getAddressItem(this.props.getCookiesAddressInfo(), 'street_number') !== undefined && this.getAddressItem(this.props.getCookiesAddressInfo(), 'street_number').short_name !== undefined ? this.getAddressItem(this.props.getCookiesAddressInfo(), 'street_number').short_name + ", " : "") +
                    (this.getAddressItem(this.props.getCookiesAddressInfo(), 'locality') !== undefined && this.getAddressItem(this.props.getCookiesAddressInfo(), 'locality').short_name !== undefined ? this.getAddressItem(this.props.getCookiesAddressInfo(), 'locality').short_name : "") + "</div>" +
                    "<br>" +
                    "<div style='" + infoWindowStyle.rowMistake + "'><a href='/" + config.steps[1] + "'>Hai sbagliato? Effetua una nuova ricerca!</a></div>" +
                    "</div>"
            });
        }
    }

    // Get address item from Google maps reverse geocode
    getAddressItem(addressStruct: Array<any>, item: string): any {
        return addressStruct.find(currentItem => {
            return currentItem.types[0] === item
        });
    }

    // Run Google Maps reverse geocoding
    revGeocode(latlng: any): Promise<any> {
        return new Promise((resolve) => {
            this.geocoder.geocode({ 'location': latlng }, (results, status) => {
                if (results[0]) {
                    resolve(results[0]);
                } else {
                    resolve('');
                }
            });
        })

    }
}