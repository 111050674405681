import React from 'react';
import { getDevice } from './../../lib/responsive-lib';
import './ConfirmPopup.css';

interface ConfirmPopupProps {
    confirmTitle: string;
    confirmText: string;
    actionOk: any;
    textOk: string;
    actionKo: any;
    textKo: string;
}

interface ConfirmPopupState {
    device: String;
}
export default class ConfirmPopup extends React.Component<ConfirmPopupProps, ConfirmPopupState> {

    constructor(props: any) {
        super(props);

        // Component state
        this.state = {
            device: ''
        }
    }

    // React componentDidMount
    componentDidMount() {
        this.setState({ device: getDevice() });
    }

    // React render
    public render() {
        return <>
            <div className="confirmpopup__container">
                <div className="confirmpopup__background">
                </div>
                <div className={"confirmpopup__popup confirmpopup__popup-" + this.state.device}>
                    <div className="confirmpopup__title-container">
                        {this.props.confirmTitle}
                    </div>
                    <div className={"confirmpopup__text-container confirmpopup__text-container-" + this.state.device}>
                        {this.props.confirmText}
                    </div>
                    <div className="confirmpopup__buttons-container">
                        <div className="confirmpopup__button-container">
                            <div className="confirmpopup__button-ok" onClick={this.props.actionOk}>
                                {this.props.textOk}
                            </div>
                        </div>
                        <div className="confirmpopup__button-container">
                            <div className="confirmpopup__button-ko" onClick={this.props.actionKo}>
                                {this.props.textKo}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}