import React from "react";
import { getDevice } from "../../lib/responsive-lib";
import "./Loader.css";

interface LoaderProps {}

interface LoaderState {
  device: any;
  loaderCode: any;
}

export default class Loader extends React.Component<LoaderProps, LoaderState> {
  constructor(props: any) {
    super(props);

    // Component state
    this.state = {
      device: "",
      loaderCode: undefined,
    };
  }

  // Class variables
  private localIsMounted: boolean = false;

  // React componentDidMount
  componentDidMount() {
    this.localIsMounted = true;
    this.setState({
      device: getDevice(),
    });
    this.getLoader();
  }

  componentWillUnmount() {
    this.localIsMounted = false;
  }

  // React render
  public render() {
    return <>{this.state.loaderCode}</>;
  }

  // Return the loader after 0.8 sec of idle
  getLoader() {
    setTimeout(() => {
      if (this.localIsMounted) {
        this.setState({
          loaderCode: (
            <>
              <div className="loader__container">
                <div className="loader__background"></div>
                <div className="loader__spinner-container">
                  <img
                    src={
                      require("./../../assets/misc/elements-pieces-loader.svg")
                        .default
                    }
                    alt="Attendere..."
                    className="loader__spinner"
                  />
                </div>
              </div>
            </>
          ),
        });
      }
    }, 500);
  }
}
